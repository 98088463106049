import React, { useContext } from 'react'
import { useSelector } from 'react-redux'

import SectionHeading from 'components/SectionHeading'
import { payrollSummaryAdditionalItems, payrollSummarySupportingDocs, payrollSummaryTitles } from 'utils/locales/payrollSummary.en'
import CommentaryContainer from '../containers/CommentaryContainer'
import SummaryCardContainer from '../containers/SummaryCardContainer'
import { Export, ExportActionButton } from 'components/export/Export'
import { getPayrollInstanceRef } from 'redux/selectors/payrollInstance'
import { isEmpty } from 'lodash'
import { dateToUtc } from 'utils/date'
import { dateFormatEnums } from 'utils/enums/dateFormatEnums'
import { SummaryTotalsTable } from '../containers/SummaryTotalsTableContainer'
import ApprovalButton from './ApprovalButton'
import { SummarySupportingDocsContainer } from '../containers/SummarySupportingDocuments'
import { SummaryAdditionalItemsContainer } from '../containers/SummaryAdditionalItemsContainer'
import { ExportBlockItem } from 'components/export/ExportBlockItem'
import { PayrollSummaryContext } from '../PayrollSummaryContext'

const ActionsHeader = ({ payrollInstanceId, payrollInstance, disabled, ...props }) => (
  <div className='d-flex jc--flex-end ai-center u-margin-top'>
    <ApprovalButton payrollInstanceId={payrollInstanceId} payrollInstance={payrollInstance} />
    <ExportActionButton {...props} disabled={isEmpty(payrollInstance) || disabled} />
  </div>
)

const generateFileName = (payrollInstance) => {
  if (isEmpty(payrollInstance)) {
    return
  }
  const currentDate = dateToUtc(new Date(), dateFormatEnums.DayMonthlyYearTimeFormat)
  const { payrollName, period } = payrollInstance
  const periodRange = period?.replaceAll('/', '.')
  return `${currentDate} ${payrollSummaryTitles.payrollSummary}, ${payrollName} ${periodRange}.pdf`
}

const sectionsToPrint = ['overview_section', 'totals_table', 'additional_items_section', 'commentary_section', 'supporting_docs_section']

const PayrollSummaryView = ({ payrollInstanceId }) => {
  const payrollInstance = useSelector((state) => getPayrollInstanceRef(state, { payrollInstanceId }))
  const { inGlobalUpdateMode } = useContext(PayrollSummaryContext)

  return (
    <Export
      sectionsToPrint={sectionsToPrint}
      getFileName={() => generateFileName(payrollInstance)}
      Actions={(props) => <ActionsHeader {...props} payrollInstanceId={payrollInstanceId} payrollInstance={payrollInstance} disabled={inGlobalUpdateMode} />}
    >
      <ExportBlockItem id={sectionsToPrint[0]}>
        <SectionHeading text={payrollSummaryTitles.overview} className='u-text--valhalla' />
        <SummaryCardContainer payrollInstanceId={payrollInstanceId} payrollInstance={payrollInstance} />
      </ExportBlockItem>
      <ExportBlockItem id={sectionsToPrint[1]}>
        <SummaryTotalsTable payrollInstanceId={payrollInstanceId} />
      </ExportBlockItem>
      <ExportBlockItem id={sectionsToPrint[2]}>
        <SectionHeading text={payrollSummaryAdditionalItems.sectionHeading} className='u-text--valhalla u-margin-top' />
        <SummaryAdditionalItemsContainer payrollInstance={payrollInstance} />
      </ExportBlockItem>
      <ExportBlockItem id={sectionsToPrint[3]}>
        <SectionHeading text={payrollSummaryTitles.commentary} className='u-text--valhalla u-margin-top' />
        <CommentaryContainer payrollInstanceId={payrollInstanceId} />
      </ExportBlockItem>
      <ExportBlockItem id={sectionsToPrint[4]}>
        <SectionHeading text={payrollSummarySupportingDocs.supportingDocs} className='u-text--valhalla u-margin-top' />
        <SummarySupportingDocsContainer payrollInstanceId={payrollInstanceId} />
      </ExportBlockItem>
    </Export>
  )
}

export default PayrollSummaryView
