import React from 'react'
import PropTypes from 'prop-types'
import InnerWrapperTabs from 'layouts/InnerWrapperTabs'
import { connect } from 'react-redux'
import { createFilter } from '../../../utils/redux/filter'
import { isAccounting, isFinanceAdmin } from 'redux/selectors/auth'
import { doesStringContainValue } from 'utils/query'
import Fetcher from 'containers/Fetcher'
import { ALL_ROUTES } from 'configs/routes'
import { payrollSummaryTitles } from 'utils/locales/payrollSummary.en'
import { reconciliationTitles } from 'utils/locales/reconciliation.en'
import { payrunTitles } from 'utils/locales/payruns.en'

class InnerWrapperContainer extends React.Component {
  renderTabs () {
    const { showExtraPayrunsTab, showReconciliation } = this.props
    if (
      [ALL_ROUTES.PAYRUNS.PAYROLL_RECONCILIATION, ALL_ROUTES.PAYRUNS.ELEMENT_RECONCILIATION, ALL_ROUTES.PAYRUNS.PAYROLL_SUMMARY].some((v) =>
        doesStringContainValue(this.props.location.pathname, v)
      )
    ) {
      return [
        { name: payrollSummaryTitles.payrollSummary, to: ALL_ROUTES.PAYRUNS.PAYROLL_SUMMARY },
        showReconciliation && { name: reconciliationTitles.payrollReconciliation, to: ALL_ROUTES.PAYRUNS.PAYROLL_RECONCILIATION },
        showReconciliation && { name: reconciliationTitles.elementReconciliation, to: ALL_ROUTES.PAYRUNS.ELEMENT_RECONCILIATION },
      ]
    }
    return [
      { name: payrunTitles.prePayrollSteps, to: `/payruns/active` },
      showExtraPayrunsTab && { name: payrunTitles.postPayrollSteps, to: `/payruns/extra` },
      { name: payrunTitles.closedPayruns, to: `/payruns/previous` },
      { name: payrunTitles.futurePayruns, to: `/payruns/inactive` },
    ]
  }

  render () {
    return <InnerWrapperTabs tabs={this.renderTabs()} {...this.props} />
  }
}

const mapStateToProps = (state, props) => {
  const filter = props.filters.payrollInstances
  const { ids } = state.payrollInstances.filters[filter.name]
  return {
    showExtraPayrunsTab: ids.length > 0,
    showReconciliation: !isFinanceAdmin(state) && !isAccounting(state),
  }
}

InnerWrapperContainer.propTypes = {
  showExtraPayrunsTab: PropTypes.bool,
}

const Component = connect(mapStateToProps)(InnerWrapperContainer)

export default Fetcher(Component, [
  {
    name: 'payrollInstances',
    // TODO - Let's think about filtration
    // We fetch `payrollInstances` because of the `flags` property we receive outside of data array.
    //
    // After discussion with BE team this is the better solution for now
    // instead of separate route for only one flag `hasExtraSteps`.
    //
    // Anyway we receive `flags` property with and without to filter instances,
    // but without filter their number could be enormous.
    // With this filtration by `{ payrunState: 'previousWithExtraTasks' }` we limit the results in data array,
    // which we don't use.
    params: [
      {
        _computed: {
          filter: (state, props) =>
            createFilter(
              {
                payrunState: 'previousWithExtraTasks',
                limit: 1,
                sort: [
                  { name: 'country', order: 'asc' },
                  { name: 'name', order: 'asc' },
                  { name: 'fromDate', order: 'desc' },
                ],
              },
              'previousWithExtraTasksLimitCheck'
            ),
        },
      },
    ],
  },
])
