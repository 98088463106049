import createSelector from 'utils/createSelector'

const defaultDashboardItems = [
  { h: 4, i: 'payroll', w: 8, x: 0, y: 0, moved: false, static: false },
  { h: 2, i: 'overdueActions', w: 4, x: 8, y: 2, moved: false, static: false },
  { h: 2, i: 'calendar', w: 8, x: 0, y: 2, moved: false, static: false },
  { h: 2, i: 'recentReports', w: 4, x: 8, y: 4, moved: false, static: false },
  { h: 2, i: 'alerts', w: 4, x: 8, y: 0, moved: false, static: false },
]

const payrollAdminDashboardItems = defaultDashboardItems
const payrollAnalystDashboardItems = defaultDashboardItems
const financeAdminDashboardItems = [
  { h: 4, i: 'payroll', w: 8, x: 0, y: 0, moved: false, static: false },
  { h: 2, i: 'alerts', w: 4, x: 8, y: 0, moved: false, static: false },
  { h: 2, i: 'overdueActions', w: 4, x: 8, y: 2, moved: false, static: false },
  { h: 2, i: 'calendar', w: 8, x: 0, y: 2, moved: false, static: false },
]
const payrollDataEntryDashboardItems = [
  { h: 4, i: 'payroll', w: 8, x: 0, y: 0, moved: false, static: false },
  { h: 2, i: 'alerts', w: 4, x: 8, y: 0, moved: false, static: false }
]
const accountingDashboardItems = [
  { h: 4, i: 'payroll', w: 8, x: 0, y: 0, moved: false, static: false },
  { h: 2, i: 'alerts', w: 4, x: 8, y: 0, moved: false, static: false },
  { h: 2, i: 'overdueActions', w: 4, x: 8, y: 2, moved: false, static: false },
  { h: 2, i: 'calendar', w: 8, x: 0, y: 2, moved: false, static: false },
]
const controllerDashboardItems = defaultDashboardItems

// Get the default Items that the logged in user should see for the dashboard
// This should only really need to be run on a first time for any user
// Since once the user 'saves' a dashboard after it's loaded.  The items would be saved
const getDefaultItemsToUse = (state, { accessRoles }) => {
  const { isCot, isPayrollAdmin, isPayrollAnalyst, isFinanceAdmin, isPayrollDataEntry, isAccounting, isControlling } = accessRoles
  if (isCot) return defaultDashboardItems
  if (isPayrollAdmin) return payrollAdminDashboardItems
  if (isPayrollAnalyst) return payrollAnalystDashboardItems
  if (isFinanceAdmin) return financeAdminDashboardItems
  if (isPayrollDataEntry) return payrollDataEntryDashboardItems
  if (isAccounting) return accountingDashboardItems
  if (isControlling) return controllerDashboardItems
}

// There shouldn't be any extra items returned from the server after
// the dashboard goes live, as we should load the default items for any user
// who has not 'saved' a dashboard.  After they have saved the dashboard
// the returned items would be equal (not referencing sizes etc, just the key)
const filterOutIncorrectSavedItems = (items, defaultItems) => {
  if (!items.length) return defaultItems
  return items.filter(item => {
    const foundItem = defaultItems.find(defaultItem => defaultItem.i === item.i)
    return foundItem
  })
}

export const getFilteredDashboardItems = createSelector(getDefaultItemsToUse, ({ Dashboard }, defaultItemsToUse) => {
  let items = Dashboard.all().toRefArray().map(dashboard => ({ ...dashboard }))

  return filterOutIncorrectSavedItems(items, defaultItemsToUse)
})
