import React from 'react'
import { useSelector } from 'react-redux'

import InnerWrapperTabs from 'layouts/InnerWrapperTabs'
import { reportScreenTabLabels } from 'utils/locales/report.en'

import { getUserSpecialRights } from 'redux/selectors/employees'
import { isCot } from 'redux/selectors/auth'

const InnerWrapper = (props) => {
  const specialRights = useSelector(getUserSpecialRights)
  const isCotUser = useSelector(isCot)
  const hasAccessToReportDesigner = specialRights?.reportDesigner || isCotUser
  const tabs = [
    { name: reportScreenTabLabels.customize, to: `/reporting/customize-report` },
    hasAccessToReportDesigner && { name: reportScreenTabLabels.designer, to: `/reporting/report-designer` },
    { name: reportScreenTabLabels.recent, to: `/reporting/recent-reports` },
    { name: reportScreenTabLabels.saved, to: `/reporting/saved-reports` },
  ].filter(Boolean)

  return <InnerWrapperTabs tabs={tabs} {...props} />
}

export default InnerWrapper
