import React, { useRef, useState, useEffect } from 'react'
import { Field } from 'redux-form'
import Form from 'components/form/Form'
import PropTypes from 'prop-types'
import CustomSelectField from 'components/form/select/CustomSelectField'
import CheckboxOption from 'components/form/select/CheckboxOption'
import FiltersEmployeeReportContainer from '../routes/CustomizeReport/containers/FiltersEmployeeReportContainer'
import FiltersAuditTrailReportContainer from '../routes/CustomizeReport/containers/FiltersAuditTrailReportContainer'
import FiltersPayrunReportContainer from '../routes/CustomizeReport/containers/FiltersPayrunReportContainer'
import FiltersPayrollReportContainer from '../routes/CustomizeReport/containers/FiltersPayrollReportContainer'
import FiltersEmployeeDataChangesReportContainer from '../routes/CustomizeReport/containers/FiltersEmployeeDataChangesReportContainer'
import Modal from 'components/Modal'
import ReportingCurrencyForm from './ReportingCurrencyForm'
import FiltersChangeLogReportContainer from '../routes/CustomizeReport/containers/FiltersChangeLogReportContainer'
import FiltersGlobalPayrunManagementReportContainer from '../routes/CustomizeReport/containers/FiltersGlobalPayrunManagementReportContainer'
import ReportResultsModalView from '../components/ReportResultsModalView'
import FiltersGlobalPayAndTaxesReportContainer from '../routes/CustomizeReport/containers/FiltersGlobalPayAndTaxesReportContainer'
import { FiltersAccessManagementReportContainer } from '../routes/CustomizeReport/containers/FiltersAccessManagementReportContainer'

const ReportingFilters = (props) => {
  const {
    selectedType,
    types,
    isFieldDisabled,
    disabledReason,
    resetForm,
    readonlyFields = {},
    mostRecentRunReport = {},
    selectedReport,
    categories,
    subcategories,
    isCategoriesDisabled,
    isSubCategoriesDisabled,
    combinedSubcategories,
    onCombinedSubcategoryChange,
    viewReportClass = '',
  } = props
  // eslint-disable-next-line no-unused-vars
  const [isFormVisible, setIsFormVisible] = useState(null)
  const [report, setReport] = useState(null)

  const reportTypesForMultiSubset = ['PayrollReport', 'GlobalPayAndTaxesReport', 'OrgUnitVarianceReport']
  const shouldShowMultiReportSubset = reportTypesForMultiSubset.includes(selectedType)
  const isGLobalPayAndTaxesReport = selectedType === 'GlobalPayAndTaxesReport'

  const skipSubcategoryOverwrite = selectedType === 'OrgUnitVarianceReport'

  const rateModal = useRef(null)
  const modalViewReportModal = useRef(null)

  /**
   * Handle modal opening
   * @param modalRef
   */
  const handleModalOpen = (modalRef) => {
    setIsFormVisible(null)
    modalRef.current.showModal()
  }

  useEffect(() => {
    if (mostRecentRunReport) setReport(mostRecentRunReport)
  }, [mostRecentRunReport])

  return (
    <>
      <Modal ref={rateModal} className='c-modal c-modal--full c-modal--overflow-y' modalHeading='Do you want to use predefined fx rate or custom rate?'>
        <ReportingCurrencyForm onHide={() => rateModal.current.hideModal()} {...props} />
      </Modal>
      {report && (
        <Modal
          ref={modalViewReportModal}
          className='c-modal c-modal--full c-modal--overflow-y'
          topLevelClassnames='u-margin-top-small'
          modalHeading=''
          data-testid='view-report-modal'
        >
          <ReportResultsModalView reportId={report.id} selectedReport={selectedReport} onDeleteCallBack={() => modalViewReportModal?.current?.hideModal()} />
        </Modal>
      )}
      <Form {...props}>
        <div className={`o-layout ${viewReportClass}`}>
          <div className='o-layout__item u-padding-left-small u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
            <div className='c-modern-input'>
              <Field
                name='type'
                label='Report Type *'
                component={CustomSelectField}
                className='c-custom-select--transparent c-custom-select--left-padding'
                labelClassName='c-label u-text--small u-text--curious'
                formGroupClassName='u-margin-none'
                placeholder='Select'
                disabled={isFieldDisabled || readonlyFields.type}
                disabledReason={disabledReason}
                options={types.map((filter) => ({
                  value: filter.type,
                  label: filter.name,
                }))}
                onChanged={(value) => {
                  resetForm()
                }}
                data-testid='type-wrapper'
              />
            </div>
          </div>
          <div className='o-layout__item u-padding-left-small u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
            <div className='c-modern-input'>
              <Field
                name='category'
                label='Report Category *'
                component={CustomSelectField}
                className='c-custom-select--transparent c-custom-select--left-padding'
                labelClassName='c-label u-text--small u-text--curious'
                formGroupClassName='u-margin-none'
                placeholder='Select'
                disabled={isFieldDisabled || isCategoriesDisabled || readonlyFields.category}
                disabledReason={disabledReason}
                options={categories.map((cat) => ({
                  value: cat.value,
                  label: cat.label,
                }))}
                enableRemovedOptionFix
                data-testid='category-wrapper'
              />
            </div>
          </div>
          <div
            className={`o-layout__item u-padding-left-small u-margin-bottom u-1/1
        u-1/2@tablet u-1/4@desktop ${shouldShowMultiReportSubset ? 'u-hidden-visually' : ''}`}
          >
            <div className='c-modern-input'>
              <Field
                name='subcategory'
                label='Report Subset *'
                component={CustomSelectField}
                className='c-custom-select--transparent c-custom-select--left-padding'
                labelClassName='c-label u-text--small u-text--curious'
                formGroupClassName='u-margin-none'
                placeholder='Select'
                disabled={isFieldDisabled || isSubCategoriesDisabled || readonlyFields.subcategory}
                disabledReason={disabledReason}
                options={subcategories.map((subcat) => ({
                  value: subcat.value,
                  label: subcat.label,
                }))}
                enableRemovedOptionFix
                data-testid='subcategory-wrapper'
              />
            </div>
          </div>
          {shouldShowMultiReportSubset && (
            <div className='o-layout__item u-padding-left-small u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
              <div className='c-modern-input'>
                <Field
                  name='combinedSubcategory'
                  label='Report Subset *'
                  component={CustomSelectField}
                  optionComponent={CheckboxOption}
                  className='c-custom-select--transparent c-custom-select--left-padding'
                  labelClassName='c-label u-text--small u-text--curious'
                  formGroupClassName='u-margin-none'
                  placeholder='Select'
                  disabled={isFieldDisabled || isSubCategoriesDisabled || readonlyFields.combinedSubcategory}
                  disabledReason={disabledReason}
                  multi
                  disableSelectAllOption={!isGLobalPayAndTaxesReport}
                  options={combinedSubcategories}
                  {...(!isFieldDisabled
                    ? {
                      onChanged: (values) => {
                        const fieldsNotToReset = readonlyFields.fieldsNotToReset || []
                        onCombinedSubcategoryChange(values, fieldsNotToReset, skipSubcategoryOverwrite)
                      },
                    }
                    : {})}
                  enableRemovedOptionFix
                  data-testid='combinedSubcategory-wrapper'
                />
              </div>
            </div>
          )}

          {/* Load the corresponding next filters based on the selected type.
        Every container describes specific filters according to the selected report type. */}
          {selectedType === 'EmployeeReport' && (
            <FiltersEmployeeReportContainer handleReportModalOpen={() => handleModalOpen(modalViewReportModal)} {...props} />
          )}
          {selectedType === 'AccessRightsReport' && (
            <FiltersAccessManagementReportContainer handleReportModalOpen={() => handleModalOpen(modalViewReportModal)} {...props} />
          )}
          {['AuditTrailReport', 'WorkflowTimelinesReport'].includes(selectedType) && (
            <FiltersAuditTrailReportContainer handleReportModalOpen={() => handleModalOpen(modalViewReportModal)} {...props} />
          )}
          {selectedType === 'PayrollInstanceReport' && (
            <FiltersPayrunReportContainer
              handleReportModalOpen={() => handleModalOpen(modalViewReportModal)}
              handleModalOpen={() => handleModalOpen(rateModal)}
              {...props}
            />
          )}
          {selectedType === 'PayrollReport' && (
            <FiltersPayrollReportContainer
              handleReportModalOpen={() => handleModalOpen(modalViewReportModal)}
              handleModalOpen={() => handleModalOpen(rateModal)}
              {...props}
            />
          )}
          {['EmployeeDataChangesReport', 'VarianceReport', 'OrgUnitVarianceReport', 'WorkforceVarianceReport'].includes(selectedType) && (
            <FiltersEmployeeDataChangesReportContainer
              handleReportModalOpen={() => handleModalOpen(modalViewReportModal)}
              handleModalOpen={() => handleModalOpen(rateModal)}
              {...props}
            />
          )}
          {selectedType === 'GlobalPayrunManagementReport' && (
            <FiltersGlobalPayrunManagementReportContainer
              handleReportModalOpen={() => handleModalOpen(modalViewReportModal)}
              handleModalOpen={() => handleModalOpen(rateModal)}
              {...props}
            />
          )}
          {selectedType === 'GlobalPayAndTaxesReport' && (
            <FiltersGlobalPayAndTaxesReportContainer
              handleReportModalOpen={() => handleModalOpen(modalViewReportModal)}
              handleModalOpen={() => handleModalOpen(rateModal)}
              {...props}
            />
          )}
          {selectedType === 'ChangeLogReport' && (
            <FiltersChangeLogReportContainer handleReportModalOpen={() => handleModalOpen(modalViewReportModal)} {...props} />
          )}
        </div>
      </Form>
    </>
  )
}

ReportingFilters.propTypes = {
  selected: PropTypes.object,
  // All reporting types
  types: PropTypes.array,
  fxRates: PropTypes.array,
  currencies: PropTypes.array,
  selectedType: PropTypes.string,
  resetForm: PropTypes.func,
  resetFormFields: PropTypes.func,
  setReportingCurrencyDetails: PropTypes.func,
  // Disabled field check
  isFieldDisabled: PropTypes.bool,
  disabledReason: PropTypes.string,
  selectedReport: PropTypes.number,
  dispatch: PropTypes.func,
  readonlyFields: PropTypes.object,
  mostRecentRunReport: PropTypes.object,
  categories: PropTypes.array,
  subcategories: PropTypes.array,
  isCategoriesDisabled: PropTypes.bool,
  isSubCategoriesDisabled: PropTypes.bool,
  combinedSubcategories: PropTypes.array,
  onCombinedSubcategoryChange: PropTypes.func,
  viewReportClass: PropTypes.string,
}

export default ReportingFilters
