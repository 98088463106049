import createSelector from 'utils/createSelector'
import { getCategories } from 'redux/selectors/termCategories'
import { getSubCategories } from 'redux/selectors/termSubCategories'
import { getTerms } from 'redux/selectors/terms'
import { types } from 'redux/config/reports'
import { makeFilteredIdsByFilter } from 'redux/selectors/filters'
import { orderBy, isArray, isEmpty, uniq, includes, replace } from 'lodash'
import { createFilter } from 'utils/redux/filter'
import { getCountriesByAuth } from 'redux/selectors/country'
import { getCompaniesByCountriesByAuth } from 'redux/selectors/company'
import { getProcesses } from 'redux/selectors/processes'
import { getFilteredPayrolls } from './payrolls'
import { getCurrencyByPayroll } from './currencies'
import { getPayrollProcessOwners } from '../routes/CustomizeReport/containers/selectors/payrollInstanceProcess'
import {
  getCCTsSubCategoriesByCompaniesAndCategories,
  getCCTsByCompaniesAndCategoriesAndSubcategories,
} from '../routes/CustomizeReport/containers/selectors/companyCountryTermPivot'
import { getCompletedReportCreateJobByIdAndFeCategory, getCompletedReportCreateJobs } from 'redux/selectors/pollingJobs'
import { consoleTextErrors } from 'utils/locales/errors.en'

const getReportId = (state, props) => parseInt(props.reportId)
const getCatNames = (state, props) => props.catNames
const getSelectedCategories = (state, props) => props.categories
const getSelectedSubCategories = (state, props) => props.subcategories
const getMostRecentReport = (state, props) => props.mostRecentReport
const getFilteredIds = makeFilteredIdsByFilter('reports')

export const getRecentReportsForDashboard = createSelector(
  getFilteredIds,
  ({ Report, Country, Company, Payroll, BusinessUnit, CostCenter, EmployeeSystemUser, Category, SubCategory, Term, Currency }, filteredIds) => {
    let recentReports = Report.all().toRefArray()
    recentReports = orderBy(recentReports, ['createdAt["date"]'], ['desc']).slice(0, 10)
    return recentReports
      .filter((report) => filteredIds.includes(report.id))
      .filter((report) => removeReportsWithDeletedEntities(report, Payroll, Country, Company, EmployeeSystemUser, Category, SubCategory, Term))
      .map((report) => getReports(report, Country, Company, Payroll, BusinessUnit, CostCenter, EmployeeSystemUser, Category, SubCategory, Term, Currency))
  }
)

export const getRecentReports = createSelector(
  ({ Report, Country, Company, Payroll, BusinessUnit, CostCenter, EmployeeSystemUser, Category, SubCategory, Term, Currency }) => {
    let recentReports = Report.all().toRefArray()
    recentReports = orderBy(recentReports, ['createdAt["date"]'], ['desc']).slice(0, 10)

    return recentReports
      .filter((report) => removeReportsWithDeletedEntities(report, Payroll, Country, Company, EmployeeSystemUser, Category, SubCategory, Term))
      .map((report) => getReports(report, Country, Company, Payroll, BusinessUnit, CostCenter, EmployeeSystemUser, Category, SubCategory, Term, Currency))
  }
)

// In case of deleted Report filter's entity, we should not list/return the report.
// For example, imagine we create a report filtered by payroll with id 1.
// No matter we delete the payroll (id=1), its value will be still part of the report's filters.
const removeReportsWithDeletedEntities = (report, Payroll, Country, Company, EmployeeSystemUser, Category, SubCategory, Term) => {
  const { payroll, country, company, processOwner, termCategory, termSubcategory, term } = report.filters
  try {
    isArray(payroll) && payroll.forEach((id) => Payroll.withId(id))
    isArray(country) && country.forEach((id) => Country.withId(id))
    isArray(company) && company.forEach((id) => Company.withId(id))
    isArray(processOwner) && processOwner.forEach((id) => EmployeeSystemUser.withId(id))
    isArray(termCategory) && termCategory.forEach((id) => Category.withId(id))
    isArray(termSubcategory) && termSubcategory.forEach((id) => SubCategory.withId(id))
    isArray(term) && term.forEach((id) => Term.withId(id))
  } catch (e) {
    return false
  }

  return true
}

export const getSavedReports = createSelector(
  ({ Report, Country, Company, Payroll, BusinessUnit, CostCenter, EmployeeSystemUser, Category, SubCategory, Term, Currency }) => {
    let savedReports = Report.all()
      .toRefArray()
      .filter((report) => report.saved)
    savedReports = orderBy(savedReports, ['createdAt["date"]'], ['desc'])
    return savedReports
      .filter((report) => removeReportsWithDeletedEntities(report, Payroll, Country, Company, EmployeeSystemUser, Category, SubCategory, Term))
      .map((report) => getReports(report, Country, Company, Payroll, BusinessUnit, CostCenter, EmployeeSystemUser, Category, SubCategory, Term, Currency))
  }
)

const getReportingCurrency = (report, Payroll, Currency) => {
  let reportingCurrency = []

  if (!isEmpty(report.namedParams) && report.namedParams.fxRate) {
    reportingCurrency = report.namedParams.fxRate
  } else if (isEmpty(report.namedParams) && !isEmpty(report.filters.payroll)) {
    const payrollIds = report.filters.payroll

    payrollIds.map((payrollId) => {
      const payroll = Payroll.filter((payroll) => payroll.id === payrollId).count() && Payroll.withId(payrollId)

      if (payroll) reportingCurrency.push(Currency.withId(payroll.currency.id).abbreviature)
    })
  }

  return [...new Set(reportingCurrency)]
}

export const getReports = (report, Country, Company, Payroll, BusinessUnit, CostCenter, EmployeeSystemUser, Category, SubCategory, Term, Currency) => {
  return {
    ...report,
    reportingCurrency: getReportingCurrency(report, Payroll, Currency),
    filters: {
      ...report.filters,
      countryNames: isArray(report.filters.country) ? report.filters.country.map((country) => Country.withId(country).name) : [],
      companyNames: isArray(report.filters.company) ? report.filters.company.map((company) => Company.withId(company).name) : [],
      payrollNames: report.namedFilters.payroll || [],
      payrollInstanceNames: report.namedFilters.payrollInstance || [],
      businessUnitNames: report.namedFilters.businessUnit || [],
      costCenterNames: report.namedFilters.costCenter || [],
      departmentNames: report.namedFilters.department || [],
      processOwnerNames: isArray(report.filters.processOwner) ? report.filters.processOwner.map((owner) => EmployeeSystemUser.withId(owner).getName()) : [],
      termCategoryNames: isArray(report.filters.termCategory) ? report.filters.termCategory.map((cat) => Category.withId(cat).name) : [],
      termSubCategoryNames: isArray(report.filters.termSubcategory) ? report.filters.termSubcategory.map((subCat) => SubCategory.withId(subCat).name) : [],
      termNames: isArray(report.filters.term) ? report.filters.term.map((term) => Term.withId(term).name) : [],
      employmentStatus: report.namedFilters.employmentStatus || [],
      modifiedBy: report.namedFilters.modifiedBy || [],
      modifiedByWithFilter: report.filters.modifiedBy || [],
    },
  }
}

export const getReportById = createSelector(getReportId, ({ Report }, reportId) => {
  const report = Report.withId(reportId)
  return {
    ...report.ref,
  }
})

export const getMostRecentRunReportByTypeID = (state, reportId) => {
  let report = getReportById(state, { reportId })
  return getFilterReportDetails(state, { mostRecentReport: report })
}

export const getMostRecentJobById = ({ pollingJob, ...rest }, selectedReport) => {
  const lastCreatedJobid = pollingJob.lastCreatedId
  const job = getCompletedReportCreateJobByIdAndFeCategory(rest, { jobId: lastCreatedJobid, feCategoryId: selectedReport })
  return job
}

export const getReportWithNames = (state, reportId) => {
  const report = getReportById(state, { reportId })
  return {
    ...report,
    filters: {
      ...report.filters,
      countryNames: report.namedFilters.country || [],
      companyNames: report.namedFilters.company || [],
      payrollNames: report.namedFilters.payroll || [],
      payrollInstanceNames: report.namedFilters.payrollInstance || [],
      businessUnitNames: report.namedFilters.businessUnit || [],
      costCenterNames: report.namedFilters.costCenter || [],
      departmentNames: report.namedFilters.department || [],
    },
  }
}

export const getMostRunRecentReport = (state) => {
  try {
    const jobId = state.pollingJob.lastCreatedId
    const job = jobId && getCompletedReportCreateJobs(state).find((j) => j.id === jobId)
    const hasJob = !isEmpty(job) && !state.reports.isFetching && !isEmpty(state.reports.filters)
    if (hasJob) {
      const reportId = job.entityId
      return reportId ? getReportWithNames(state, reportId) : null
    }
  } catch (error) {
    console.error(consoleTextErrors.onReportingGeneration, error)
  }
}

const getFilterReportDetails = createSelector(
  getMostRecentReport,
  ({ Report, Country, Company, Payroll, BusinessUnit, CostCenter, EmployeeSystemUser, Category, SubCategory, Term, Currency, report }, mostRecentReport) => {
    return {
      ...mostRecentReport,
      reportingCurrency: getReportingCurrency(mostRecentReport, Payroll, Currency),
      filters: {
        ...mostRecentReport.filters,
        countryNames: isArray(mostRecentReport.filters.country) ? mostRecentReport.filters.country.map((country) => Country.withId(country).name) : [],
        companyNames: isArray(mostRecentReport.filters.company) ? mostRecentReport.filters.company.map((company) => Company.withId(company).name) : [],
        payrollNames: mostRecentReport.namedFilters.payroll || [],
        payrollInstanceNames: mostRecentReport.namedFilters.payrollInstance || [],
        businessUnitNames: mostRecentReport.namedFilters.businessUnit || [],
        costCenterNames: mostRecentReport.namedFilters.costCenter || [],
        departmentNames: mostRecentReport.namedFilters.department || [],
        processOwnerNames: isArray(mostRecentReport.filters.processOwner)
          ? mostRecentReport.filters.processOwner.map((owner) => EmployeeSystemUser.withId(owner).getName())
          : [],
        termCategoryNames: isArray(mostRecentReport.filters.termCategory) ? mostRecentReport.filters.termCategory.map((cat) => Category.withId(cat).name) : [],
        termSubCategoryNames: isArray(mostRecentReport.filters.termSubcategory)
          ? mostRecentReport.filters.termSubcategory.map((subCat) => SubCategory.withId(subCat).name)
          : [],
        termNames: isArray(mostRecentReport.filters.term) ? mostRecentReport.filters.term.map((term) => Term.withId(term).name) : [],
        employmentStatus: mostRecentReport.namedFilters.employmentStatus || [],
      },
    }
  }
)

const getReportByType = (state, { type }) => types.find((report) => report.type === type)
const getSubcategory = (state, { subcategory }) => subcategory

export const getTermCategoriesReportFilter = createSelector(getCategories, getCatNames, (session, categories, catNames) =>
  categories.filter((cat) => catNames.includes(cat.name))
)

/**
 * Combine a report (by type) subcategories with term categories
 * into one list.
 *
 * That's the business requirement.
 * From programming point of view, these categories should be separated in two
 * different arrays and later visualized in two different select components.
 *
 * However, here's what we have and what we did.
 * 1. Each report has subcategories `redux/config/reports`.
 * 2. Here we combine these subcategories (1.) with the selected term categories.
 * 3. Important rule is - only categories from one type (report subcategories or term categories),
 * can be selected. That's the reason we have `disabled` property in the below list.
 */
export const getCombinedSubcategories = createSelector(
  getTermCategoriesReportFilter,
  getReportByType,
  getSubcategory,
  (session, categories, report, subcategory) => {
    const isTermsSubcategory = subcategory === 'Terms'
    const isOrgUnitVarianceReport = report.type === 'OrgUnitVarianceReport'

    if (isOrgUnitVarianceReport) {
      return report.subcategories.filter((subcategory) => subcategory.value !== 'OrgUnitVarianceReport')
    }
    return [
      ...report.subcategories
        // We remove this report's subcategory,
        // because on its place, we include the term categories (
        .filter((subcategory) => subcategory.value !== 'Terms')
        .map((subcategory) => ({
          ...subcategory,
          disabled: subcategory && isTermsSubcategory,
        })),
      ...categories.map((category) => ({
        value: `Terms-${category.id}`,
        label: category.name,
        disabled: subcategory && !isTermsSubcategory,
      })),
    ]
  }
)

export const getSubCategoriesByCategories = createSelector(getSubCategories, getSelectedCategories, (session, subcategories, selectedCategories) =>
  subcategories.filter((subcat) => selectedCategories.includes(subcat.termCategory))
)

export const getTermsBySubCategories = createSelector(getTerms, getSelectedSubCategories, (session, terms, selectedSubCategories) =>
  terms.filter((term) => selectedSubCategories.includes(term.termSubcategory))
)

export const buildUpInitialValuesForReports = (state, initValues, values, catNames) => {
  let hasElementsSelections = false
  if (initValues.allCountriesSelected) {
    initValues['country'] = getCountriesByAuth(state).map((item) => item.id)
  }

  if (initValues.allCompaniesSelected) {
    initValues['company'] = getCompaniesByCountriesByAuth(state, {
      countriesIds: initValues['country'],
    }).map((i) => i.id)
  }

  if (initValues.servicesSelection && !state.processes.isFetching) {
    const processes = getProcesses(state)
    const foundItem = processes.filter((i) => initValues.servicesSelection.includes(i.name))
    try {
      initValues['process'] = foundItem && foundItem.length === 1 ? foundItem[0].id : null
    } catch (error) {
      initValues['process'] = null
    }
  }

  let hasPayrollFetched = false

  if (initValues.allPayrollsSelected && state.payrolls.filters) {
    initValues['fxRate'] = null
    const filters = createFilter({
      company: initValues['company'],
      sort: [{ name: 'id', order: 'desc' }],
    })
    if (state.payrolls.filters[filters.name]) {
      initValues['payrollFetching'] = state.payrolls.filters[filters.name].isFetching

      if (!state.payrolls.filters[filters.name].isFetching) {
        initValues['payroll'] = getFilteredPayrolls(state, { filter: filters.name }).map((i) => i.id)
        if (initValues.hasPayrollNoneOption) {
          initValues['payroll'] = [...initValues['payroll'], 0]
        }
        hasPayrollFetched = true

        if (initValues.hasPayollCurrencySelection && initValues.hasPayollCurrencySelection === 'setCurrency') {
          const selectedPayrolls = initValues['payroll']
          let payrollCurrency
          if (isArray(selectedPayrolls) && selectedPayrolls.length) {
            payrollCurrency = uniq(selectedPayrolls.map((payroll) => getCurrencyByPayroll(state, { payrollId: payroll })))
            initValues['payrollCurrency'] = payrollCurrency
            initValues['reportingCurrency'] = payrollCurrency.length === 1 ? payrollCurrency : null
          }

          if (!isArray(selectedPayrolls) && selectedPayrolls) {
            payrollCurrency = getCurrencyByPayroll(state, {
              payrollId: selectedPayrolls,
            })
            initValues['payrollCurrency'] = [payrollCurrency]
            initValues['reportingCurrency'] = [payrollCurrency]
          }
        }
      }
    }
  }
  if (initValues.allProcessOwnersSelected && hasPayrollFetched) {
    const processOwners = initValues['payroll'] && getPayrollProcessOwners(state, { ids: initValues['payroll'] })
    initValues['processOwner'] = processOwners
  }

  if (initValues.combinedSubcategorySelection) {
    let combined =
      values.type && values.category
        ? getCombinedSubcategories(state, {
          type: values.type,
          subcategory: values.subcategory,
          catNames,
        })
        : []
    initValues['combinedSubcategory'] = combined.filter((combine) => initValues.combinedSubcategorySelection.includes(combine.label)).map((i) => i.value)
  }
  if (initValues.allCombinedSubcategorySelection) {
    let combined =
      values.type && values.category
        ? getCombinedSubcategories(state, {
          type: values.type,
          subcategory: values.subcategory,
          catNames,
        })
        : []
    initValues['combinedSubcategory'] = combined.map((i) => i.value)
  }

  if (initValues.allElementsSubCategoriesSelected) {
    const selectorFilter = {
      companiesIds: initValues['company'] || [],
      categoriesIds: values.combinedSubcategory
        ? values.combinedSubcategory.filter((value) => includes(value, 'Terms-')).map((category) => parseInt(replace(category, /Terms-/g, '')))
        : [],
      isPayAndTaxesReport: initValues ? initValues.isPayAndTaxesReport : false,
    }
    initValues['termSubcategory'] = getCCTsSubCategoriesByCompaniesAndCategories(state, selectorFilter).map((i) => i.id)
    hasElementsSelections = true
    if (initValues.allElementsSelected) {
      initValues['term'] = getCCTsByCompaniesAndCategoriesAndSubcategories(state, {
        ...selectorFilter,
        subcategoriesIds: initValues['termSubcategory'] || [],
      }).map((i) => i.id)
      hasElementsSelections = true
    }
  }

  if (initValues.shouldShowElements) {
    hasElementsSelections = true
  }

  if (initValues.populateElementsSubCategoriesSelected) {
    hasElementsSelections = true
    const selectorFilter = {
      companiesIds: [],
      categoriesIds: values.combinedSubcategory
        ? values.combinedSubcategory.filter((value) => includes(value, 'Terms-')).map((category) => parseInt(replace(category, /Terms-/g, '')))
        : [],
      isPayAndTaxesReport: initValues ? initValues.isPayAndTaxesReport : false,
    }
    initValues['termSubcategory'] = getCCTsSubCategoriesByCompaniesAndCategories(state, selectorFilter).map((i) => i.id)
    initValues['termSubcategory'] = initValues.termSubcategorySelection
  }

  if (initValues['combinedSubcategory']) {
    const termCategories = initValues['combinedSubcategory']
      .filter((value) => includes(value, 'Terms-'))
      .map((category) => parseInt(replace(category, /Terms-/g, ''), 10))
    if (termCategories.length) {
      initValues['subcategory'] = 'Terms'
      initValues['termCategory'] = termCategories
    }
  }

  return { initValues, hasElementsSelections }
}
