export const asideTitles = {
  home: 'Home',
  clients: 'Clients',
  users: 'Users',
  company: 'Company',
  people: 'Employees',
  payroll: 'Payroll',
  toolbox: 'Toolbox',
  calendar: 'Calendar',
  actions: 'Actions',
  alerts: 'Alerts',
  admin: 'Admin',
  countries: 'Countries',
  globalTerms: 'Global Terms',
  globalTermsSubcategories: 'Global Term Subcategories',
  globalServices: 'Global Services',
  processes: 'Processes',
  steps: 'Steps',
  vendors: 'Vendors',
  cotUsers: 'COT Users',
  picc: 'Control Center',
  resources: 'Resources',
}

export const asideCosVendorTitles = {
  icpCosting: 'ICP Costing',
  icpSlas: 'ICP SLAs',
}

export const asideVendorTitles = {
  toolbox: 'Toolbox',
  alerts: 'Alerts',
}
