import React from 'react'
import PropTypes from 'prop-types'
import Fetcher from 'containers/Fetcher'
import { connect } from 'react-redux'
import { getEmployeeFieldsByCountry, getEmployeePersonalFields, getEmployeeWithCountryName } from '../../../selectors/employeesORM'
import { isEmployeeOnly } from 'redux/selectors/auth'
import createEmployeeFilter from 'redux/filters/createEmployeeFilter'
import EmploymentStatusHelper from 'utils/helperClasses/EmploymentStatuses'
import { PersonalViewContainer } from './PersonalViewContainer'
import { getBankAccountsByEmployee } from 'redux/selectors/bankAccounts'
import { getCompanies } from 'redux/selectors/company'
import { getCountries } from 'redux/selectors/country'
import { sortByName } from 'utils/strings'
import { isMobilePhoneDisabled } from 'redux/selectors/employees'
import useEmployeeCustomFields from 'hooks/useEmployeeCustomFields'
import Loader from 'components/Loader'
import { retrieveCompanyCustomFields } from 'routes/Companies/modules/actions'

const PersonalContainer = (props) => {
  const { fields, isFetchingExtraFields } = useEmployeeCustomFields(retrieveCompanyCustomFields, props.employee.companyRef?.id, [
    props.employee.companyRef?.id,
  ])

  if (isFetchingExtraFields) return <Loader />
  return <PersonalViewContainer extraFields={fields} {...props} />
}

PersonalContainer.propTypes = {
  employee: PropTypes.object,
}

const mapStateToProps = (state, props) => {
  const employee = getEmployeeWithCountryName(state, { employeeId: props.match.params.employeeId })
  const employeeCustomFieldsObj = getEmployeeFieldsByCountry(state, { country: employee.companyCountry.name })
  const employeeCustomFields = employeeCustomFieldsObj ? getEmployeePersonalFields(state, { country: employee.companyCountry.name }) : []
  const EmployeeStatus = new EmploymentStatusHelper(employee.employmentStatus)
  const isMobilePhoneFieldDisabled = isMobilePhoneDisabled(state, { employeeId: employee.id })

  return {
    bankAccounts: getBankAccountsByEmployee(state, { employeeId: props.match.params.employeeId }),
    employee,
    typeOfEmploymentSelectedValue: employee.employmentStatus,
    EmployeeStatus,
    employeeCustomFields,
    mobileFieldOptions: {
      isDisabled: isMobilePhoneFieldDisabled,
      disabledReason: isMobilePhoneFieldDisabled ? 'Please go to settings' : '',
    },
    companies: getCompanies(state),
    countries: getCountries(state).sort(sortByName),
    isEmployeeOnly: isEmployeeOnly(state),
  }
}

const Container = connect(mapStateToProps)(PersonalContainer)

export default Fetcher(Container, [
  'countries',
  'companies',
  'currencies',
  'bankAccounts',
  'bankAccountTypes',
  createEmployeeFilter({ name: 'employeeCountryPivot' }),
  createEmployeeFilter({ name: 'employeeUserAddresses', filterName: 'employeeUser' }),
  createEmployeeFilter({ name: 'employeeSystemUsers', filterName: 'id' }),
])
