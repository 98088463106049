import React from 'react'
import { buildStyles, CircularProgressbar } from 'react-circular-progressbar'
import Moment from 'components/Moment'
import { Link } from 'react-router-dom'
import Flag from 'components/Flag'
import PropTypes from 'prop-types'
import ReversedFlagRenderer from 'components/payruns/ReversedFlagRenderer'
import { SelectColumnFilter } from 'components/table/filters'
import { sortByLabel } from 'utils/strings'
import { defaultFormat, payrollInstanceHoverDates } from 'utils/tableDataFormatters'
import PayslipTable from 'components/table/PayslipTable'
import { PADLOCK_ICONS_TYPES, PadlockIcon } from 'components/PadlockIcons'
import ConfirmationModal from 'components/ConfirmationModal'
import Modal from 'components/Modal'
import { payrunStatesEnum } from 'utils/enums/payrunStates'
import StepperConfirmationModal from 'components/StepperConfirmationModal'
import Loader from 'components/Loader'
import PayrollsPinButton from './PayrollsPinButton'
import { ALL_ROUTES } from 'configs/routes'
import { getLockUnlockPayloadString } from 'utils/sections/payruns/lockUnlock'
import { NotesIcon } from 'components/icons/NoteIcons'
import { isEmpty, isUndefined } from 'utils/fnkit/typeChecks'
import ProgressStatus from 'components/payruns/ProgressStatus'

let classNames = require('classnames')

const columnFilter = { type: 'TextFilter', delay: 1000, placeholder: 'Filter' }

class PayrollInstanceTable extends React.Component {
  constructor (props) {
    super(props)
    this.unlockConfirmationModal = React.createRef()
    this.lockConfirmationModal = React.createRef()
    this.confirmUnlockAlertModal = React.createRef()
    this.confirmLockAlertModal = React.createRef()
    this.blockedUnlockAlertModal = React.createRef()
    this.permissionsAlertModal = React.createRef()
    this.confirmUnPin = React.createRef()
    this.unsuccessfulGTNAlertModal = React.createRef()
    this.successLockWithReimportGTN = React.createRef()
    this.reimportGTNConfirmation = React.createRef()
    this.reimportGTNConfirmationWithPayrunLocked = React.createRef()

    this.state = {
      lockingInProcess: false,
      stepperProcessFinished: false,
      payrollInstance: null,
    }
  }

  componentDidMount () {
    const isFromBackButton = this?.props?.history?.action === 'POP'

    if (this.props.modalState.showConfirmUnlock) {
      return this.confirmUnlockAlertModal.current.showModal()
    }

    if (this.props.modalState.showConfirmLock) {
      return this.confirmLockAlertModal.current.showModal()
    }

    if (!isFromBackButton && this.props.modalState.successLockWithGTNReimport && this.props.payrunState === payrunStatesEnum.ACTIVE) {
      return this.successLockWithReimportGTN.current.showModal()
    }

    if (this.props.modalState.reimportGTNConfirmationModal) {
      return this.reimportGTNConfirmation.current.showModal()
    }

    if (this.props.modalState.reimportGTNConfirmationWithPayrunLocked) {
      return this.reimportGTNConfirmationWithPayrunLocked.current.showModal()
    }
  }

  handleTimedLockingProcess = (source, modalStateChange) => {
    this.setState({ lockingInProcess: true })
    this.props
      .onHandleSubmit({
        id: this.state.payrollInstance.id,
        kuLockUnlockPayrun: getLockUnlockPayloadString(this.state.payrollInstance.newLockUnlock.isPayrollInstanceLocked),
      })
      .then((res) => {
        this.setState({ lockingInProcess: false, stepperProcessFinished: true })
        source.current?.hideModal()
        if (res.hasError) {
          this.blockedUnlockAlertModal.current?.showModal()
        }
        if (!res.hasError) {
          this.props.onModalStateChange(modalStateChange)
        }
      })
  }

  getModalHeading = () => {
    const { payrollInstance } = this.state

    if (payrollInstance) {
      const { companyName, countryName, payrollName, period } = payrollInstance
      return `${companyName}, ${countryName}, ${payrollName}, ${period}`
    }
    return ''
  }

  renderTableHeadings (displayOptions) {
    const { payrunState, hasAccess, payrolls, countries, allCompaniesCountries, filter, hasUnlockedInstances } = this.props
    const isFuturePayrun = payrunState === payrunStatesEnum.INACTIVE
    const isPreviousPayrun = payrunState === payrunStatesEnum.PREVIOUS

    const isAccordionLayout = isFuturePayrun || isPreviousPayrun

    const countriesOptions = isFuturePayrun ? allCompaniesCountries : countries

    let headings = []

    if (!isAccordionLayout) {
      headings.push({
        accessor: 'country',
        Header: 'Country',
        setWidth: '120px',
        disableSortBy: true,
        classNames: 'u-text--center',
        Filter: SelectColumnFilter,
        dropDownOptions: {
          name: 'country',
          defaultValue: filter.params['country'] || null,
          options: countriesOptions.map((c) => ({
            value: `${c.value}`,
            label: c.label,
          })),
        },
      })
    }

    headings.push({
      accessor: 'payrollInstances',
      isKey: true,
      Header: 'Payroll Name',
      setWidth: '170px',
      disableSortBy: true,
      Filter: !isAccordionLayout && SelectColumnFilter,
      dropDownOptions: {
        name: 'payrollInstances',
        defaultValue: filter.params['payroll'] || null,
        options: payrolls
          .map((p) => ({
            value: `${p.value}`,
            label: p.label,
          }))
          .sort(sortByLabel),
      },
    })

    if (isPreviousPayrun) {
      headings.push({
        accessor: 'reversed',
        disableSortBy: true,
        disableFilters: true,
        Header: '',
        setWidth: '20px',
      })
    }

    if (displayOptions.showCount) {
      headings.push({
        accessor: 'showCount',
        Header: 'Headcount',
        classNames: 'u-text-overflow--unset u-white-space--unset text-center',
        disableSortBy: true,
        disableFilters: true,
        setWidth: '98px',
      })
    }

    if (displayOptions.showStatus) {
      headings.push({
        accessor: 'showStatus',
        Header: 'Status',
        disableSortBy: true,
        disableFilters: true,
        setWidth: '98px',
      })
    }

    if (displayOptions.showProgress) {
      headings.push({
        accessor: 'showProgress',
        Header: 'Progress',
        classNames: 'u-text-overflow--unset u-white-space--unset text-center',
        disableFilters: true,
        disableSortBy: true,
        setWidth: '85px',
      })
    }

    if (isAccordionLayout) {
      headings.push(
        {
          accessor: 'fromDateString',
          Header: 'From Date',
          setWidth: '80px',
          classNames: 'u-text-overflow--unset u-white-space--unset u-text--left',
          disableSortBy: true,
          filter: columnFilter,
        },
        {
          accessor: 'toDateString',
          Header: 'To Date',
          setWidth: '80px',
          classNames: 'u-text-overflow--unset u-white-space--unset u-text--left',
          disableSortBy: true,
          filter: columnFilter,
        }
      )
    }

    headings.push({
      accessor: 'payDateString',
      Header: 'Pay Date',
      setWidth: '80px',
      classNames: 'u-text-overflow--unset u-white-space--unset u-text--left',
      disableSortBy: true,
      filter: columnFilter,
      Cell: !isAccordionLayout ? payrollInstanceHoverDates : defaultFormat,
    })

    if (!isPreviousPayrun && !isFuturePayrun) {
      headings.push(
        {
          accessor: 'steps',
          Header: 'Steps',
          disableFilters: true,
          disableSortBy: true,
          setWidth: '200px',
        },
        {
          accessor: 'actions',
          Header: 'Actions',
          disableFilters: true,
          disableSortBy: true,
          classNames: 'u-text-overflow--unset u-white-space--unset text-left',
          columnClassName: 'text-left',
          setWidth: '60px',
        },
        {
          accessor: 'processPayrun',
          Header: 'Process Payrun',
          disableFilters: true,
          disableSortBy: true,
          setWidth: '80px',
          classNames: 'u-text-overflow--unset u-white-space--unset text-center',
          actionCell: true,
        }
      )

      if (hasAccess) {
        headings.push({
          accessor: 'changePayrun',
          Header: 'Change Payrun',
          disableSortBy: true,
          disableFilters: true,
          classNames: 'u-text-overflow--unset u-white-space--unset text-center',
          setWidth: '80px',
        })
      }
    }
    if (isPreviousPayrun && hasAccess && hasUnlockedInstances) {
      headings.push({
        accessor: 'changePayrun',
        Header: 'Change Payrun',
        disableSortBy: true,
        disableFilters: true,
        classNames: 'u-text-overflow--unset u-white-space--unset text-center',
        setWidth: '80px',
      })
    }

    if (isFuturePayrun) {
      if (hasAccess) {
        headings.push({
          accessor: 'changePayrun',
          Header: 'Change Payrun',
          disableSortBy: true,
          disableFilters: true,
          classNames: 'u-text-overflow--unset u-white-space--unset text-center',
          setWidth: '40px',
        })
      }
      headings.push({
        accessor: 'joiners',
        Header: 'Joiners & Leavers',
        disableSortBy: true,
        disableFilters: true,
        classNames: 'u-text-overflow--unset u-white-space--unset text-center',
        setWidth: '80px',
      })
    }

    headings.push({
      accessor: 'files',
      Header: 'Files',
      disableSortBy: true,
      disableFilters: true,
      setMinWidth: '50px',
      classNames: 'u-text-overflow--unset u-white-space--unset text-center',
      setWidth: '50px',
    })

    if (!isFuturePayrun) {
      headings.push({
        accessor: 'analyze',
        Header: 'Analyze',
        disableSortBy: true,
        disableFilters: true,
        setMinWidth: '70px',
        classNames: 'u-text-overflow--unset u-white-space--unset text-center',
        setWidth: '75px',
      })
    }

    headings.push({
      accessor: 'notes',
      Header: 'Notes',
      disableSortBy: true,
      disableFilters: true,
      classNames: 'u-text--center u-text-overflow--unset u-white-space--unset',
      setWidth: '60px',
    })

    if (!isFuturePayrun) {
      headings.push({
        accessor: 'padlock',
        Header: '',
        disableSortBy: true,
        disableFilters: true,
        classNames: 'u-text--center u-text-overflow--unset u-white-space--unset',
        setWidth: '30px',
      })
    }

    return headings
  }

  togglePayrollInstanceLockUnlock = (payrollInstance) => {
    const { isCot } = this.props
    const { isPayrollInstanceLocked, isWithRightLockUnlock, isKUGlobalOwner, isPayrollInstanceOnStepToLockUnlock } = payrollInstance.newLockUnlock

    this.setState(() => ({
      payrollInstance: payrollInstance,
    }))
    const hasUserLockUnlockPermission = isWithRightLockUnlock || isKUGlobalOwner

    if (!hasUserLockUnlockPermission && !isCot) {
      return this.permissionsAlertModal.current.showModal()
    }

    if (!isPayrollInstanceOnStepToLockUnlock) {
      return this.blockedUnlockAlertModal.current.showModal()
    }

    if (isPayrollInstanceLocked) {
      return this.unlockConfirmationModal.current.showModal()
    }

    this.lockConfirmationModal.current.showModal()
  }

  onPinClicked = (item) => {
    if (!this.props.canPinPayroll && !item.pinned) {
      return
    }
    this.setState(() => ({ payrollInstance: item }))
    if (this.props.requireConfirmBeforeUnPin) {
      this.confirmUnPin.current.showModal()
      return
    }

    this.props.onPinRow(item.payroll, item.pinned)
  }

  renderTableRows (payrollInstances, displayOptions) {
    const {
      payrunState,
      onActionButtonClick,
      onOptionsButtonClick,
      onFilesButtonClick,
      onJoinersButtonClick,
      hasAccess,
      location,
      accordionIdx,
      canPinPayroll,
      analyzeBtnUrl,
    } = this.props

    const isPreviousPayrun = payrunState === payrunStatesEnum.PREVIOUS
    const queryForAccordion = !isUndefined(accordionIdx) ? `&idx=${accordionIdx}` : ''

    let pathname = location.pathname
    let alternativePath = pathname

    return payrollInstances.map((payrollInstance) => {
      if (payrollInstance.status === payrunStatesEnum.REOPENED) {
        alternativePath = `/${ALL_ROUTES.PAYRUNS.BASE}/${ALL_ROUTES.PAYRUNS.REOPENED}`
      }
      const { isPayrollInstanceLocked } = payrollInstance?.newLockUnlock || {}

      return {
        isPinned: payrollInstance.pinned,
        payrollInstanceId: payrollInstance.id,
        country: (
          <div className=''>
            <div className='u-text--left d-flex ai-center jc--center'>
              <PayrollsPinButton
                id={payrollInstance.id}
                pinned={payrollInstance.pinned}
                canPinPayroll={canPinPayroll}
                onClick={() => {
                  this.onPinClicked(payrollInstance)
                }}
              />
              <Flag flag={payrollInstance.countryAbbr.toLowerCase()} title={`${payrollInstance.countryName} - ${payrollInstance.companyName}`} size='tiny' />
            </div>
          </div>
        ),
        title: payrollInstance.payrollName,
        payrollInstances: (
          <span className='c-badge' title={payrollInstance.payrollName}>
            {payrollInstance.shortName}
          </span>
        ),

        showCount: displayOptions.showCount && <div className='text-center'>{payrollInstance.employees.length || 0}</div>,

        showStatus: displayOptions.showStatus && payrollInstance.progressStatus && <ProgressStatus {...payrollInstance} />,

        showProgress: displayOptions.showProgress && (
          <div className='d-flex jc--center'>
            <div style={{ width: 40, height: 40 }}>
              <CircularProgressbar
                value={payrollInstance.progressPercentage || 0}
                text={`${payrollInstance.progressPercentage || 0}%`}
                styles={buildStyles({ textSize: '1.5rem', textColor: '#000', pathColor: `#5292dd` })}
              />
            </div>
          </div>
        ),

        reversed: isPreviousPayrun && (
          <div className='o-grid o-grid--middle o-grid--reverse'>
            <ReversedFlagRenderer instance={payrollInstance} />
          </div>
        ),

        fromDateString: <Moment format='DD/MM/YYYY'>{payrollInstance.fromDate.date}</Moment>,
        toDateString: <Moment format='DD/MM/YYYY'>{payrollInstance.toDate.date}</Moment>,
        payDateString: (
          <div className='u-text--left'>
            <Moment format='DD/MM/YYYY'>{payrollInstance.payDate.date}</Moment>
          </div>
        ),

        steps: !isPreviousPayrun && (
          <div>
            <span data-testid={`payrollInstanceId-${payrollInstance.id}`}>
              {payrollInstance.currentStepName} / {payrollInstance.alphabeticStepRange}
            </span>
          </div>
        ),

        actions: !isPreviousPayrun && (
          <div className='text-left d-flex ai-center jc--space-between'>
            <span className='u-margin-right-tiny'>
              {payrollInstance.completedStepTasksCount} / {payrollInstance.totalStepTasksCount}
            </span>
            <ReversedFlagRenderer instance={payrollInstance} />
          </div>
        ),

        processPayrun: !isPreviousPayrun && (
          <div className='text-center'>
            <Link
              to={`${location.pathname}?action=openActionsModal&id=${payrollInstance.id}${queryForAccordion}`}
              className='c-btn c-btn--tiny c-btn--curious u-text--tiny text-center'
              onClick={onActionButtonClick(payrollInstance.id)}
              data-testid={`actions-button-${payrollInstance.id}`}
              title='Actions'
            >
              Actions
            </Link>
          </div>
        ),

        changePayrun: (isPreviousPayrun ? !isPayrollInstanceLocked && hasAccess : hasAccess) && (
          <div className='text-center'>
            <button
              className='c-btn c-btn--tiny c-btn--curious u-text--tiny'
              onClick={onOptionsButtonClick(payrollInstance)}
              data-testid={`options-button-${payrollInstance.id}`}
              title='Options'
            >
              Options
            </button>
          </div>
        ),
        joiners: (
          <div className='text-center'>
            <Link
              to={`${location.pathname}?action=openJoinersModal&id=${payrollInstance.id}${queryForAccordion}`}
              className='c-btn c-btn--tiny c-btn--curious'
              onClick={onJoinersButtonClick ? onJoinersButtonClick(payrollInstance.id) : null}
              title='View'
              data-testid={`joiners-button-${payrollInstance.id}`}
            >
              <span className='icon icon--preview' />
            </Link>
          </div>
        ),
        files: (
          <div className='text-center'>
            <Link
              to={`${location.pathname}?action=openFilesModal&id=${payrollInstance.id}${queryForAccordion}`}
              className='c-btn c-btn--tiny c-btn--curious u-text--tiny'
              onClick={onFilesButtonClick ? onFilesButtonClick(payrollInstance.id) : null}
              title='Files'
              data-testid={`files-button-${payrollInstance.id}`}
            >
              Files
            </Link>
          </div>
        ),
        analyze: (
          <div className='text-center'>
            <Link
              to={`${payrollInstance.id}/${analyzeBtnUrl}`}
              className='c-btn c-btn--tiny c-btn--curious u-text--tiny'
              onClick={() => null}
              title='Analyze'
              data-testid={`analyze-button-${payrollInstance.id}`}
            >
              <span className='icon icon--bar-chart' />
            </Link>
          </div>
        ),
        notes: (
          <div className='o-grid o-grid--middle o-grid--center'>
            <Link className='d-flex jc-center ai-center' to={`${alternativePath}/${payrollInstance.id}/notes`}>
              <NotesIcon className='u-relative' title='Notes' data-testid={`payrollInstance-${payrollInstance.id}-notes`}>
                {payrollInstance.isLastAddedNoteFromOtherUser && <span className='c-notification__indicator u-bg--supernova' />}
              </NotesIcon>
            </Link>
          </div>
        ),

        padlock: (
          <div className='o-grid o-grid--middle o-grid--center'>
            <button
              type='button'
              className={`
                c-btn
                c-btn--transparent
                c-btn--small
                border-none
              `}
              data-testid={`padlock-${payrollInstance.id}`}
              onClick={() => this.togglePayrollInstanceLockUnlock(payrollInstance)}
            >
              <PadlockIcon type={isPayrollInstanceLocked ? PADLOCK_ICONS_TYPES.padlockLocked : PADLOCK_ICONS_TYPES.padlockUnlocked} />
            </button>
          </div>
        ),
      }
    })
  }

  getNewLockUnlockDetailsFromState () {
    const newLockUnlock = this.state.payrollInstance?.newLockUnlock
    if (isEmpty(newLockUnlock)) return {}
    const { isPayrollInstanceLocked, isWithRightGtnReImport, isKUGlobalOwner, lastGtnDocument } = newLockUnlock
    return { isPayrollInstanceLocked, isWithRightGtnReImport, isKUGlobalOwner, lastGtnDocument }
  }

  getLastGtnDocumentDetails (documentData) {
    const newLockUnlock = documentData
    if (isEmpty(newLockUnlock)) return {}
    const { id: docId, name: docName, version: docVersion } = documentData
    return { docId, docName, docVersion }
  }

  getHasGTNReimportRights ({ isKUGlobalOwner, isWithRightGtnReImport }) {
    return isKUGlobalOwner || isWithRightGtnReImport
  }

  render () {
    const {
      payrollInstances,
      selectedPayroll,
      onSubmit,
      selectedCountry,
      showCount = false,
      showProgress = false,
      showStatus = false,
      onFilter,
      isFetching,
      payrunState,
      showFilters = false,
      onHandleSubmit,
      state,
      modalState,
      onModalStateChange,
      onZeroTouchSubmit,
      ...rest
    } = this.props

    const options = {
      noDataMessage: 'No matching records found.',
      pageSize: rest.pagination && rest.pagination.limit,
      sizePerPageList: [
        { value: 25, label: 25 },
        { value: 50, label: 50 },
        { value: 100, label: 100 },
      ],
      pageIndex: rest.pagination && rest.pagination.currentPage,
      pageCount: rest.pagination && rest.pagination.totalPages,
      showPageSizeSelector: rest.pagination && rest.pagination.totalCount >= 25,
      onPageChange: ({ pageSize, pageIndex, filters }) => {
        const isChangedFilter = pageIndex === rest.pagination?.currentPage
        const page = isChangedFilter ? 0 : pageIndex
        const limit = pageSize

        if (!isFetching) {
          let normalizedFilters = {}
          filters.forEach((filter) => {
            normalizedFilters[filter.id] = filter
          })
          this.props.onFilter({
            page,
            limit,
            filters: normalizedFilters,
          })
        }
      },
    }

    const displayOptions = { showProgress, showCount, showStatus }
    const newLockUnlock = this.state.payrollInstance?.newLockUnlock
    const { isPayrollInstanceLocked } = newLockUnlock || {}
    const lastRecordPinned = payrollInstances.findLast((i) => i.pinned)
    return (
      <>
        <PayslipTable
          modifierClasses='react-bs-container-body u-padding-bottom-large'
          tableElementClassName='table--layout-fixed table-payrollInstances'
          wrapperClassName={classNames({
            'u-1/1 react-bs-table--overflow-auto': true,
            'u-half-opacity': isFetching,
          })}
          noDataMessage='There are no payrolls created.'
          headings={this.renderTableHeadings(displayOptions)}
          options={options}
          data={this.renderTableRows(payrollInstances, displayOptions)}
          pagination
          showFilters={showFilters}
          remote
          skipPageReset={isFetching}
          totalCount={rest.pagination && rest.pagination.totalCount}
          data-testid='payrollInstance-table'
          trBodyClassName={(row) => {
            let css = 'u-text--valhalla'
            if (lastRecordPinned && row.original.payrollInstanceId === lastRecordPinned.id) {
              css += ' row-is-pinned '
            }
            return css
          }}
        />
        <ConfirmationModal
          ref={this.unlockConfirmationModal}
          className='c-modal u-text--valhalla'
          modalHeading={this.getModalHeading()}
          onConfirm={() => {
            onHandleSubmit({
              id: this.state.payrollInstance.id,
              kuLockUnlockPayrun: getLockUnlockPayloadString(this.state.payrollInstance.newLockUnlock.isPayrollInstanceLocked),
            }).then(
              (res) =>
                !res.hasError &&
                onModalStateChange({
                  showConfirmLock: false,
                  showConfirmUnlock: true,
                  heading: this.getModalHeading(),
                })
            )
          }}
        >
          <div className='u-text--center u-text--normal u-weight--regular'>
            <p>
              <PadlockIcon type={PADLOCK_ICONS_TYPES.padlockLockedBig} />
            </p>
            <p>{this.state.payrollInstance?.payrollName || 'Payrun'} is currently locked for changes. Do you want to unlock the Payrun File?</p>
          </div>
        </ConfirmationModal>

        <StepperConfirmationModal
          ref={this.lockConfirmationModal}
          className={`c-modal u-text--valhalla ${this.state.lockingInProcess ? 'hide-modal-close' : ''}`}
          modalHeading={this.getModalHeading()}
          hideNextStep={this.state.lockingInProcess || this.state.stepperProcessFinished}
          disableBackdropClose={this.state.lockingInProcess}
          onNextStep={() => {
            const newUnlockStates = this.getNewLockUnlockDetailsFromState()
            const gtnDetails = this.getLastGtnDocumentDetails(newLockUnlock?.lastGtnDocument)
            const hasGtnRights = this.getHasGTNReimportRights(newUnlockStates)
            const { hasGtnMappingReport, isLastGtnMappingReportSuccessful } = this.state.payrollInstance?.newLockUnlock || {}
            const showConfirmLock = !hasGtnMappingReport || (hasGtnMappingReport && isLastGtnMappingReportSuccessful)
            const stateChange = {
              showConfirmLock: !hasGtnRights || showConfirmLock,
              heading: this.getModalHeading(),
              successLockWithGTNReimport: hasGtnRights && !showConfirmLock,
              documentId: gtnDetails.docId,
              documentName: gtnDetails.docName,
              documentVersion: gtnDetails.docVersion,
            }
            this.handleTimedLockingProcess(this.lockConfirmationModal, stateChange)
          }}
        >
          <div className='u-text--center u-text--normal u-weight--regular'>
            {this.state.lockingInProcess && (
              <>
                <Loader />
                <div>Payrun File lock is in progress</div>
              </>
            )}
            {!this.state.lockingInProcess && !this.state.stepperProcessFinished && (
              <>
                <p className='u-padding-left'>
                  <PadlockIcon type={PADLOCK_ICONS_TYPES.padlockUnlockedBig} />
                </p>
                <p>{this.state.payrollInstance?.payrollName || 'Payrun'} is currently unlocked. Do you want to lock the Payrun File?</p>
              </>
            )}
          </div>
        </StepperConfirmationModal>

        <ConfirmationModal
          ref={this.successLockWithReimportGTN}
          className='c-modal u-text--valhalla'
          modalHeading={this.getModalHeading() || this.props.modalState.modalHeading}
          onHide={() =>
            onModalStateChange({
              successLockWithGTNReimport: false,
              heading: '',
            })
          }
          onConfirm={() => {
            onZeroTouchSubmit({ id: this.props.modalState.documentId }).then(
              (res) => res.success && this.reimportGTNConfirmationWithPayrunLocked.current.showModal()
            )
          }}
        >
          <div className='u-text--center u-text--normal u-weight--regular'>
            <p>
              <PadlockIcon type={PADLOCK_ICONS_TYPES.padlockLockedBig} />
            </p>
            <p>
              Payrun File successfully locked. Do you want to re-import GTN file
              <span className='u-weight--medium'>
                {' '}
                {this.props.modalState.documentName}
                {this.props.modalState.documentVersion ? `_V${this.props.modalState.documentVersion}` : ''}
              </span>{' '}
              ?
            </p>
          </div>
        </ConfirmationModal>

        <Modal
          ref={this.reimportGTNConfirmation}
          className='c-modal u-text--valhalla'
          modalHeading={'Payrun File is locked for changes and GTN Mapping and Import is in progress.'}
          onHide={() =>
            onModalStateChange({
              showConfirmUnlock: false,
              showConfirmLock: false,
              heading: '',
              reimportGTNConfirmationModal: false,
              successLockWithGTNReimport: false,
            })
          }
        >
          <div className='u-text--center u-text--normal u-weight--regular'>
            <div className='u-relative u-margin-bottom zt zt-animation' />
            <p>GTN Mapping Report will be produced and you will be notified of the result shortly in the "Alerts" section.</p>

            <button
              onClick={() => this.reimportGTNConfirmation.current.hideModal()}
              className='c-btn c-btn--small c-btn--curious u-padding-left u-padding-right'
              data-testid={`closeModal`}
            >
              OK
            </button>
          </div>
        </Modal>

        <Modal
          ref={this.reimportGTNConfirmationWithPayrunLocked}
          className='c-modal u-text--valhalla'
          modalHeading={'GTN Mapping and Import is in progress.'}
          onHide={() =>
            onModalStateChange({
              showConfirmLock: false,
              showConfirmUnlock: false,
              heading: '',
              reimportGTNConfirmationWithPayrunLocked: false,
              successLockWithGTNReimport: false,
            })
          }
        >
          <div className='u-text--center u-text--normal u-weight--regular'>
            <div className='u-relative u-margin-bottom zt zt-animation' />
            <p>GTN Mapping Report will be produced and you will be notified of the result shortly in the "Alerts" section.</p>

            <button
              onClick={() => this.reimportGTNConfirmationWithPayrunLocked.current.hideModal()}
              className='c-btn c-btn--small c-btn--curious u-padding-left u-padding-right'
              data-testid={`closeModal`}
            >
              OK
            </button>
          </div>
        </Modal>

        <Modal
          ref={this.confirmUnlockAlertModal}
          className='c-modal u-text--valhalla'
          modalHeading={this.getModalHeading() || modalState.modalHeading}
          onHide={() => onModalStateChange({ showConfirmUnlock: false, showConfirmLock: false, heading: '' })}
        >
          <div className='u-text--center u-text--normal u-weight--regular'>
            <p className='u-padding-left'>
              <PadlockIcon type={PADLOCK_ICONS_TYPES.padlockUnlockedBig} />
            </p>
            <p>Payrun File successfully unlocked.</p>

            <button
              onClick={() => {
                this.confirmUnlockAlertModal.current.hideModal()
                onModalStateChange({ showConfirmUnlock: false, showConfirmLock: false, heading: '' })
              }}
              className='c-btn c-btn--small c-btn--curious u-padding-left u-padding-right'
              data-testid={`closeModal`}
            >
              OK
            </button>
          </div>
        </Modal>

        <Modal
          ref={this.confirmLockAlertModal}
          className='c-modal u-text--valhalla'
          modalHeading={this.getModalHeading() || modalState.modalHeading}
          onHide={() => onModalStateChange({ showConfirmUnlock: false, showConfirmLock: false, heading: '' })}
        >
          <div className='u-text--center u-text--normal u-weight--regular'>
            <p>
              <PadlockIcon type={PADLOCK_ICONS_TYPES.padlockLockedBig} />
            </p>
            <p>Payrun File successfully locked.</p>

            <button
              onClick={() => {
                this.confirmLockAlertModal.current.hideModal()
                onModalStateChange({ showConfirmUnlock: false, showConfirmLock: false, heading: '' })
              }}
              className='c-btn c-btn--small c-btn--curious u-padding-left u-padding-right'
              data-testid={`closeModal`}
            >
              OK
            </button>
          </div>
        </Modal>

        <Modal ref={this.blockedUnlockAlertModal} className='c-modal u-text--valhalla' modalHeading={this.getModalHeading()}>
          <div className='u-text--center u-text--normal u-weight--regular'>
            <p className={isPayrollInstanceLocked ? '' : 'u-padding-left'}>
              <PadlockIcon type={isPayrollInstanceLocked ? PADLOCK_ICONS_TYPES.padlockLockedBigDisabled : PADLOCK_ICONS_TYPES.padlockUnlockedBigDisabled} />
            </p>
            <p>
              {`The Payrun File cannot be ${
                this.state.payrollInstance ? (isPayrollInstanceLocked ? 'unlocked' : 'locked') : 'lock/unlock'
              } at this stage of the payroll.`}
            </p>

            <button
              onClick={() => {
                this.blockedUnlockAlertModal.current.hideModal()
                onModalStateChange({ showConfirmUnlock: false, showConfirmLock: false, heading: '' })
              }}
              className='c-btn c-btn--small c-btn--curious u-padding-left u-padding-right'
              data-testid={`closeModal`}
            >
              OK
            </button>
          </div>
        </Modal>

        <Modal ref={this.unsuccessfulGTNAlertModal} className='c-modal u-text--valhalla' modalHeading={this.getModalHeading()}>
          <div className='u-text--center u-text--normal u-weight--regular'>
            <p className={isPayrollInstanceLocked ? '' : 'u-padding-left'}>
              <PadlockIcon type={isPayrollInstanceLocked ? PADLOCK_ICONS_TYPES.padlockLockedBigDisabled : PADLOCK_ICONS_TYPES.padlockUnlockedBigDisabled} />
            </p>
            <p>
              You may only use this functionality in the event of an unsuccessful GTN data import. If you need to unlock or lock the Payrun File, contact the
              Payslip Team.
            </p>

            <button
              onClick={() => {
                this.unsuccessfulGTNAlertModal.current.hideModal()
                onModalStateChange({ showConfirmUnlock: false, showConfirmLock: false, heading: '' })
              }}
              className='c-btn c-btn--small c-btn--curious u-padding-left u-padding-right'
              data-testid={`closeModal`}
            >
              OK
            </button>
          </div>
        </Modal>

        <Modal ref={this.permissionsAlertModal} className='c-modal u-text--valhalla' modalHeading={this.getModalHeading()}>
          <div className='u-text--center u-text--normal u-weight--regular'>
            <p className={isPayrollInstanceLocked ? '' : 'u-padding-left'}>
              <PadlockIcon type={isPayrollInstanceLocked ? PADLOCK_ICONS_TYPES.padlockLockedBigDisabled : PADLOCK_ICONS_TYPES.padlockUnlockedBigDisabled} />
            </p>
            <p>You are not authorised to {isPayrollInstanceLocked ? 'unlock' : 'lock'} the Payrun File, please contact your Global Owner for access.</p>

            <button
              onClick={() => {
                this.permissionsAlertModal.current.hideModal()
                onModalStateChange({ showConfirmUnlock: false, showConfirmLock: false, heading: '' })
              }}
              className='c-btn c-btn--small c-btn--curious u-padding-left u-padding-right'
              data-testid={`closeModal`}
            >
              OK
            </button>
          </div>
        </Modal>
        <ConfirmationModal
          ref={this.confirmUnPin}
          className='c-modal'
          modalHeading='Confirmation'
          onConfirm={() => {
            this.props.onPinRow(this.state.payrollInstance.payroll, this.state.payrollInstance.pinned)
          }}
        >
          <p>
            <em>
              <strong>This payroll will be unpinned from all tabs in the Payroll menu and the Dashboard.</strong>
            </em>
          </p>
          <p>Are you sure you want to unpin the payroll?</p>
        </ConfirmationModal>
      </>
    )
  }

  componentWillUnmount () {
    this.props.onFilter({ filters: {} })
  }
}

PayrollInstanceTable.propTypes = {
  payrollInstances: PropTypes.array,
  payrunState: PropTypes.string,
  onActionButtonClick: PropTypes.func,
  onOptionsButtonClick: PropTypes.func,
  handleModalOpen: PropTypes.func,
  onFilesButtonClick: PropTypes.func,
  onJoinersButtonClick: PropTypes.func,
  hasAccess: PropTypes.bool,
  isFetching: PropTypes.bool,
  showFilters: PropTypes.bool,
  location: PropTypes.object,
  onFilter: PropTypes.func.isRequired,
  showCount: PropTypes.bool,
  showProgress: PropTypes.bool,
  showStatus: PropTypes.bool,
  selectedPayroll: PropTypes.number,
  selectedCountry: PropTypes.number,
  onSubmit: PropTypes.func,
  payrolls: PropTypes.array,
  countries: PropTypes.array,
  allCompaniesCountries: PropTypes.array,
  filter: PropTypes.object,
  onHandleSubmit: PropTypes.func,
  state: PropTypes.object,
  isCot: PropTypes.bool,
  modalState: PropTypes.object,
  onModalStateChange: PropTypes.func,
  accordionIdx: PropTypes.number,
  canPinPayroll: PropTypes.bool,
  requireConfirmBeforeUnPin: PropTypes.bool,
  onZeroTouchSubmit: PropTypes.func,
}

export default PayrollInstanceTable
