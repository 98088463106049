import React, { useRef, useState } from 'react'
import { Field } from 'redux-form'
import InputField from 'components/form/InputField'
import CustomSelectField from 'components/form/select/CustomSelectField'
import Form from 'components/form/Form'
import CheckboxField from 'components/form/CheckboxField'
import PropTypes from 'prop-types'
import { Authorization } from 'containers/Authorization'
import { emailValidation, phoneValidation, required } from 'utils/validations'
import PhoneCodeNumberField from 'components/form/PhoneCodeNumberField'
import CheckboxOption from 'components/form/select/CheckboxOption'
import { getSelectionOptions } from 'utils/selectOptions'
import ConfirmationModal from 'components/ConfirmationModal'
import { sortByLabel } from 'utils/strings'
import { isRoleCotBot, isRoleCotUser } from 'utils/roles'
import SectionHeading from 'components/SectionHeading'
import { mode2faEmailOption } from 'redux/config/DropDownOptions/mode2faOptions'

const CotUserForm = (props) => {
  const {
    resetForm,
    countries,
    hasCredentials,
    resetUserPassword,
    workEmail,
    isMobilePhoneFieldDisabled,
    timezones,
    parentCompaniesTenants,
    cotRoleTypes,
    roleType,
    isPristine,
    isCotAdmin,
    isSSOEnabled,
  } = props

  const confirmUserPasswordReset = useRef(null)
  const [cotUserEmail, setCotUserEmail] = useState(null)

  return (
    <>
      <Form {...props} pristine={isPristine}>
        <div className='o-layout'>
          <div className='o-layout__item u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
            <div className='c-panel u-bg--white border-1-valhalla rounded u-padding-small'>
              <Field
                name='firstname'
                label='First Name *'
                labelClassName='c-label u-text--small u-text--curious'
                className='c-input c-input--transparent'
                formGroupClassName='u-margin-none'
                component={InputField}
                type='text'
                validate={required}
              />
            </div>
          </div>
          <div className='o-layout__item u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
            <div className='c-panel u-bg--white border-1-valhalla rounded u-padding-small'>
              <Field
                name='surname'
                label='Last Name *'
                labelClassName='c-label u-text--small u-text--curious'
                className='c-input c-input--transparent'
                formGroupClassName='u-margin-none'
                component={InputField}
                type='text'
                validate={required}
              />
            </div>
          </div>
          <div className='o-layout__item u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
            <div className='c-panel u-bg--white border-1-valhalla rounded u-padding-small'>
              <Field
                name='title'
                label='Title'
                component={CustomSelectField}
                labelClassName='c-label u-text--small u-text--curious'
                className='c-custom-select--transparent'
                formGroupClassName='u-margin-none'
                placeholder='Select'
                data-testid='title'
                options={getSelectionOptions('title')}
              />
            </div>
          </div>
          <div className='o-layout__item u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
            <div className='c-panel u-bg--white border-1-valhalla rounded u-padding-small'>
              <Field
                name='country'
                label='Country'
                labelClassName='c-label u-text--small u-text--curious'
                className='c-custom-select--transparent'
                formGroupClassName='u-margin-none'
                component={CustomSelectField}
                placeholder='Select'
                options={countries
                  .map((country) => ({
                    value: country.id,
                    label: country.name,
                  }))
                  .sort(sortByLabel)}
              />
            </div>
          </div>
          <div className='o-layout__item u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
            <div className='c-panel u-bg--white border-1-valhalla rounded u-padding-small'>
              <Field
                name='timezone'
                label='Timezone'
                labelClassName='c-label u-text--small u-text--curious'
                className='c-custom-select--transparent'
                formGroupClassName='u-margin-none'
                component={CustomSelectField}
                placeholder='Select'
                options={timezones.map((timezone) => ({
                  value: timezone.id,
                  label: timezone.zone,
                }))}
              />
            </div>
          </div>
          <div className='o-layout__item u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
            <div className='c-panel u-bg--white border-1-valhalla rounded u-padding-small'>
              <Field
                name='position'
                label='Position'
                labelClassName='c-label u-text--small u-text--curious'
                className='c-input c-input--transparent'
                formGroupClassName='u-margin-none'
                component={InputField}
                type='text'
              />
            </div>
          </div>
          <div className='o-layout__item u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
            <div className='c-panel u-bg--white border-1-valhalla rounded u-padding-small'>
              <Field
                name='department'
                label='Department'
                labelClassName='c-label u-text--small u-text--curious'
                className='c-input c-input--transparent'
                formGroupClassName='u-margin-none'
                component={InputField}
                type='text'
              />
            </div>
          </div>
          <div className='o-layout__item u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
            <div className='c-panel u-bg--white border-1-valhalla rounded u-padding-small'>
              <Field
                name='workEmail'
                label='Work Email *'
                labelClassName='c-label u-text--small u-text--curious'
                className='c-input c-input--transparent'
                formGroupClassName='u-margin-none'
                component={InputField}
                type='text'
                validate={emailValidation}
              />
            </div>
          </div>
          <div className='o-layout__item u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
            <div className='c-panel u-bg--white border-1-valhalla rounded u-padding-small'>
              <Field
                name='personalEmail'
                label='Personal Email'
                labelClassName='c-label u-text--small u-text--curious'
                className='c-input c-input--transparent'
                formGroupClassName='u-margin-none'
                component={InputField}
                type='text'
                validate={emailValidation}
              />
            </div>
          </div>
          <div className='o-layout__item u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
            <div className='c-panel u-bg--white border-1-valhalla rounded u-padding-small' data-testid='office-phone-wrapper'>
              <Field
                name='officePhone'
                label='Office Phone'
                component={PhoneCodeNumberField}
                type='text'
                labelClassName='c-label u-text--small u-text--curious'
                className='c-input c-input--transparent'
                formGroupClassName='u-margin-none'
                validate={phoneValidation}
              />
            </div>
          </div>
          <div className='o-layout__item u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
            <div className='c-panel u-bg--white border-1-valhalla rounded u-padding-small' data-testid='mobile-phone-wrapper'>
              <Field
                name='mobilePhone'
                label='Mobile Phone'
                component={PhoneCodeNumberField}
                type='text'
                labelClassName='c-label u-text--small u-text--curious'
                className='c-input c-input--transparent'
                formGroupClassName='u-margin-none'
                validate={phoneValidation}
                disabled={isMobilePhoneFieldDisabled}
                disabledReason='Please go to settings'
              />
            </div>
          </div>
          <div className='o-layout__item u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
            <div className='c-panel u-bg--white border-1-valhalla rounded u-padding-small'>
              <Field
                name='skype'
                label='Skype'
                labelClassName='c-label u-text--small u-text--curious'
                className='c-input c-input--transparent'
                formGroupClassName='u-margin-none'
                component={InputField}
                type='text'
              />
            </div>
          </div>
          <div className='o-layout__item u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
            <div className='c-panel u-bg--white border-1-valhalla rounded u-padding-small'>
              <Field
                name='gender'
                label='Gender'
                component={CustomSelectField}
                labelClassName='c-label u-text--small u-text--curious'
                className='c-custom-select--transparent'
                formGroupClassName='u-margin-none'
                placeholder='Select'
                options={[
                  { value: 'male', label: 'Male' },
                  { value: 'female', label: 'Female' },
                  { value: 'not stated', label: 'Not stated' },
                ]}
              />
            </div>
          </div>
          <div className='o-layout__item  u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
            <div className='c-panel u-bg--white border-1-valhalla rounded u-padding-small'>
              <Field
                name='hasCredentials'
                label='Account Status'
                component={CustomSelectField}
                labelClassName='c-label u-text--small u-text--curious'
                className='c-custom-select--transparent'
                formGroupClassName='u-margin-none'
                placeholder='Select'
                options={[
                  { value: true, label: 'Active' },
                  { value: false, label: 'Inactive' },
                ]}
              />
            </div>
          </div>
          {hasCredentials && (
            <div className='o-layout__item  u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
              <div className='c-panel u-bg--white border-1-valhalla rounded u-padding-small'>
                <Field
                  name='username'
                  label='Username'
                  component={InputField}
                  type='text'
                  labelClassName='c-label u-text--small u-text--curious'
                  className='c-input c-input--transparent'
                  formGroupClassName='u-margin-none'
                />
              </div>
            </div>
          )}
          {!isSSOEnabled && (
            <div className='o-layout__item  u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
              <div className='c-panel u-bg--white border-1-valhalla rounded u-padding-small'>
                <Field
                  name='mode2fa'
                  label='2FA Mode'
                  component={CustomSelectField}
                  labelClassName='c-label u-text--small u-text--curious'
                  className='c-custom-select--transparent'
                  formGroupClassName='u-margin-none'
                  placeholder='Select'
                  options={[...getSelectionOptions('mode2fa'), mode2faEmailOption]}
                />
              </div>
            </div>
          )}
          <div className='o-layout__item  u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
            <div className='c-panel u-bg--white border-1-valhalla rounded u-padding-small'>
              <Field
                name='receivesNotifications'
                label='Receive alerts in the system'
                component={CustomSelectField}
                labelClassName='c-label u-text--small u-text--curious'
                className='c-custom-select--transparent'
                formGroupClassName='u-margin-none'
                placeholder='Select'
                clearable={false}
                options={[
                  { value: 'yes', label: 'Yes' },
                  { value: 'no', label: 'No' },
                ]}
              />
            </div>
          </div>
          {hasCredentials && !isSSOEnabled && (
            <Authorization permissions={['COTUSER_EDIT']}>
              <div className='o-layout__item u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
                <div className='c-panel u-padding-top u-padding-bottom o-grid o-grid--medium'>
                  <div
                    onClick={() => {
                      setCotUserEmail({ email: workEmail })
                      confirmUserPasswordReset.current.showModal()
                    }}
                    className='c-btn c-btn--submit c-btn--small c-btn--curious u-padding-left u-padding-right'
                    data-testid='reset-user-password'
                  >
                    Reset User Password
                  </div>
                </div>
              </div>
            </Authorization>
          )}
        </div>
        {isCotAdmin && (
          <div>
            <div className='o-layout'>
              <div className='o-layout__item u-text--center u-1/1'>
                <h2 className='u-text--center u-text--huge u-weight--bold'>Choose rights</h2>
              </div>
            </div>
            <div className='o-layout'>
              <div className='o-layout__item u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
                <div className='c-panel u-bg--white border-1-valhalla rounded u-padding-small'>
                  <Field
                    name='roleType'
                    label='Role *'
                    labelClassName='c-label u-text--small u-text--curious'
                    className='c-custom-select--transparent c-custom-select--specific-normal'
                    formGroupClassName='u-margin-none'
                    component={CustomSelectField}
                    placeholder='Select'
                    options={cotRoleTypes.map((roleType) => ({
                      value: roleType.type,
                      label: roleType.name,
                    }))}
                    validate={required}
                    onChanged={resetForm}
                    data-testid='role-wrapper'
                  />
                </div>
              </div>
              {isRoleCotUser(roleType) && (
                <div className='o-layout__item u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
                  <div className='c-panel u-bg--white border-1-valhalla rounded u-padding-small'>
                    <Field
                      name='accessAreaParentCompany'
                      label='Access Areas *'
                      labelClassName='c-label u-text--small u-text--curious'
                      className='c-custom-select--transparent c-custom-select--specific-normal'
                      formGroupClassName='u-margin-none'
                      component={CustomSelectField}
                      optionComponent={CheckboxOption}
                      placeholder='Select'
                      options={parentCompaniesTenants.map((tenant) => ({
                        value: tenant.id,
                        label: tenant.name,
                      }))}
                      validate={required}
                      multi
                      data-testid='accessAreaParentCompany-wrapper'
                    />
                  </div>
                </div>
              )}
            </div>
            {!isRoleCotBot(roleType) && (
              <>
                <div className='u-text--center u-padding-top'>
                  <SectionHeading text='Special Rights' tagFullWidth tagTestId='heading-special-rights' />
                </div>
                <div className='o-layout__item u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop u-padding-left-none'>
                  <div className='c-panel u-bg--white border-1-valhalla rounded u-padding-small'>
                    <Field
                      type='checkbox'
                      name='beneficiaryFile'
                      label='Beneficiary File'
                      labelClassName='c-label u-text--small u-text--curious'
                      formGroupClassName='u-margin-none'
                      component={CheckboxField}
                      className='control__indicator__input'
                      indicatorClassName='control__indicator'
                      data-testid='beneficiary-file'
                    />
                  </div>
                </div>
                <div className='o-layout__item u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
                  <div className='c-panel u-bg--white border-1-valhalla rounded u-padding-small'>
                    <Field
                      type='checkbox'
                      name='bankFile'
                      label='Bank File'
                      formGroupClassName='u-margin-none'
                      component={CheckboxField}
                      className='control__indicator__input'
                      indicatorClassName='control__indicator'
                      data-testid='bank-file'
                    />
                  </div>
                </div>
                <div className='o-layout__item u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
                  <div className='c-panel u-bg--white border-1-valhalla rounded u-padding-small'>
                    <Field
                      type='checkbox'
                      name='dataMigration'
                      label='Data Migration'
                      formGroupClassName='u-margin-none'
                      component={CheckboxField}
                      className='control__indicator__input'
                      indicatorClassName='control__indicator'
                      data-testid='data-migration'
                    />
                  </div>
                </div>
              </>
            )}
          </div>
        )}
        <Authorization permissions={['COTUSER_EDIT', 'COTUSER_DELETE']}>
          <div className='o-layout'>
            <div className='o-layout__item u-text--center u-1/1'>
              <button
                type='submit'
                className='c-btn c-btn--submit c-btn--small c-btn--curious u-padding-left
              u-padding-right u-margin-top-small'
                disabled={isPristine}
                data-testid='submit'
              >
                Save
              </button>
            </div>
          </div>
        </Authorization>
      </Form>

      <ConfirmationModal ref={confirmUserPasswordReset} className='c-modal' modalHeading='Confirmation' onConfirm={() => resetUserPassword(cotUserEmail)}>
        <p>Are you sure you want to reset the user password?</p>
      </ConfirmationModal>
    </>
  )
}

CotUserForm.propTypes = {
  isCotAdmin: PropTypes.bool,
  resetForm: PropTypes.func,
  countries: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    })
  ),
  timezones: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      zone: PropTypes.string.isRequired,
    })
  ),
  parentCompaniesTenants: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    })
  ),
  cotRoleTypes: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })
  ),
  roleType: PropTypes.string,
  hasCredentials: PropTypes.bool,
  resetUserPassword: PropTypes.func,
  workEmail: PropTypes.string,
  isMobilePhoneFieldDisabled: PropTypes.bool,
  isPristine: PropTypes.bool,
}

export default CotUserForm
