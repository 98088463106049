import { reportScreenTabLabels } from 'utils/locales/report.en'

export const reportGroupingsEnum = {
  HR: 'HR Reports',
  FINANCE: 'Finance Reports',
  PAYROLL: 'Payroll Reports',
  CONTROL_AND_GOVERNANCE: 'Control & Governance Reports',
  REPORT_DESIGNER: reportScreenTabLabels.designer,
}

export const reportGroupingsDescriptionsEnum = {
  HR: 'Track your global workforce headcount and monitor changes',
  FINANCE: 'Measure, track and analyse your global payroll costs',
  PAYROLL: 'View and compare payroll data across multiple countries, companies and payrolls',
  CONTROL_AND_GOVERNANCE: 'Monitor internal control, compliance and governance reports',
}

export const reportSubcategoriesEnum = {
  TotalEmployerCosts: 'TotalEmployerCosts',
  VarianceReport: 'VarianceReport',
  OrgUnitVarianceReport: 'OrgUnitVarianceReport',
  WorkforceVarianceReport: 'WorkforceVarianceReport',
  EmployeePayrollChanges: 'EmployeePayrollChanges',
  PayAndTermsChanges: 'PayAndTermsChanges',
  GeneralUpdates: 'GeneralUpdates',
  RolesAndRights: 'RolesAndRights',
  GlobalPayrunManagement: 'GlobalPayrunManagement',
  PayrollInstance: 'PayrollInstance',
  ChangelogReportAll: 'ChangeLogReport-All',
}

export const reportsWithOnlyOneCountryToSelect = [
  reportSubcategoriesEnum.TotalEmployerCosts,
  reportSubcategoriesEnum.VarianceReport,
  reportSubcategoriesEnum.OrgUnitVarianceReport,
  reportSubcategoriesEnum.EmployeePayrollChanges,
  reportSubcategoriesEnum.PayAndTermsChanges,
  reportSubcategoriesEnum.GeneralUpdates,
  reportSubcategoriesEnum.RolesAndRights,
  reportSubcategoriesEnum.PayrollInstance,
  reportSubcategoriesEnum.WorkforceVarianceReport,
]
export const reportsWithOnlyOneCompanyToSelect = [
  reportSubcategoriesEnum.VarianceReport,
  reportSubcategoriesEnum.OrgUnitVarianceReport,
  reportSubcategoriesEnum.PayAndTermsChanges,
  reportSubcategoriesEnum.GeneralUpdates,
  reportSubcategoriesEnum.RolesAndRights,
  reportSubcategoriesEnum.PayrollInstance,
  reportSubcategoriesEnum.WorkforceVarianceReport,
]

export const reportsWithCombinedSubCategory = [reportSubcategoriesEnum.ChangelogReportAll]

// Dynamic selectors are for Country and Company only!
export const reportsWithDynamicSelectors = [reportSubcategoriesEnum.GlobalPayrunManagement]

export const reportsWithSinglePayroll = [
  reportSubcategoriesEnum.PayrollInstance,
  reportSubcategoriesEnum.VarianceReport,
  reportSubcategoriesEnum.WorkforceVarianceReport,
  reportSubcategoriesEnum.OrgUnitVarianceReport,
]

export const feReportIDsWithSingleCountryCompanyPayroll = [51]

export const reportsWithRemovedFields = {
  ChangeLogReport: ['payrollInstance'],
}
