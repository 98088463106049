export const reportCommonLabels = {
  areYouSureWantToDelete: 'Are you sure you want to delete the report?',
  tooLargeXls: 'Report is too large to generate in Excel',
}

export const reportingChartLabels = {
  avgOnTimeCompletionCompany: 'Average On Time Completion - Company',
  avgOnTimeCompletionPayrollProvider: 'Average On Time Completion - Payroll Provider',
  avgOnTimeCompletionGtnVersions: 'Average Number of GTN Versions',
}

export const reportScreenTabLabels = {
  customize: 'Report Library',
  designer: 'Report Designer',
  recent: 'Recent Reports',
  saved: 'Saved Reports',
}

export const reportNameLabels = {
  workForcePayrunDetail: 'Workforce + Payrun Detail',
}

export const reportFeCategoryIdLabels = {
  customBuildReports: {
    label: 'Workforce and Payrun Detail',
    description: 'Payroll totals alongside workforce detail across multiple pay periods',
  },
  orgUnitVarianceReport: {
    label: 'Org Unit Variance',
    description: 'Compare org unit changes across multiple pay periods and review variances by value or percentage',
  },
  workflowTimelinesReport: {
    label: 'Workflow Timelines',
    description: 'Extract a list of workflow timelines for any selected country and payroll',
  },
  workforceVarianceReport: {
    label: 'Workforce Variance',
    description: 'Compare workforce payroll data across multiple pay periods and review variances by value or percentage',
  },
}
