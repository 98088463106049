/* eslint-disable max-len */
import { connect } from 'react-redux'
import { getFormInitialValues, getFormValues, reduxForm } from 'redux-form'
import { diff } from 'utils/object'

import { areTasksCompleted, removeDisabledTasks } from 'redux/models/payrollInstanceTask'
import { invalidatePayrollInstanceTaskOwner, mergePayrollInstanceTaskOwners } from 'redux/actions/payrollInstanceTaskOwner'
import { invalidatePayrollInstances } from 'redux/actions/payrollInstances'
import { invalidatePayrollInstanceService } from 'redux/actions/payrollInstanceService'
import { invalidatePayrollInstanceProcess } from 'redux/actions/payrollInstanceProcess'
import { invalidatePayrollInstanceStep } from 'redux/actions/payrollInstanceStep'
import { invalidatePayrollInstanceTask } from 'redux/actions/payrollInstanceTask'
import { invalidatePayrollInstanceEmployeePivot } from 'redux/actions/payrollInstanceEmployeePivot'
import { invalidatePayrollInstanceCountryTermsPivot } from 'redux/actions/payrollInstanceCountryTermsPivot'

import Fetcher from 'containers/Fetcher'
import PayrollInstanceTaskOwnersEditView from '../components/PayrollInstanceTask/PayrollInstanceTaskOwnersEditView'

import { getPayrollInstanceRef } from 'redux/selectors/payrollInstance'
import { getPayrollInstanceTaskRef, getPayrollInstanceTasksThatAreNotCompletedRef } from '../selectors/payrollInstanceTasks'
import {
  getDisabledPayrollInstanceTaskOwnersByPITaskId,
  getInitials,
  getPayrollInstanceTaskOwnerScopesByPITaskId,
} from '../selectors/payrollInstanceTaskOwnerScopes'
import { createFilter } from 'utils/redux/filter'
import { removeTasksURLParameters } from 'utils/query'
import { showMessage } from 'redux/actions/modal'

const mapDispatchToProps = (dispatch, { onSubmit, payrollInstanceTaskId, payrollInstanceId }) => ({
  onSubmit: (data) =>
    dispatch((dispatch, getState) => {
      const state = getState()
      const initialFormValues = getFormInitialValues('payrollInstanceTaskOwnersEdit')(state)
      const instance = getPayrollInstanceRef(state, { payrollInstanceId })
      const disabledScopes = getDisabledPayrollInstanceTaskOwnersByPITaskId(state, { payrollInstanceTaskId })

      let normalized = removeDisabledTasks(data, disabledScopes)
      normalized = diff(initialFormValues, normalized)
      const taskFormValues = getFormValues('payrollInstanceTaskEdit')(state)
      const payrollInstanceTaskIds = Object.values(taskFormValues)
        .map((task) => task.id)
        .filter((t) => t !== payrollInstanceTaskId)

      const areStillToDoTasks = getPayrollInstanceTasksThatAreNotCompletedRef(state, { payrollInstanceTaskIds })
      return dispatch(mergePayrollInstanceTaskOwners(normalized)).then(() => {
        const formValues = getFormValues('payrollInstanceTaskOwnersEdit')(state)
        const areAllTasksCompleted = areTasksCompleted(formValues)
        onSubmit(areAllTasksCompleted)

        if (instance.isLastBeforeExtraStep && areStillToDoTasks.length === 0) {
          removeTasksURLParameters()

          dispatch(
            showMessage({
              body: 'Next payrun is generated. There are still steps and actions to be completed for the current payrun, please go on tab "Post Payroll Steps".',
            })
          )
        }

        dispatch(invalidatePayrollInstanceTaskOwner())
        dispatch(invalidatePayrollInstances())
        dispatch(invalidatePayrollInstanceService())
        dispatch(invalidatePayrollInstanceProcess())
        dispatch(invalidatePayrollInstanceStep())
        dispatch(invalidatePayrollInstanceTask())

        dispatch(invalidatePayrollInstanceEmployeePivot())
        dispatch(invalidatePayrollInstanceCountryTermsPivot())
      })
    }),
  dispatch,
})

const mapStateToProps = (state, { payrollInstanceId, payrollInstanceTaskId, isReadOnly = false }) => {
  const instance = getPayrollInstanceRef(state, { payrollInstanceId })
  const task = getPayrollInstanceTaskRef(state, { payrollInstanceTaskId })
  const scopes = getPayrollInstanceTaskOwnerScopesByPITaskId(state, { payrollInstanceTaskId })
  const initialValues = getInitials(state, { payrollInstanceTaskId })
  const payrollInstance = getPayrollInstanceRef(state, { payrollInstanceId })

  return {
    instance,
    task,
    scopes,
    initialValues,
    isReadOnly,
    isPayrollInstanceFrozen: payrollInstance?.newLockUnlock.isPayrollInstanceFrozen,
  }
}

const Component = connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    form: 'payrollInstanceTaskOwnersEdit',
  })(PayrollInstanceTaskOwnersEditView)
)

export default Fetcher(Component, [
  {
    name: 'employeeSystemUsers',
    params: [
      {
        _computed: { filter: (state, { payrollInstanceTaskId }) => createFilter({ payrollInstanceTask: payrollInstanceTaskId }) },
      },
    ],
  },
  {
    name: 'payrollInstances',
    params: [
      {
        _computed: { filter: (state, { payrollInstanceId }) => createFilter({ id: payrollInstanceId }) },
      },
    ],
  },
  {
    name: 'payrollInstanceTask',
    params: [
      {
        _computed: { filter: (state, { payrollInstanceTaskId }) => createFilter({ id: payrollInstanceTaskId }) },
      },
    ],
    isForceFetching: true,
  },
  {
    name: 'payrollInstanceTaskOwner',
    params: [
      {
        _computed: {
          filter: (state, { payrollInstanceTaskId }) => createFilter({ payrollInstanceTask: payrollInstanceTaskId }),
        },
      },
    ],
    isForceFetching: true,
  },
  {
    name: 'payrollInstanceTaskOwnerScope',
    params: [
      {
        _computed: {
          filter: (state, { payrollInstanceTaskId }) => createFilter({ payrollInstanceTask: payrollInstanceTaskId }),
        },
      },
    ],
    isForceFetching: true,
  },
  'businessUnits',
])
