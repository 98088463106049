import React from 'react'
import PayrollSummaryView from '../components/PayrollSummaryView'
import Fetcher from 'containers/Fetcher'
import { createFilter } from 'utils/redux/filter'
import { PayrollSummaryProvider } from '../PayrollSummaryContext'

const PayrollSummaryContainer = ({
  match: {
    params: { id },
  },
}) => (
  <PayrollSummaryProvider>
    <PayrollSummaryView payrollInstanceId={id} />
  </PayrollSummaryProvider>
)

export default Fetcher(PayrollSummaryContainer, [
  {
    name: 'payrollInstances',
    params: [
      {
        _computed: {
          forceVendorTenantAPI: (state, { vendorTenantUrl }) => !!vendorTenantUrl,
          filter: (_, props) => {
            const {
              match: {
                params: { id },
              },
            } = props
            return createFilter({
              id,
            })
          },
        },
      },
    ],
  },
])
