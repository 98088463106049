import React from 'react'
import { useDispatch } from 'react-redux'
import ReportsList from '../components/ReportsList'
import { downloadVendorsAndKeyUsersReport } from 'redux/actions/parentCompanies'

const ReportsContainer = () => {
  const dispatch = useDispatch()

  const reports = [
    {
      title: 'Client and ICP Key Users',
      description: 'Extract a complete list of active KUs + Vendor Users including their email addresses',
      icon: 'internal-report',
      reportName: 'vendorsAndKeyUsersReport'
    }
  ]

  const onDownload = (reportName) => {
    switch (reportName) {
    case 'vendorsAndKeyUsersReport':
      dispatch(downloadVendorsAndKeyUsersReport())
      break
    default:
      break
    }
  }

  return <ReportsList reports={reports} onDownloadClick={onDownload} />
}

export default ReportsContainer
