import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { Field } from 'redux-form'
import CustomSelectField from 'components/form/select/CustomSelectField'
import { statuses } from 'redux/config/payrollInstanceTasks'
import Form from 'components/form/Form'
import moment from 'moment'
import { owners } from 'redux/config/tasks'
import _ from 'lodash'
import PayslipTable from 'components/table/PayslipTable'

class PayrollInstanceTaskList extends React.Component {
  renderOwner (task) {
    const { onMultipleTaskClick } = this.props

    if (task.shouldShowMultiOwnersText) {
      return <span onClick={() => onMultipleTaskClick(task.id, false)}
        className='u-text--curious u-cursor--pointer' data-testid={`mao-${task.id}`}>Multiple owners</span>
    }

    if (task.ownerName === owners.company) return <span className='u-text--italic'>{task.ownerName}</span>

    return task.ownerName
  }

  renderAction (task, openFilesModal) {
    const { finishTaskLink, alphabeticName } = task

    if (!finishTaskLink) return <span>{alphabeticName}</span>
    if (_.includes(finishTaskLink, 'openFilesModal')) {
      return <Link to={finishTaskLink} className='u-text--curious' onClick={openFilesModal}
        data-testid={`actionName-${alphabeticName}`} >
        {alphabeticName}
      </Link>
    }

    return <Link to={finishTaskLink} className='u-text--curious' data-testid={`actionName-${alphabeticName}`} >
      {alphabeticName}
    </Link>
  }

  buildStatuses (task) {
    // Convert to options (value, label) Array
    let normalized = _.map(statuses, ({ value, label }) => ({
      value,
      label
    }))

    return normalized
      // Should or should not list `reverted` status
      .filter(status => {
        // Here the logic is just for the 'reverted' status only. Don't touch the rest ones.
        if (status.value !== statuses.reverted.value) return true

        // Leave `reverted` status in the case the task `isReversible` or the current task already had `reverted` status
        return task.isReversible || task.status === statuses.reverted.value
      })
      .map(status => ({
        ...status,
        // BE returns which statuses are disabled to be selected by the user.
        disabled: task.disabledStatuses.includes(status.value)
      }))
  }

  /**
   * render the popup rows
   * @param array tasks
   * @return array
   */
  renderPopupRows () {
    const { openFilesModal, tasks, isPayrollInstanceFrozen } = this.props
    return tasks.map(task => {
      return {
        action: this.renderAction(task, openFilesModal),
        actionOwner: this.renderOwner(task),
        dueDate: task.deadline ? moment(task.deadline.date).format('DD/MM/YYYY') : 'Not set',
        status: <Field
          name={`${JSON.stringify({ id: task.id })}.status`}
          label=''
          component={CustomSelectField}
          type='text'
          className={`c-custom-select--transparent c-custom-select--min-width--small
          ${isPayrollInstanceFrozen ? 'is-frozen' : ''}
          `}
          labelClassName='c-label'
          formGroupClassName='u-margin-none'
          clearable={false}
          placeholder='Select status'
          options={this.buildStatuses(task)}
          disabled={!task.isEditable || isPayrollInstanceFrozen}
          data-testid={`payrollInstanceTaskId-${task.id}`}
          aria-label={`${isPayrollInstanceFrozen
            ? 'Payrun file is currently unlocked for changes. Please lock it to complete any actions.'
            : ''}`}
        />
      }
    })
  }

  render () {
    return <div className='o-layout'>
      <Form {...this.props} data-testid='payrollInstanceTask-form'>
        <div className='o-layout__item u-1/1'>
          <PayslipTable
            wrapperClassName='u-1/1'
            tableElementClassName='u-margin-bottom-large table--layout-auto'
            data={this.renderPopupRows()}
            headings={[
              {
                Header: 'Action',
                disableFilters: true,
                disableSortBy: true,
                accessor: 'action',
              },
              {
                Header: 'Action owner',
                disableFilters: true,
                disableSortBy: true,
                setWidth: '160px',
                classNames: 'white-space--unset',
                accessor: 'actionOwner',
              },
              {
                Header: 'Due Date',
                disableFilters: true,
                disableSortBy: true,
                accessor: 'dueDate',
              },
              {
                Header: 'Status',
                disableFilters: true,
                disableSortBy: true,
                accessor: 'status',
                columnClassName: 'u-overflow-visible'
              }
            ]}
          />
        </div>
      </Form>
    </div>
  }
}

PayrollInstanceTaskList.propTypes = {
  openFilesModal: PropTypes.func,
  onMultipleTaskClick: PropTypes.func.isRequired,
  alphabeticName: PropTypes.string,
  tasks: PropTypes.array,
  isPayrollInstanceFrozen: PropTypes.bool
}

export default PayrollInstanceTaskList
