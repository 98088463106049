export const toasts = {
  thePrefix: 'The',
  errorPrefix: 'There was an error generating the',
  errorImportPrefix: 'There was an error importing the',
  preparingPrefix: 'We are preparing the',
  yourPrefix: 'Your',
  payrunFile: {
    fileName: 'payrun file',
    ready: 'payrun file is ready',
    uploaded: 'payrun file was successfully uploaded. Please refresh to see changes.',
  },
  reconciliation: {
    fileName: 'reconciliation file',
    ready: 'reconciliation file is ready',
  },
  elementReconciliation: {
    fileName: 'Element reconciliation',
    alternateFileName: 'Employee reconciliation',
    ready: 'Element reconciliation file is ready.',
    alternateReady: 'Employee reconciliation file is ready.',
  },
  reports: {
    inProgress: 'report is in progress',
    ready: 'is ready and available in Recent Reports',
    report: 'report',
  },
}
