import React from 'react'
import Logo from '../Logo'
import MainNav from '../MainNav'
import TenantSelectorContainer from '../../containers/TenantSelectorContainer'
import PropTypes from 'prop-types'
import { MultiArrowTransitioendIcon } from 'components/icons/multiArrowIcon'
import { asideGlobalRoutes } from './routes'

const Aside = (props) => {
  const { history, selectAside, asideMode } = props
  return (
    <aside className='o-aside'>
      <Logo />
      <TenantSelectorContainer history={history} />

      <MultiArrowTransitioendIcon className='o-block' handleClick={() => selectAside(!asideMode)} />

      <MainNav
        items={[
          { title: 'Home', name: 'home', path: '/home', isHome: true },
          { title: 'Countries', name: 'payroll', path: '/countries' },
          { title: 'Global Terms', name: 'payroll', path: '/terms' },
          { title: 'Global Term Subcategories', name: 'payroll', path: '/term-subcategories' },
          { title: 'Clients', name: 'payroll', path: '/clients' },
          {
            title: 'Global Services',
            name: 'payroll',
            path: '/services',
            children: [
              { title: 'Processes', name: 'processes', path: '/processes', sub: true },
              { title: 'Steps', name: 'steps', path: '/steps', sub: true },
              { title: 'Actions', name: 'actions', path: '/actions', sub: true },
            ],
          },
          { title: 'Vendors', name: 'payroll', path: '/vendors' },
          { title: 'COT Users', name: 'payroll', path: '/users' },
          { title: 'Global Data Protection', name: 'payroll', path: '/global-data-protection' },
          asideGlobalRoutes.resources,
          { title: 'Alerts', name: 'payroll', path: '/alerts' },
        ]}
        {...props}
      />
    </aside>
  )
}

Aside.propTypes = {
  selectAside: PropTypes.func,
  asideMode: PropTypes.bool,
  isFetching: PropTypes.bool,
  parentCompanies: PropTypes.array,
  dispatch: PropTypes.func,
  history: PropTypes.object,
}

export default Aside
