import { reportFeCategoryIdLabels } from 'utils/locales/report.en'
import { graphTypesEnum, totalsCalculations } from '../utils/enums/analyticsEnums'
import employeeStatusEnum from '../utils/enums/employmentStatuses'
import { chartLabels, chartSectionHeadings } from '../utils/locales/charts.en'

const ANALYTICS_REVERSIONS_CONFIG = {
  SIDE_MULTI_SELECTOR_BARCHART: {
    chartId: 'reversionsChart',
    defaultAxis: {
      xAxis: 'country',
      yAxis: 'noOfReversions',
    },
    selectors: [
      {
        id: 'xAxis',
        title: chartSectionHeadings.numberOfReversions,
        optionsKey: 'SIDE_BAR_FILTER_OPTIONS',
      },
      {
        id: 'yAxis',
        title: chartSectionHeadings.reversionsType,
        optionsKey: 'SIDE_BAR_REVERSIONS_OPTIONS',
        className: 'u-min-width-250',
      },
    ],
  },
  SIDE_BAR_REVERSIONS_OPTIONS: [
    { value: 'noOfReversions', label: chartLabels.noOfReversions },
    { value: 'newRunRequestedCompany', label: chartLabels.newRunRequestedCompany },
    { value: 'newRunRequestedPayrollPartner', label: chartLabels.newRunRequestedPayrollPartner },
    { value: 'newRunRequestedOther', label: chartLabels.newRunRequestedOther },
    { value: 'clarificationRequired', label: chartLabels.clarificationRequired },
  ],
}
const ANALYTICS_WORKFLOW_CONFIG = {
  CHART_LOGIC: {
    IS_WORKFLOW_TOTALS_COUNT: true,
    TOTALS_KEY_VALUE: 'Totals',
    TITLE: chartSectionHeadings.numberOfGtnVersions,
    TILE_CALC_KEYS: {
      ON_TIME_COMPANY_KEY: 'onTimeActionsCompanyPercentage',
      ON_TIME_PAYROLL_PARTNER_KEY: 'onTimeActionsPayrollPartnerPercentage',
      ON_TIME_GTN_VERSIONS_KEY: 'noOfGtns',
    },
    SIDE_BARCHART_AXIS: { chartId: 'gtnChart', xAxis: 'country', yAxis: 'noOfGtns' },
    ...ANALYTICS_REVERSIONS_CONFIG,
    SIDE_BAR_FILTER_OPTIONS: [
      { value: 'country', label: chartLabels.country, default: true },
      { value: 'company', label: chartLabels.company },
      { value: 'payroll', label: chartLabels.payroll },
    ],
    NOT_ASSIGNED_TEXT: chartSectionHeadings.notAssigned,
  },
  REPORT_CHARTS: {
    AVG_ON_TIME_COMPLETION_COMPANY: graphTypesEnum.TILE_COUNT,
    AVG_ON_TIME_COMPLETION_PAYROLL_PROVIDER: graphTypesEnum.TILE_COUNT,
    AVG_ON_TIME_COMPLETION_GTN_VERSIONS: graphTypesEnum.TILE_COUNT,
    NUMBER_OF_GTN_VERSIONS: graphTypesEnum.SIDE_BARCHART,
    NUMBER_OF_REVERSIONS: graphTypesEnum.SIDE_BARCHART,
  },
}

const reportOptionsConfig = {
  GLOBAL_WORK_HEADCOUNT: {
    LABEL: 'Global Workforce Headcount',
    DESCRIPTION: 'Global workforce headcount across all countries and companies',
    VALUE: 1,
    CHART_LOGIC: {
      TOTALS_KEY: 'country',
      TOTALS_KEY_VALUE: 'Totals Both',
      TOTALS_CALCULATION: totalsCalculations.FLAT,
      ESS_ACTIVE_KEY: 'Totals ESS Turned On Yes',
      ESS_INACTIVE_KEY: 'Totals ESS Turned On No',
      SIDE_BARCHART_EXCLUSIONS: {
        individualRecords: {
          field: 'country',
          remapField: 'Country',
          whiteList: ['totals'],
          blackList: ['ess turned', 'both'],
          fieldFormatter: { exclude: 'Totals', replace: '' },
        },
      },
      SIDE_BARCHART_AXIS: { xAxis: 'Country', yAxis: 'total' },
    },
    REPORT_CHARTS: {
      GLOBAL_WORKFORCE_HEADCOUNT: graphTypesEnum.TILE_COUNT,
      HEADCOUNT_BY_COUNTRY: graphTypesEnum.SIDE_BARCHART,
      EMPLOYEE_SELF_SERVICE: graphTypesEnum.PIE_CHART,
    },
  },
  GLOBAL_JOINERS_HEADCOUNT: {
    LABEL: 'Global Joiners Headcount',
    DESCRIPTION: 'Global joiners headcount across all countries and companies',
    VALUE: 2,
    CHART_LOGIC: {
      TOTALS_KEY: 'country',
      TOTALS_KEY_VALUE: 'Totals Both',
      TOTALS_CALCULATION: totalsCalculations.FLAT,
      ESS_ACTIVE_KEY: 'Totals ESS Turned On Yes',
      ESS_INACTIVE_KEY: 'Totals ESS Turned On No',
      SIDE_BARCHART_EXCLUSIONS: {
        individualRecords: {
          field: 'country',
          remapField: 'Country',
          whiteList: ['totals'],
          blackList: ['ess turned', 'both'],
          fieldFormatter: { exclude: 'Totals', replace: '' },
        },
      },
      SIDE_BARCHART_AXIS: { xAxis: 'Country', yAxis: 'total' },
    },
    REPORT_CHARTS: {
      GLOBAL_JOINERS_HEADCOUNT: graphTypesEnum.TILE_COUNT,
      HEADCOUNT_BY_COUNTRY: graphTypesEnum.SIDE_BARCHART,
      EMPLOYEE_SELF_SERVICE: graphTypesEnum.PIE_CHART,
    },
  },
  GLOBAL_LEAVERS_HEADCOUNT: {
    LABEL: 'Global Leavers Headcount',
    DESCRIPTION: 'Global leavers headcount across all countries and companies',
    VALUE: 3,
    CHART_LOGIC: {
      TOTALS_KEY: 'country',
      TOTALS_KEY_VALUE: 'Totals Both',
      TOTALS_CALCULATION: totalsCalculations.FLAT,
      ESS_ACTIVE_KEY: 'Totals ESS Turned On Yes',
      ESS_INACTIVE_KEY: 'Totals ESS Turned On No',
      SIDE_BARCHART_EXCLUSIONS: {
        individualRecords: {
          field: 'country',
          remapField: 'Country',
          whiteList: ['totals'],
          blackList: ['ess turned', 'both'],
          fieldFormatter: { exclude: 'Totals', replace: '' },
        },
      },
      SIDE_BARCHART_AXIS: { xAxis: 'Country', yAxis: 'total' },
    },
    REPORT_CHARTS: {
      GLOBAL_LEAVERS_HEADCOUNT: graphTypesEnum.TILE_COUNT,
      HEADCOUNT_BY_COUNTRY: graphTypesEnum.SIDE_BARCHART,
      EMPLOYEE_SELF_SERVICE: graphTypesEnum.PIE_CHART,
    },
  },
  WORKFORCE_HEADCOUNT: {
    LABEL: 'Workforce Headcount',
    DESCRIPTION: 'Workforce headcount for any selected country and company',
    VALUE: 4,
    CHART_LOGIC: {
      TOTALS_KEY: 'country',
      TOTALS_KEY_VALUE: 'Totals Both',
      TOTALS_CALCULATION: totalsCalculations.FLAT,
      ESS_ACTIVE_KEY: 'Totals ESS Turned On Yes',
      ESS_INACTIVE_KEY: 'Totals ESS Turned On No',
      SIDE_BARCHART_EXCLUSIONS: {
        individualRecords: {
          field: 'country',
          remapField: 'Country',
          whiteList: ['totals'],
          blackList: ['ess turned', 'both'],
          fieldFormatter: { exclude: 'Totals', replace: '' },
        },
      },
      SIDE_BARCHART_AXIS: { xAxis: 'Country', yAxis: 'total' },
    },
    REPORT_CHARTS: {
      GLOBAL_WORKFORCE_HEADCOUNT: graphTypesEnum.TILE_COUNT,
      HEADCOUNT_BY_COUNTRY: graphTypesEnum.SIDE_BARCHART,
      EMPLOYEE_SELF_SERVICE: graphTypesEnum.PIE_CHART,
    },
  },
  JOINERS_HEADCOUNT: {
    LABEL: 'Joiners Headcount',
    DESCRIPTION: 'Joiners headcount for any selected country and company',
    VALUE: 5,
    CHART_LOGIC: {
      TOTALS_KEY: 'country',
      TOTALS_KEY_VALUE: 'Totals Both',
      TOTALS_CALCULATION: totalsCalculations.FLAT,
      ESS_ACTIVE_KEY: 'Totals ESS Turned On Yes',
      ESS_INACTIVE_KEY: 'Totals ESS Turned On No',
      SIDE_BARCHART_EXCLUSIONS: {
        individualRecords: {
          field: 'country',
          remapField: 'Country',
          whiteList: ['totals'],
          blackList: ['ess turned', 'both'],
          fieldFormatter: { exclude: 'Totals', replace: '' },
        },
      },
      SIDE_BARCHART_AXIS: { xAxis: 'Country', yAxis: 'total' },
    },
    REPORT_CHARTS: {
      GLOBAL_JOINERS_HEADCOUNT: graphTypesEnum.TILE_COUNT,
      HEADCOUNT_BY_COUNTRY: graphTypesEnum.SIDE_BARCHART,
      EMPLOYEE_SELF_SERVICE: graphTypesEnum.PIE_CHART,
    },
  },
  LEAVERS_HEADCOUNT: {
    LABEL: 'Leavers Headcount',
    DESCRIPTION: 'Leavers headcount for any selected country and company',
    VALUE: 6,
    CHART_LOGIC: {
      TOTALS_KEY: 'country',
      TOTALS_KEY_VALUE: 'Totals Both',
      TOTALS_CALCULATION: totalsCalculations.FLAT,
      ESS_ACTIVE_KEY: 'Totals ESS Turned On Yes',
      ESS_INACTIVE_KEY: 'Totals ESS Turned On No',
      SIDE_BARCHART_EXCLUSIONS: {
        individualRecords: {
          field: 'country',
          remapField: 'Country',
          whiteList: ['totals'],
          blackList: ['ess turned', 'both'],
          fieldFormatter: { exclude: 'Totals', replace: '' },
        },
      },
      SIDE_BARCHART_AXIS: { xAxis: 'Country', yAxis: 'total' },
    },
    REPORT_CHARTS: {
      GLOBAL_LEAVERS_HEADCOUNT: graphTypesEnum.TILE_COUNT,
      HEADCOUNT_BY_COUNTRY: graphTypesEnum.SIDE_BARCHART,
      EMPLOYEE_SELF_SERVICE: graphTypesEnum.PIE_CHART,
    },
  },
  WORKFORCE_DETAIL: {
    LABEL: 'Workforce Detail',
    DESCRIPTION: 'Detailed list of your workforce Job & Org data',
    VALUE: 7,
    CHART_LOGIC: {
      TOTALS_KEY: 'country',
      OTHER_SHEETS_TOTALS: true,
      TOTALS_KEY_VALUE: 'Totals Both',
      TOTALS_CALCULATION: totalsCalculations.CALCULATED,
      EMPLOYEMENT_STATUS: 'employementStatus',
      ESS_ACTIVE_KEY: 'Totals ESS Turned On Yes',
      ESS_INACTIVE_KEY: 'Totals ESS Turned On No',
      SIDE_BARCHART_EXCLUSIONS: {
        individualRecords: {
          field: 'country',
          remapField: 'Country',
          whiteList: ['totals'],
          blackList: ['ess turned', 'both'],
          fieldFormatter: { exclude: 'Totals', replace: '' },
        },
      },
      SIDE_BAR_FILTER_OPTIONS: [
        { value: 'country', label: 'Country', default: true },
        { value: 'company', label: 'Company' },
        { value: 'payroll', label: 'Payroll' },
        { value: 'businessUnit', label: 'Business Unit' },
        { value: 'costCenter', label: 'Cost Center' },
        { value: 'department', label: 'Department' },
        { value: 'officeLocation', label: 'Office Location' },
        { value: 'project', label: 'Project' },
        { value: 'subsidiary', label: 'Subsidiary' },
      ],
      SIDE_BARCHART_AXIS: { xAxis: 'Country', yAxis: 'total' },
      CHART_DATAKEYS_COLORS: {
        [employeeStatusEnum.LOCAL.VALUE]: '#5292dd',
        [employeeStatusEnum.EXPAT.VALUE]: '#2268b4',
        [employeeStatusEnum.CONTRACTOR.VALUE]: '#88c8b4',
        [employeeStatusEnum.EMPLOYER_OF_RECORD.VALUE]: '#e293a7',
        [employeeStatusEnum.SHORT_TERM_ASSIGNMENT.VALUE]: '#91d4e8',
        [employeeStatusEnum.SHORT_TERM_ASSIGNMENT_HOST.VALUE]: '#ffca0a',
        [employeeStatusEnum.SUPPLIER.VALUE]: '#7ab059',
        [employeeStatusEnum.NON_EMPLOYEE.VALUE]: '#462b65',
        'Not Assigned': '#f6ab66',
      },
      CHART_DATAKEYS_CSS_NAMES: {
        [employeeStatusEnum.LOCAL.VALUE]: 'local',
        [employeeStatusEnum.EXPAT.VALUE]: 'expat',
        [employeeStatusEnum.CONTRACTOR.VALUE]: 'contractor',
        [employeeStatusEnum.EMPLOYER_OF_RECORD.VALUE]: 'employer-of-record',
        [employeeStatusEnum.SHORT_TERM_ASSIGNMENT.VALUE]: 'short-term-assignment',
        [employeeStatusEnum.SHORT_TERM_ASSIGNMENT_HOST.VALUE]: 'short-term-assignment-host',
        [employeeStatusEnum.SUPPLIER.VALUE]: 'supplier',
        [employeeStatusEnum.NON_EMPLOYEE.VALUE]: 'non-employee',
        'Not Assigned': 'not-assigned',
      },
      LINE_CHART_DATAKEYS: {
        [employeeStatusEnum.LOCAL.VALUE]: [employeeStatusEnum.LOCAL.VALUE],
        [employeeStatusEnum.EXPAT.VALUE]: [employeeStatusEnum.EXPAT.VALUE],
        [employeeStatusEnum.CONTRACTOR.VALUE]: [
          employeeStatusEnum.CONTRACTOR.VALUE,
          employeeStatusEnum.CONTRACTOR_INDIVIDUAL.VALUE,
          employeeStatusEnum.CONTRACTOR_COMPANY.VALUE,
          employeeStatusEnum.CONTRACTOR_AGENCY.VALUE,
        ],
        [employeeStatusEnum.EMPLOYER_OF_RECORD.VALUE]: [employeeStatusEnum.EMPLOYER_OF_RECORD.VALUE],
        [employeeStatusEnum.SHORT_TERM_ASSIGNMENT.VALUE]: [employeeStatusEnum.SHORT_TERM_ASSIGNMENT.VALUE],
        [employeeStatusEnum.SHORT_TERM_ASSIGNMENT_HOST.VALUE]: [employeeStatusEnum.SHORT_TERM_ASSIGNMENT_HOST.VALUE],
        [employeeStatusEnum.SUPPLIER.VALUE]: [employeeStatusEnum.SUPPLIER.VALUE],
        [employeeStatusEnum.NON_EMPLOYEE.VALUE]: [employeeStatusEnum.NON_EMPLOYEE.VALUE],
        'Not Assigned': ['Not Assigned'],
      },
    },
    REPORT_CHARTS: {
      GLOBAL_WORKFORCE_HEADCOUNT: graphTypesEnum.TILE_COUNT,
      GLOBAL_JOINERS_HEADCOUNT: graphTypesEnum.TILE_COUNT,
      GLOBAL_LEAVERS_HEADCOUNT: graphTypesEnum.TILE_COUNT,
      HEADCOUNT_BY_COUNTRY: graphTypesEnum.SIDE_BARCHART,
      HEADCOUNT_BY_MONTH: graphTypesEnum.LINE_CHART,
      EMPLOYEE_SELF_SERVICE: graphTypesEnum.PIE_CHART,
      HEADCOUNT_BY_WORKER_TYPE: graphTypesEnum.PIE_CHART,
    },
  },
  JOINERS_DETAIL: {
    LABEL: 'Joiners Detail',
    DESCRIPTION: 'Detailed list of your joiners Job & Org data',
    VALUE: 8,
    CHART_LOGIC: {
      TOTALS_KEY: 'country',
      OTHER_SHEETS_TOTALS: true,
      TOTALS_KEY_VALUE: 'Totals Both',
      TOTALS_CALCULATION: totalsCalculations.FLAT,
      EMPLOYEMENT_STATUS: 'employementStatus',
      ESS_ACTIVE_KEY: 'Totals ESS Turned On Yes',
      ESS_INACTIVE_KEY: 'Totals ESS Turned On No',
      DISABLED_REPORTS: ['HEADCOUNT_BY_MONTH'],
      SIDE_BARCHART_EXCLUSIONS: {
        individualRecords: {
          field: 'country',
          remapField: 'Country',
          whiteList: ['totals'],
          blackList: ['ess turned', 'both'],
          fieldFormatter: { exclude: 'Totals', replace: '' },
        },
      },
      SIDE_BAR_FILTER_OPTIONS: [
        { value: 'country', label: 'Country', default: true },
        { value: 'company', label: 'Company' },
        { value: 'payroll', label: 'Payroll' },
        { value: 'businessUnit', label: 'Business Unit' },
        { value: 'costCenter', label: 'Cost Center' },
        { value: 'department', label: 'Department' },
        { value: 'officeLocation', label: 'Office Location' },
        { value: 'project', label: 'Project' },
        { value: 'subsidiary', label: 'Subsidiary' },
      ],
      SIDE_BARCHART_AXIS: { xAxis: 'Country', yAxis: 'total' },
      CHART_DATAKEYS_COLORS: {
        [employeeStatusEnum.LOCAL.VALUE]: '#5292dd',
        [employeeStatusEnum.EXPAT.VALUE]: '#2268b4',
        [employeeStatusEnum.CONTRACTOR.VALUE]: '#88c8b4',
        [employeeStatusEnum.EMPLOYER_OF_RECORD.VALUE]: '#e293a7',
        [employeeStatusEnum.SHORT_TERM_ASSIGNMENT.VALUE]: '#91d4e8',
        [employeeStatusEnum.SHORT_TERM_ASSIGNMENT_HOST.VALUE]: '#ffca0a',
        [employeeStatusEnum.SUPPLIER.VALUE]: '#7ab059',
        [employeeStatusEnum.NON_EMPLOYEE.VALUE]: '#462b65',
        'Not Assigned': '#f6ab66',
      },
      CHART_DATAKEYS_CSS_NAMES: {
        [employeeStatusEnum.LOCAL.VALUE]: 'local',
        [employeeStatusEnum.EXPAT.VALUE]: 'expat',
        [employeeStatusEnum.CONTRACTOR.VALUE]: 'contractor',
        [employeeStatusEnum.EMPLOYER_OF_RECORD.VALUE]: 'employer-of-record',
        [employeeStatusEnum.SHORT_TERM_ASSIGNMENT.VALUE]: 'short-term-assignment',
        [employeeStatusEnum.SHORT_TERM_ASSIGNMENT_HOST.VALUE]: 'short-term-assignment-host',
        [employeeStatusEnum.SUPPLIER.VALUE]: 'supplier',
        [employeeStatusEnum.NON_EMPLOYEE.VALUE]: 'non-employee',
        'Not Assigned': 'not-assigned',
      },
      LINE_CHART_DATAKEYS: {
        [employeeStatusEnum.LOCAL.VALUE]: [employeeStatusEnum.LOCAL.VALUE],
        [employeeStatusEnum.EXPAT.VALUE]: [employeeStatusEnum.EXPAT.VALUE],
        [employeeStatusEnum.CONTRACTOR.VALUE]: [
          employeeStatusEnum.CONTRACTOR.VALUE,
          employeeStatusEnum.CONTRACTOR_INDIVIDUAL.VALUE,
          employeeStatusEnum.CONTRACTOR_COMPANY.VALUE,
          employeeStatusEnum.CONTRACTOR_AGENCY.VALUE,
        ],
        [employeeStatusEnum.EMPLOYER_OF_RECORD.VALUE]: [employeeStatusEnum.EMPLOYER_OF_RECORD.VALUE],
        [employeeStatusEnum.SHORT_TERM_ASSIGNMENT.VALUE]: [employeeStatusEnum.SHORT_TERM_ASSIGNMENT.VALUE],
        [employeeStatusEnum.SHORT_TERM_ASSIGNMENT_HOST.VALUE]: [employeeStatusEnum.SHORT_TERM_ASSIGNMENT_HOST.VALUE],
        [employeeStatusEnum.SUPPLIER.VALUE]: [employeeStatusEnum.SUPPLIER.VALUE],
        [employeeStatusEnum.NON_EMPLOYEE.VALUE]: [employeeStatusEnum.NON_EMPLOYEE.VALUE],
        'Not Assigned': ['Not Assigned'],
      },
    },
    REPORT_CHARTS: {
      GLOBAL_JOINERS_HEADCOUNT: graphTypesEnum.TILE_COUNT,
      HEADCOUNT_BY_COUNTRY: graphTypesEnum.SIDE_BARCHART,
      HEADCOUNT_BY_MONTH: graphTypesEnum.LINE_CHART,
      EMPLOYEE_SELF_SERVICE: graphTypesEnum.PIE_CHART,
      HEADCOUNT_BY_WORKER_TYPE: graphTypesEnum.PIE_CHART,
    },
  },
  LEAVERS_DETAIL: {
    LABEL: 'Leavers Detail',
    DESCRIPTION: 'Detailed list of your leavers Job & Org data',
    VALUE: 9,
    CHART_LOGIC: {
      TOTALS_KEY: 'country',
      OTHER_SHEETS_TOTALS: true,
      TOTALS_KEY_VALUE: 'Totals Both',
      TOTALS_CALCULATION: totalsCalculations.FLAT,
      EMPLOYEMENT_STATUS: 'employementStatus',
      ESS_ACTIVE_KEY: 'Totals ESS Turned On Yes',
      ESS_INACTIVE_KEY: 'Totals ESS Turned On No',
      DISABLED_REPORTS: ['HEADCOUNT_BY_MONTH'],
      SIDE_BARCHART_EXCLUSIONS: {
        individualRecords: {
          field: 'country',
          remapField: 'Country',
          whiteList: ['totals'],
          blackList: ['ess turned', 'both'],
          fieldFormatter: { exclude: 'Totals', replace: '' },
        },
      },
      SIDE_BAR_FILTER_OPTIONS: [
        { value: 'country', label: 'Country', default: true },
        { value: 'company', label: 'Company' },
        { value: 'payroll', label: 'Payroll' },
        { value: 'businessUnit', label: 'Business Unit' },
        { value: 'costCenter', label: 'Cost Center' },
        { value: 'department', label: 'Department' },
        { value: 'officeLocation', label: 'Office Location' },
        { value: 'project', label: 'Project' },
        { value: 'subsidiary', label: 'Subsidiary' },
      ],
      SIDE_BARCHART_AXIS: { xAxis: 'Country', yAxis: 'total' },
      CHART_DATAKEYS_COLORS: {
        [employeeStatusEnum.LOCAL.VALUE]: '#5292dd',
        [employeeStatusEnum.EXPAT.VALUE]: '#2268b4',
        [employeeStatusEnum.CONTRACTOR.VALUE]: '#88c8b4',
        [employeeStatusEnum.EMPLOYER_OF_RECORD.VALUE]: '#e293a7',
        [employeeStatusEnum.SHORT_TERM_ASSIGNMENT.VALUE]: '#91d4e8',
        [employeeStatusEnum.SHORT_TERM_ASSIGNMENT_HOST.VALUE]: '#ffca0a',
        [employeeStatusEnum.SUPPLIER.VALUE]: '#7ab059',
        [employeeStatusEnum.NON_EMPLOYEE.VALUE]: '#462b65',
        'Not Assigned': '#f6ab66',
      },
      CHART_DATAKEYS_CSS_NAMES: {
        [employeeStatusEnum.LOCAL.VALUE]: 'local',
        [employeeStatusEnum.EXPAT.VALUE]: 'expat',
        [employeeStatusEnum.CONTRACTOR.VALUE]: 'contractor',
        [employeeStatusEnum.EMPLOYER_OF_RECORD.VALUE]: 'employer-of-record',
        [employeeStatusEnum.SHORT_TERM_ASSIGNMENT.VALUE]: 'short-term-assignment',
        [employeeStatusEnum.SHORT_TERM_ASSIGNMENT_HOST.VALUE]: 'short-term-assignment-host',
        [employeeStatusEnum.SUPPLIER.VALUE]: 'supplier',
        [employeeStatusEnum.NON_EMPLOYEE.VALUE]: 'non-employee',
        'Not Assigned': 'not-assigned',
      },
      LINE_CHART_DATAKEYS: {
        [employeeStatusEnum.LOCAL.VALUE]: [employeeStatusEnum.LOCAL.VALUE],
        [employeeStatusEnum.EXPAT.VALUE]: [employeeStatusEnum.EXPAT.VALUE],
        [employeeStatusEnum.CONTRACTOR.VALUE]: [
          employeeStatusEnum.CONTRACTOR.VALUE,
          employeeStatusEnum.CONTRACTOR_INDIVIDUAL.VALUE,
          employeeStatusEnum.CONTRACTOR_COMPANY.VALUE,
          employeeStatusEnum.CONTRACTOR_AGENCY.VALUE,
        ],
        [employeeStatusEnum.EMPLOYER_OF_RECORD.VALUE]: [employeeStatusEnum.EMPLOYER_OF_RECORD.VALUE],
        [employeeStatusEnum.SHORT_TERM_ASSIGNMENT.VALUE]: [employeeStatusEnum.SHORT_TERM_ASSIGNMENT.VALUE],
        [employeeStatusEnum.SHORT_TERM_ASSIGNMENT_HOST.VALUE]: [employeeStatusEnum.SHORT_TERM_ASSIGNMENT_HOST.VALUE],
        [employeeStatusEnum.SUPPLIER.VALUE]: [employeeStatusEnum.SUPPLIER.VALUE],
        [employeeStatusEnum.NON_EMPLOYEE.VALUE]: [employeeStatusEnum.NON_EMPLOYEE.VALUE],
        'Not Assigned': ['Not Assigned'],
      },
    },
    REPORT_CHARTS: {
      GLOBAL_LEAVERS_HEADCOUNT: graphTypesEnum.TILE_COUNT,
      HEADCOUNT_BY_COUNTRY: graphTypesEnum.SIDE_BARCHART,
      HEADCOUNT_BY_MONTH: graphTypesEnum.LINE_CHART,
      EMPLOYEE_SELF_SERVICE: graphTypesEnum.PIE_CHART,
      HEADCOUNT_BY_WORKER_TYPE: graphTypesEnum.PIE_CHART,
    },
  },
  EMPLOYEE_SELF_SERVE_ACCESS_STATUS_REPORT: {
    LABEL: 'ESS Status Update',
    DESCRIPTION: 'Detailed status report listing users with and without access to Employee Self-Serve Portal',
    VALUE: 10,
    CHART_LOGIC: {
      TOTALS_KEY: 'country',
      OTHER_SHEETS_TOTALS: true,
      TOTALS_KEY_VALUE: 'Totals Both',
      TOTALS_CALCULATION: totalsCalculations.CALCULATED,
      ESS_ACTIVE_KEY: 'Totals ESS Turned On Yes',
      ESS_INACTIVE_KEY: 'Totals ESS Turned On No',
      SIDE_BARCHART_AXIS: { xAxis: 'Country', yAxis: 'total' },
      SIDE_BARCHART_EXCLUSIONS: {
        individualRecords: {
          field: 'country',
          remapField: 'Country',
          whiteList: ['totals'],
          blackList: ['ess turned', 'both', 'grand totals'],
          fieldFormatter: { exclude: 'Totals', replace: '' },
        },
      },
    },
    REPORT_CHARTS: {
      GLOBAL_WORKFORCE_HEADCOUNT: graphTypesEnum.TILE_COUNT,
      HEADCOUNT_BY_COUNTRY: graphTypesEnum.SIDE_BARCHART,
      EMPLOYEE_SELF_SERVICE: graphTypesEnum.PIE_CHART,
    },
  },
  GLOBAL_WORKFLOW_AUDIT: {
    LABEL: 'Global Workflow Audit',
    DESCRIPTION: 'Company and payroll provider’s performance measured against agreed workflow deadlines consolidated across all countries and payrolls',
    VALUE: 11,
    ...ANALYTICS_WORKFLOW_CONFIG,
  },
  WORKFLOW_AUDIT: {
    LABEL: 'Workflow Audit',
    DESCRIPTION: 'Company and payroll provider’s performance measured against agreed workflow deadlines for any selected country and payroll',
    VALUE: 12,
    ...ANALYTICS_WORKFLOW_CONFIG,
  },
  PAYROLL_CHANGES_REPORT: {
    LABEL: 'Workforce Payroll Changes',
    DESCRIPTION: "Compare your worker's payroll data across multiple pay periods",
    VALUE: 13,
  },
  VARIANCE_REPORT: {
    LABEL: 'Payrun Variance',
    DESCRIPTION: 'Compare earning & deduction changes across multiple pay periods and review variances by value or percentage',
    VALUE: 14,
  },
  EMPLOYEE_SELF_SERVE_NO_ACCESS_REPORT: {
    LABEL: 'ESS No Access',
    DESCRIPTION: 'Detailed status report listing users without access to Employee Self-Serve Portal',
    VALUE: 15,
    CHART_LOGIC: {
      TOTALS_KEY: 'country',
      OTHER_SHEETS_TOTALS: true,
      TOTALS_KEY_VALUE: 'Grand Totals',
      TOTALS_CALCULATION: totalsCalculations.CALCULATED,
      ESS_ACTIVE_KEY: 'Totals ESS Turned On Yes',
      ESS_INACTIVE_KEY: 'Totals ESS Turned On No',
      SIDE_BARCHART_AXIS: { xAxis: 'Country', yAxis: 'total' },
      SIDE_BARCHART_EXCLUSIONS: {
        individualRecords: {
          field: 'country',
          remapField: 'Country',
          whiteList: [],
          blackList: ['ess turned', 'both', 'grand totals'],
          fieldFormatter: { exclude: 'Totals', replace: '' },
        },
      },
    },
    REPORT_CHARTS: {
      GLOBAL_WORKFORCE_HEADCOUNT: graphTypesEnum.TILE_COUNT,
      HEADCOUNT_BY_COUNTRY: graphTypesEnum.SIDE_BARCHART,
    },
  },
  EMPLOYEE_SELF_SERVE_ACCESS_REPORT: {
    LABEL: 'ESS With Access',
    DESCRIPTION: 'Detailed status report listing users with access to Employee Self-Serve Portal',
    VALUE: 16,
    CHART_LOGIC: {
      TOTALS_KEY: 'country',
      OTHER_SHEETS_TOTALS: true,
      TOTALS_KEY_VALUE: 'Grand Totals',
      TOTALS_CALCULATION: totalsCalculations.CALCULATED,
      ESS_ACTIVE_KEY: 'Totals ESS Turned On Yes',
      ESS_INACTIVE_KEY: 'Totals ESS Turned On No',
      SIDE_BARCHART_AXIS: { xAxis: 'Country', yAxis: 'total' },
      SIDE_BARCHART_EXCLUSIONS: {
        individualRecords: {
          field: 'country',
          remapField: 'Country',
          whiteList: [],
          blackList: ['ess turned', 'both', 'grand totals'],
          fieldFormatter: { exclude: 'Totals', replace: '' },
        },
      },
    },
    REPORT_CHARTS: {
      GLOBAL_WORKFORCE_HEADCOUNT: graphTypesEnum.TILE_COUNT,
      HEADCOUNT_BY_COUNTRY: graphTypesEnum.SIDE_BARCHART,
    },
  },
  GLOBAL_TOTAL_EMPLOYER_COSTS: {
    LABEL: 'Global Total Employer Costs',
    DESCRIPTION: 'Consolidated total employer costs across all countries and payrolls',
    VALUE: 17,
    CHART_LOGIC: {
      IS_FINANCE_TILE: true,
      TOTALS_KEY: 'currency',
      TOTALS_KEY_VALUE: 'Grand Totals',
      TOTALS_REPORT_KEY: 'totalEmployerCost',
      TOTALS_CALCULATION: totalsCalculations.CALCULATED,
      SIDE_BARCHART_AXIS: { xAxis: 'Country', yAxis: 'totalEmployerCost' },
      SIDE_BARCHART_HEADER: 'Employer',
      SIDE_BARCHART_EXCLUSIONS: {
        individualRecords: {
          field: 'country',
          remapField: 'Country',
          whiteList: [],
          blackList: [],
          fieldFormatter: { exclude: 'Totals', replace: '' },
        },
      },
      SIDE_BAR_FILTER_OPTIONS: [
        { value: 'country', label: 'Country', default: true },
        { value: 'company', label: 'Company' },
        { value: 'payroll', label: 'Payroll' },
      ],
    },
    REPORT_CHARTS: {
      TOTAL_EMPLOYER_COSTS: graphTypesEnum.TILE_COUNT,
      GLOBAL_COSTS: graphTypesEnum.SIDE_BARCHART,
    },
  },
  TOTAL_EMPLOYER_COSTS: {
    LABEL: 'Total Employer Costs',
    DESCRIPTION: 'Total employer costs for any selected country and payroll',
    VALUE: 18,
    CHART_LOGIC: {
      IS_FINANCE_TILE: true,
      TOTALS_KEY: 'currency',
      TOTALS_KEY_VALUE: 'Grand Totals',
      TOTALS_REPORT_KEY: 'totalEmployerCost',
      TOTALS_CALCULATION: totalsCalculations.FLAT,
      SIDE_BARCHART_AXIS: { xAxis: 'Country', yAxis: 'totalEmployerCost' },
      SIDE_BARCHART_HEADER: 'Employer',
      SIDE_BARCHART_EXCLUSIONS: {
        individualRecords: {
          field: 'country',
          remapField: 'Country',
          whiteList: [],
          blackList: [],
          fieldFormatter: { exclude: 'Totals', replace: '' },
        },
      },
      SIDE_BAR_FILTER_OPTIONS: [
        { value: 'country', label: 'Country', default: true },
        { value: 'company', label: 'Company' },
        { value: 'payroll', label: 'Payroll' },
      ],
    },
    REPORT_CHARTS: {
      TOTAL_EMPLOYER_COSTS: graphTypesEnum.TILE_COUNT,
      GLOBAL_COSTS: graphTypesEnum.SIDE_BARCHART,
    },
  },
  EMPLOYER_COSTS_DETAIL: {
    LABEL: 'Employer Costs Detail',
    DESCRIPTION: 'Detailed breakdown of total employer costs per element',
    VALUE: 19,
    CHART_LOGIC: {
      IS_FINANCE_TILE: true,
      TOTALS_KEY: 'currency',
      TOTALS_KEY_VALUE: 'Grand Totals',
      TOTALS_REPORT_KEY: 'totalEmployerCost',
      TOTALS_CALCULATION: totalsCalculations.FLAT,
      SIDE_BARCHART_AXIS: { xAxis: 'Country', yAxis: 'totalEmployerCost' },
      SIDE_BARCHART_HEADER: 'Employer',
      SIDE_BARCHART_EXCLUSIONS: {
        individualRecords: {
          field: 'country',
          remapField: 'Country',
          whiteList: [],
          blackList: [],
          fieldFormatter: { exclude: 'Totals', replace: '' },
        },
      },
      SIDE_BAR_FILTER_OPTIONS: [
        { value: 'country', label: 'Country', default: true },
        { value: 'company', label: 'Company' },
        { value: 'payroll', label: 'Payroll' },
      ],
    },
    REPORT_CHARTS: {
      TOTAL_EMPLOYER_COSTS: graphTypesEnum.TILE_COUNT,
      GLOBAL_COSTS: graphTypesEnum.SIDE_BARCHART,
    },
  },
  GLOBAL_PAY_ELEMENT_COSTS: {
    LABEL: 'Global Pay Element Costs',
    DESCRIPTION: 'Consolidated pay element costs across all countries and payrolls',
    VALUE: 20,
    CHART_LOGIC: {
      IS_FINANCE_TILE: true,
      TOTALS_KEY: 'currency',
      TOTALS_KEY_VALUE: 'Grand Totals',
      TOTALS_PAY_ELEMENT_REPORT_KEY: 'Pay elements',
      TOTALS_CALCULATION: totalsCalculations.FLAT,
      SIDE_BARCHART_AXIS: { xAxis: 'Country', yAxis: 'Pay elements' },
      SIDE_BARCHART_HEADER: 'Pay Element',
      SIDE_BARCHART_EXCLUSIONS: {
        individualRecords: {
          field: 'country',
          remapField: 'Country',
          whiteList: [],
          blackList: [],
          fieldFormatter: { exclude: 'Totals', replace: '' },
        },
      },
      SIDE_BAR_FILTER_OPTIONS: [
        { value: 'country', label: 'Country', default: true },
        { value: 'company', label: 'Company' },
        { value: 'payroll', label: 'Payroll' },
      ],
    },
    REPORT_CHARTS: {
      TOTAL_PAY_ELEMENT_COSTS: graphTypesEnum.TILE_COUNT,
      GLOBAL_COSTS: graphTypesEnum.SIDE_BARCHART,
    },
  },
  TOTAL_PAY_ELEMENT_COSTS: {
    LABEL: 'Total Pay Element Costs',
    DESCRIPTION: 'Total pay element costs for any selected country and payroll',
    VALUE: 21,
    CHART_LOGIC: {
      IS_FINANCE_TILE: true,
      TOTALS_KEY: 'currency',
      TOTALS_KEY_VALUE: 'Grand Totals',
      TOTALS_PAY_ELEMENT_REPORT_KEY: 'Pay elements',
      TOTALS_CALCULATION: totalsCalculations.FLAT,
      SIDE_BARCHART_AXIS: { xAxis: 'Country', yAxis: 'Pay elements' },
      SIDE_BARCHART_HEADER: 'Pay Element',
      SIDE_BARCHART_EXCLUSIONS: {
        individualRecords: {
          field: 'country',
          remapField: 'Country',
          whiteList: [],
          blackList: [],
          fieldFormatter: { exclude: 'Totals', replace: '' },
        },
      },
      SIDE_BAR_FILTER_OPTIONS: [
        { value: 'country', label: 'Country', default: true },
        { value: 'company', label: 'Company' },
        { value: 'payroll', label: 'Payroll' },
      ],
    },
    REPORT_CHARTS: {
      TOTAL_PAY_ELEMENT_COSTS: graphTypesEnum.TILE_COUNT,
      GLOBAL_COSTS: graphTypesEnum.SIDE_BARCHART,
    },
  },
  PAY_ELEMENTS_COSTS_DETAIL: {
    LABEL: 'Pay Elements Costs Detail',
    DESCRIPTION: 'Detailed breakdown of total pay element costs',
    VALUE: 22,
    CHART_LOGIC: {
      IS_FINANCE_TILE: true,
      TOTALS_KEY: 'currency',
      TOTALS_KEY_VALUE: 'Grand Totals',
      TOTALS_PAY_ELEMENT_REPORT_KEY: 'totals_numeric',
      TOTALS_CALCULATION: totalsCalculations.FLAT,
      SIDE_BARCHART_AXIS: { xAxis: 'Country', yAxis: 'totals_numeric' },
      SIDE_BARCHART_HEADER: 'Pay Element',
      SIDE_BARCHART_EXCLUSIONS: {
        individualRecords: {
          field: 'country',
          remapField: 'Country',
          whiteList: [],
          blackList: [],
          fieldFormatter: { exclude: 'Totals', replace: '' },
        },
      },
      SIDE_BAR_FILTER_OPTIONS: [
        { value: 'country', label: 'Country', default: true },
        { value: 'company', label: 'Company' },
        { value: 'payroll', label: 'Payroll' },
      ],
    },
    REPORT_CHARTS: {
      TOTAL_PAY_ELEMENT_COSTS: graphTypesEnum.TILE_COUNT,
      GLOBAL_COSTS: graphTypesEnum.SIDE_BARCHART,
    },
  },
  GLOBAL_EMPLOYER_CONTRIBUTION_COSTS: {
    LABEL: 'Global Employer Contribution Costs',
    DESCRIPTION: 'Consolidated employer contribution costs across all countries and payrolls',
    VALUE: 23,
    CHART_LOGIC: {
      IS_FINANCE_TILE: true,
      TOTALS_KEY: 'currency',
      TOTALS_KEY_VALUE: 'Grand Totals',
      TOTALS_ER_CONTRIBUTION_REPORT_KEY: 'Employer Contributions',
      TOTALS_CALCULATION: totalsCalculations.FLAT,
      SIDE_BARCHART_AXIS: { xAxis: 'Country', yAxis: 'Employer Contributions' },
      SIDE_BARCHART_HEADER: 'Employer Contribution',
      SIDE_BARCHART_EXCLUSIONS: {
        individualRecords: {
          field: 'country',
          remapField: 'Country',
          whiteList: [],
          blackList: [],
          fieldFormatter: { exclude: 'Totals', replace: '' },
        },
      },
      SIDE_BAR_FILTER_OPTIONS: [
        { value: 'country', label: 'Country', default: true },
        { value: 'company', label: 'Company' },
        { value: 'payroll', label: 'Payroll' },
      ],
    },
    REPORT_CHARTS: {
      EMPLOYER_CONTRIBUTION_COSTS: graphTypesEnum.TILE_COUNT,
      GLOBAL_COSTS: graphTypesEnum.SIDE_BARCHART,
    },
  },
  TOTAL_EMPLOYER_CONTRIBUTION_COSTS: {
    LABEL: 'Total Employer Contribution Costs',
    DESCRIPTION: 'Total employer contribution costs for any selected country and payroll',
    VALUE: 24,
    CHART_LOGIC: {
      IS_FINANCE_TILE: true,
      TOTALS_KEY: 'currency',
      TOTALS_KEY_VALUE: 'Grand Totals',
      TOTALS_ER_CONTRIBUTION_REPORT_KEY: 'Employer Contributions',
      TOTALS_CALCULATION: totalsCalculations.FLAT,
      SIDE_BARCHART_AXIS: { xAxis: 'Country', yAxis: 'Employer Contributions' },
      SIDE_BARCHART_HEADER: 'Employer Contribution',
      SIDE_BARCHART_EXCLUSIONS: {
        individualRecords: {
          field: 'country',
          remapField: 'Country',
          whiteList: [],
          blackList: [],
          fieldFormatter: { exclude: 'Totals', replace: '' },
        },
      },
      SIDE_BAR_FILTER_OPTIONS: [
        { value: 'country', label: 'Country', default: true },
        { value: 'company', label: 'Company' },
        { value: 'payroll', label: 'Payroll' },
      ],
    },
    REPORT_CHARTS: {
      EMPLOYER_CONTRIBUTION_COSTS: graphTypesEnum.TILE_COUNT,
      GLOBAL_COSTS: graphTypesEnum.SIDE_BARCHART,
    },
  },
  EMPLOYER_CONTRIBUTIONS_COSTS_DETAIL: {
    LABEL: 'Employer Contributions Costs Detail',
    DESCRIPTION: 'Detailed breakdown of total employer contribution costs',
    VALUE: 25,
    CHART_LOGIC: {
      IS_FINANCE_TILE: true,
      TOTALS_KEY: 'currency',
      TOTALS_KEY_VALUE: 'Grand Totals',
      TOTALS_ER_CONTRIBUTION_REPORT_KEY: 'totals_numeric',
      TOTALS_CALCULATION: totalsCalculations.FLAT,
      SIDE_BARCHART_AXIS: { xAxis: 'Country', yAxis: 'totals_numeric' },
      SIDE_BARCHART_HEADER: 'Employer Contribution',
      SIDE_BARCHART_EXCLUSIONS: {
        individualRecords: {
          field: 'country',
          remapField: 'Country',
          whiteList: [],
          blackList: [],
          fieldFormatter: { exclude: 'Totals', replace: '' },
        },
      },
      SIDE_BAR_FILTER_OPTIONS: [
        { value: 'country', label: 'Country', default: true },
        { value: 'company', label: 'Company' },
        { value: 'payroll', label: 'Payroll' },
      ],
    },
    REPORT_CHARTS: {
      EMPLOYER_CONTRIBUTION_COSTS: graphTypesEnum.TILE_COUNT,
      GLOBAL_COSTS: graphTypesEnum.SIDE_BARCHART,
    },
  },
  GLOBAL_EMPLOYEE_DEDUCTION_COSTS: {
    LABEL: 'Global Employee Deduction Costs',
    DESCRIPTION: 'Consolidated employee deduction costs across all countries and payrolls',
    VALUE: 26,
    CHART_LOGIC: {
      IS_FINANCE_TILE: true,
      TOTALS_KEY: 'currency',
      TOTALS_KEY_VALUE: 'Grand Totals',
      TOTALS_EE_DEDUCTIONS_REPORT_KEY: 'Employee Deductions',
      TOTALS_CALCULATION: totalsCalculations.FLAT,
      SIDE_BARCHART_AXIS: { xAxis: 'Country', yAxis: 'Employee Deductions' },
      SIDE_BARCHART_HEADER: 'Employee Deduction',
      SIDE_BARCHART_EXCLUSIONS: {
        individualRecords: {
          field: 'country',
          remapField: 'Country',
          whiteList: [],
          blackList: [],
          fieldFormatter: { exclude: 'Totals', replace: '' },
        },
      },
      SIDE_BAR_FILTER_OPTIONS: [
        { value: 'country', label: 'Country', default: true },
        { value: 'company', label: 'Company' },
        { value: 'payroll', label: 'Payroll' },
      ],
    },
    REPORT_CHARTS: {
      TOTAL_EE_DEDUCTION_COSTS: graphTypesEnum.TILE_COUNT,
      GLOBAL_COSTS: graphTypesEnum.SIDE_BARCHART,
    },
  },
  TOTAL_EMPLOYEE_DEDUCTION_COSTS: {
    LABEL: 'Total Employee Deduction Costs',
    DESCRIPTION: 'Total employee deduction costs for any selected country and payroll',
    VALUE: 27,
    CHART_LOGIC: {
      IS_FINANCE_TILE: true,
      TOTALS_KEY: 'currency',
      TOTALS_KEY_VALUE: 'Grand Totals',
      TOTALS_EE_DEDUCTIONS_REPORT_KEY: 'Employee Deductions',
      TOTALS_CALCULATION: totalsCalculations.FLAT,
      SIDE_BARCHART_AXIS: { xAxis: 'Country', yAxis: 'Employee Deductions' },
      SIDE_BARCHART_HEADER: 'Employee Deduction',
      SIDE_BARCHART_EXCLUSIONS: {
        individualRecords: {
          field: 'country',
          remapField: 'Country',
          whiteList: [],
          blackList: [],
          fieldFormatter: { exclude: 'Totals', replace: '' },
        },
      },
      SIDE_BAR_FILTER_OPTIONS: [
        { value: 'country', label: 'Country', default: true },
        { value: 'company', label: 'Company' },
        { value: 'payroll', label: 'Payroll' },
      ],
    },
    REPORT_CHARTS: {
      TOTAL_EE_DEDUCTION_COSTS: graphTypesEnum.TILE_COUNT,
      GLOBAL_COSTS: graphTypesEnum.SIDE_BARCHART,
    },
  },
  EMPLOYEE_DEDUCTION_COSTS_DETAIL: {
    LABEL: 'Employee Deduction Costs Detail',
    DESCRIPTION: 'Detailed breakdown of total employee deduction costs',
    VALUE: 28,
    CHART_LOGIC: {
      IS_FINANCE_TILE: true,
      TOTALS_KEY: 'currency',
      TOTALS_KEY_VALUE: 'Grand Totals',
      TOTALS_EE_DEDUCTIONS_REPORT_KEY: 'totals_numeric',
      TOTALS_CALCULATION: totalsCalculations.FLAT,
      SIDE_BARCHART_AXIS: { xAxis: 'Country', yAxis: 'totals_numeric' },
      SIDE_BARCHART_HEADER: 'Employee Deduction',
      SIDE_BARCHART_EXCLUSIONS: {
        individualRecords: {
          field: 'country',
          remapField: 'Country',
          whiteList: [],
          blackList: [],
          fieldFormatter: { exclude: 'Totals', replace: '' },
        },
      },
      SIDE_BAR_FILTER_OPTIONS: [
        { value: 'country', label: 'Country', default: true },
        { value: 'company', label: 'Company' },
        { value: 'payroll', label: 'Payroll' },
      ],
    },
    REPORT_CHARTS: {
      TOTAL_EE_DEDUCTION_COSTS: graphTypesEnum.TILE_COUNT,
      GLOBAL_COSTS: graphTypesEnum.SIDE_BARCHART,
    },
  },
  GLOBAL_EMPLOYEE_NET_DEDUCTION_COSTS: {
    LABEL: 'Global Employee Net Deduction Costs',
    DESCRIPTION: 'Consolidated employee net deduction costs across all countries and payrolls',
    VALUE: 29,
    CHART_LOGIC: {
      IS_FINANCE_TILE: true,
      TOTALS_KEY: 'currency',
      TOTALS_KEY_VALUE: 'Grand Totals',
      TOTALS_EE_NET_DEDUCTIONS_REPORT_KEY: 'Employee Net Deductions',
      TOTALS_CALCULATION: totalsCalculations.FLAT,
      SIDE_BARCHART_AXIS: { xAxis: 'Country', yAxis: 'Employee Net Deductions' },
      SIDE_BARCHART_HEADER: 'Employee Net Deduction',
      SIDE_BARCHART_EXCLUSIONS: {
        individualRecords: {
          field: 'country',
          remapField: 'Country',
          whiteList: [],
          blackList: [],
          fieldFormatter: { exclude: 'Totals', replace: '' },
        },
      },
      SIDE_BAR_FILTER_OPTIONS: [
        { value: 'country', label: 'Country', default: true },
        { value: 'company', label: 'Company' },
        { value: 'payroll', label: 'Payroll' },
      ],
    },
    REPORT_CHARTS: {
      TOTAL_EE_NET_DEDUCTION_COSTS: graphTypesEnum.TILE_COUNT,
      GLOBAL_COSTS: graphTypesEnum.SIDE_BARCHART,
    },
  },
  TOTAL_EMPLOYEE_NET_DEDUCTION_COSTS: {
    LABEL: 'Total Employee Net Deduction Costs',
    DESCRIPTION: 'Total employee net deduction costs for any selected country and payroll',
    VALUE: 30,
    CHART_LOGIC: {
      IS_FINANCE_TILE: true,
      TOTALS_KEY: 'currency',
      TOTALS_KEY_VALUE: 'Grand Totals',
      TOTALS_EE_NET_DEDUCTIONS_REPORT_KEY: 'Employee Net Deductions',
      TOTALS_CALCULATION: totalsCalculations.FLAT,
      SIDE_BARCHART_AXIS: { xAxis: 'Country', yAxis: 'Employee Net Deductions' },
      SIDE_BARCHART_HEADER: 'Employee Net Deduction',
      SIDE_BARCHART_EXCLUSIONS: {
        individualRecords: {
          field: 'country',
          remapField: 'Country',
          whiteList: [],
          blackList: [],
          fieldFormatter: { exclude: 'Totals', replace: '' },
        },
      },
      SIDE_BAR_FILTER_OPTIONS: [
        { value: 'country', label: 'Country', default: true },
        { value: 'company', label: 'Company' },
        { value: 'payroll', label: 'Payroll' },
      ],
    },
    REPORT_CHARTS: {
      TOTAL_EE_NET_DEDUCTION_COSTS: graphTypesEnum.TILE_COUNT,
      GLOBAL_COSTS: graphTypesEnum.SIDE_BARCHART,
    },
  },
  EMPLOYEE_NET_DEDUCTION_COSTS_DETAIL: {
    LABEL: 'Employee Net Deduction Costs Detail',
    DESCRIPTION: 'Detailed breakdown of total employee net deduction costs',
    VALUE: 31,
    CHART_LOGIC: {
      IS_FINANCE_TILE: true,
      TOTALS_KEY: 'currency',
      TOTALS_KEY_VALUE: 'Grand Totals',
      TOTALS_EE_NET_DEDUCTIONS_REPORT_KEY: 'totals_numeric',
      TOTALS_CALCULATION: totalsCalculations.FLAT,
      SIDE_BARCHART_AXIS: { xAxis: 'Country', yAxis: 'totals_numeric' },
      SIDE_BARCHART_HEADER: 'Employee Net Deduction',
      SIDE_BARCHART_EXCLUSIONS: {
        individualRecords: {
          field: 'country',
          remapField: 'Country',
          whiteList: [],
          blackList: [],
          fieldFormatter: { exclude: 'Totals', replace: '' },
        },
      },
      SIDE_BAR_FILTER_OPTIONS: [
        { value: 'country', label: 'Country', default: true },
        { value: 'company', label: 'Company' },
        { value: 'payroll', label: 'Payroll' },
      ],
    },
    REPORT_CHARTS: {
      TOTAL_EE_NET_DEDUCTION_COSTS: graphTypesEnum.TILE_COUNT,
      GLOBAL_COSTS: graphTypesEnum.SIDE_BARCHART,
    },
  },
  PAYRUN_MANAGEMENT_REPORT_BY_SUBCATEGORY: {
    LABEL: 'Payrun Detail by Subcategory',
    DESCRIPTION: "Consolidated view of multiple payruns organized by Pay & Taxes Elements' subcategory for any selected country and payroll",
    VALUE: 32,
  },
  PAYRUN_MANAGEMENT_REPORT: {
    LABEL: 'Payrun Management Detail',
    DESCRIPTION: 'Consolidated view of your payrun file(s) across all countries, payrolls, and workforce',
    VALUE: 33,
  },
  PAYRUN_REPORT: {
    LABEL: 'Payrun Detail',
    DESCRIPTION: 'Consolidated view of your payrun file(s) within a selected payroll',
    VALUE: 34,
  },
  CHANGE_LOG: {
    LABEL: 'Change Log',
    DESCRIPTION: 'View all Pay & Taxes and General Updates changes made to your workforce',
    VALUE: 35,
    ENABLED: (check, hasAccess) => {
      return ['accessChangeLogReport'].includes(check) && hasAccess
    },
  },
  CHANGE_LOG_GENERAL_UPDATES: {
    LABEL: 'General Updates Change Log',
    DESCRIPTION: "View all employees' General Updates changes for any country and period",
    VALUE: 36,
    ENABLED: (check, hasAccess) => {
      return ['accessChangeLogReport'].includes(check) && hasAccess
    },
  },
  CHANGE_LOG_PAYROLL_DATA: {
    LABEL: 'Payroll Data Change Log',
    DESCRIPTION: "View all employees' Pay & Taxes changes for any country and period",
    VALUE: 37,
    ENABLED: (check, hasAccess) => {
      return ['accessChangeLogReport'].includes(check) && hasAccess
    },
  },
  GLOBAL_PAY_AND_TAXES_REPORT: {
    LABEL: 'Global Pay & Taxes Overview',
    DESCRIPTION: 'Consolidated global list of all your Pay & Taxes elements across all countries and companies',
    VALUE: 38,
  },
  PAY_AND_TAXES_REPORT: {
    LABEL: 'Pay & Taxes Overview',
    DESCRIPTION: 'View all your Pay & Taxes elements set up per company',
    VALUE: 39,
  },
  PAY_ELEMENTS_LIST: {
    LABEL: 'Pay Elements Overview',
    DESCRIPTION: 'View all your Pay Elements set up per company',
    VALUE: 40,
  },
  EMPLOYER_CONTRIBUTIONS_LISTING: {
    LABEL: 'Employer Contributions Overview',
    DESCRIPTION: 'View all your Employer Contributions set up per company',
    VALUE: 41,
  },
  EMPLOYEE_DEDUCTIONS_LISTING: {
    LABEL: 'Employee Deductions Overview',
    DESCRIPTION: 'View all your Employee Deductions set up per company',
    VALUE: 42,
  },
  EMPLOYEE_NET_DEDUCTIONS_LISTING: {
    LABEL: 'Employee Net Deductions Overview',
    DESCRIPTION: 'View all your Employee Net Deductions set up per company',
    VALUE: 43,
  },
  GLOBAL_PAYROLL_ELEMENT_COSTS: {
    LABEL: 'Global Payroll Element Costs',
    DESCRIPTION: 'Consolidated payroll element costs across all countries and payrolls',
    VALUE: 44,
    CHART_LOGIC: {
      IS_FINANCE_TILE: true,
      TOTALS_KEY: 'currency',
      TOTALS_KEY_VALUE: 'Grand Totals',
      TOTALS_ER_CONTRIBUTION_REPORT_KEY: 'Employer Contributions',
      TOTALS_PAY_ELEMENT_REPORT_KEY: 'Pay elements',
      TOTALS_EE_DEDUCTIONS_REPORT_KEY: 'Employee Deductions',
      TOTALS_EE_NET_DEDUCTIONS_REPORT_KEY: 'Employee Net Deductions',
      TOTALS_CALCULATION: totalsCalculations.FLAT,
      SIDE_BARCHART_AXIS: { xAxis: 'Country', yAxis: 'element' },
      SIDE_BARCHART_EXCLUSIONS: {
        individualRecords: {
          field: 'country',
          remapField: 'Country',
          whiteList: [],
          blackList: [],
          fieldFormatter: { exclude: 'Totals', replace: '' },
        },
      },
      SIDE_BAR_FILTER_OPTIONS_FIRST: [
        { value: 'Pay elements', label: 'Pay Element Cost', default: true },
        { value: 'Employee Deductions', label: 'Employee Deduction Cost', default: true },
        { value: 'Employee Net Deductions', label: 'Employee Net Deduction Cost', default: true },
        { value: 'Employer Contributions', label: 'Employer Contribution Cost', default: true },
      ],
      SIDE_BAR_FILTER_OPTIONS: [
        { value: 'country', label: 'Country', default: true },
        { value: 'company', label: 'Company' },
        { value: 'payroll', label: 'Payroll' },
      ],
    },
    REPORT_CHARTS: {
      EMPLOYER_CONTRIBUTION_COSTS: graphTypesEnum.TILE_COUNT,
      TOTAL_PAY_ELEMENT_COSTS: graphTypesEnum.TILE_COUNT,
      TOTAL_EE_DEDUCTION_COSTS: graphTypesEnum.TILE_COUNT,
      TOTAL_EE_NET_DEDUCTION_COSTS: graphTypesEnum.TILE_COUNT,
      GLOBAL_COSTS_PER_ELEMENT: graphTypesEnum.SIDE_BARCHART,
    },
  },
  PAYROLL_ELEMENT_COSTS: {
    LABEL: 'Total Payroll Element Costs',
    DESCRIPTION: 'Total payroll element costs for any selected country and payroll',
    VALUE: 45,
    CHART_LOGIC: {
      IS_FINANCE_TILE: true,
      TOTALS_KEY: 'currency',
      TOTALS_KEY_VALUE: 'Grand Totals',
      TOTALS_ER_CONTRIBUTION_REPORT_KEY: 'Employer Contributions',
      TOTALS_PAY_ELEMENT_REPORT_KEY: 'Pay elements',
      TOTALS_EE_DEDUCTIONS_REPORT_KEY: 'Employee Deductions',
      TOTALS_EE_NET_DEDUCTIONS_REPORT_KEY: 'Employee Net Deductions',
      TOTALS_CALCULATION: totalsCalculations.FLAT,
      SIDE_BARCHART_AXIS: { xAxis: 'Country', yAxis: 'element' },
      SIDE_BARCHART_EXCLUSIONS: {
        individualRecords: {
          field: 'country',
          remapField: 'Country',
          whiteList: [],
          blackList: [],
          fieldFormatter: { exclude: 'Totals', replace: '' },
        },
      },
      SIDE_BAR_FILTER_OPTIONS_FIRST: [
        { value: 'Pay elements', label: 'Pay Element Cost', default: true },
        { value: 'Employee Deductions', label: 'Employee Deduction Cost', default: true },
        { value: 'Employee Net Deductions', label: 'Employee Net Deduction Cost', default: true },
        { value: 'Employer Contributions', label: 'Employer Contribution Cost', default: true },
      ],
      SIDE_BAR_FILTER_OPTIONS: [
        { value: 'country', label: 'Country', default: true },
        { value: 'company', label: 'Company' },
        { value: 'payroll', label: 'Payroll' },
      ],
    },
    REPORT_CHARTS: {
      GLOBAL_COSTS_PER_ELEMENT: graphTypesEnum.SIDE_BARCHART,
      EMPLOYER_CONTRIBUTION_COSTS: graphTypesEnum.TILE_COUNT,
      TOTAL_PAY_ELEMENT_COSTS: graphTypesEnum.TILE_COUNT,
      TOTAL_EE_DEDUCTION_COSTS: graphTypesEnum.TILE_COUNT,
      TOTAL_EE_NET_DEDUCTION_COSTS: graphTypesEnum.TILE_COUNT,
    },
  },
  PAYROLL_ELEMENT_COSTS_DETAIL: {
    LABEL: 'Payroll Element Costs Detail',
    DESCRIPTION: 'Detailed breakdown of total payroll element costs',
    VALUE: 46,
    CHART_LOGIC: {
      IS_FINANCE_TILE: true,
      TOTALS_KEY: 'currency',
      TOTALS_KEY_VALUE: 'Grand Totals',
      TOTALS_PAYROLL_ELEMENT_REPORT_KEY: 'totals_numeric',
      SIDE_BARCHART_AXIS: { xAxis: 'Country', yAxis: 'totals_numeric' },
      SIDE_BARCHART_EXCLUSIONS: {
        individualRecords: {
          field: 'country',
          remapField: 'Country',
          whiteList: [],
          blackList: [],
          fieldFormatter: { exclude: 'Totals', replace: '' },
        },
      },
      SIDE_BAR_FILTER_OPTIONS: [
        { value: 'country', label: 'Country', default: true },
        { value: 'company', label: 'Company' },
        { value: 'payroll', label: 'Payroll' },
      ],
    },
    REPORT_CHARTS: {
      TOTAL_PAYROLL_ELEMENT_COSTS: graphTypesEnum.TILE_COUNT,
      GLOBAL_COSTS: graphTypesEnum.SIDE_BARCHART,
    },
  },
  ROLES_AND_RIGHTS_CHANGE_LOG: {
    LABEL: 'Roles & Rights Change Log',
    DESCRIPTION: 'View all Roles & Rights changes for any country and period',
    VALUE: 47,
    ENABLED: (check, hasAccess) => {
      return ['accessChangeLogReport'].includes(check) && hasAccess
    },
  },
  GLOBAL_ACCESS_MANAGEMENT: {
    LABEL: 'Global Access Management',
    DESCRIPTION: 'View user access management across all countries and companies',
    VALUE: 48,
  },
  ACCESS_MANAGEMENT: {
    LABEL: 'Access Management',
    DESCRIPTION: 'View user access management for any country and period',
    VALUE: 49,
  },
  CUSTOM_BUILD_REPORT: {
    LABEL: reportFeCategoryIdLabels.customBuildReports.label,
    DESCRIPTION: reportFeCategoryIdLabels.customBuildReports.description,
    VALUE: 50,
  },
  PAYROLL_WORKFLOW_AUDIT: {
    LABEL: 'Payroll Workflow Audit',
    DESCRIPTION: 'Company and payroll provider’s performance measured against agreed workflow deadlines for a single payroll',
    VALUE: 51,
    ...ANALYTICS_WORKFLOW_CONFIG,
    CHART_LOGIC: {
      ...ANALYTICS_WORKFLOW_CONFIG.CHART_LOGIC,
      TITLE: chartSectionHeadings.numberOfGtnVersionsByPayDate,
      SIDE_BARCHART_AXIS: { xAxis: 'payDate', yAxis: 'noOfGtns' },
      SIDE_MULTI_SELECTOR_BARCHART: {
        ...ANALYTICS_REVERSIONS_CONFIG.SIDE_MULTI_SELECTOR_BARCHART,
        defaultAxis: {
          xAxis: 'payDate',
          yAxis: 'noOfReversions',
        },
        selectors: [
          {
            id: 'yAxis',
            title: chartSectionHeadings.numberOfReversionsByPayDate,
            optionsKey: 'SIDE_BAR_REVERSIONS_OPTIONS',
            className: 'u-min-width-250',
          },
        ],
      },
    },
    REPORT_CHARTS: {
      AVG_ON_TIME_COMPLETION_COMPANY: graphTypesEnum.TILE_COUNT,
      AVG_ON_TIME_COMPLETION_PAYROLL_PROVIDER: graphTypesEnum.TILE_COUNT,
      AVG_ON_TIME_COMPLETION_GTN_VERSIONS: graphTypesEnum.TILE_COUNT,
      NUMBER_OF_GTN_VERSIONS_BY_PAY_DATE: graphTypesEnum.SIDE_BARCHART,
      NUMBER_OF_REVERSIONS: graphTypesEnum.SIDE_BARCHART,
    },
  },
  ORG_UNIT_VARIANCE: {
    LABEL: reportFeCategoryIdLabels.orgUnitVarianceReport.label,
    DESCRIPTION: reportFeCategoryIdLabels.orgUnitVarianceReport.description,
    VALUE: 52,
  },
  WORKFORCE_VARIANCE: {
    LABEL: reportFeCategoryIdLabels.workforceVarianceReport.label,
    DESCRIPTION: reportFeCategoryIdLabels.workforceVarianceReport.description,
    VALUE: 53,
  },
  WORKFLOW_TIMELINES: {
    LABEL: reportFeCategoryIdLabels.workflowTimelinesReport.label,
    DESCRIPTION: reportFeCategoryIdLabels.workflowTimelinesReport.description,
    VALUE: 54,
  },
}

// TODO Temporary thing to hide on report lists
export const WhiteListedReports = [
  reportOptionsConfig.GLOBAL_WORK_HEADCOUNT.LABEL,
  reportOptionsConfig.GLOBAL_JOINERS_HEADCOUNT.LABEL,
  reportOptionsConfig.GLOBAL_LEAVERS_HEADCOUNT.LABEL,
  reportOptionsConfig.WORKFORCE_HEADCOUNT.LABEL,
  reportOptionsConfig.JOINERS_HEADCOUNT.LABEL,
  reportOptionsConfig.LEAVERS_HEADCOUNT.LABEL,
  reportOptionsConfig.WORKFORCE_DETAIL.LABEL,
  reportOptionsConfig.LEAVERS_DETAIL.LABEL,
  reportOptionsConfig.JOINERS_DETAIL.LABEL,
  reportOptionsConfig.EMPLOYEE_SELF_SERVE_ACCESS_STATUS_REPORT.LABEL,
  reportOptionsConfig.EMPLOYEE_SELF_SERVE_NO_ACCESS_REPORT.LABEL,
  reportOptionsConfig.EMPLOYEE_SELF_SERVE_ACCESS_REPORT.LABEL,
  reportOptionsConfig.GLOBAL_TOTAL_EMPLOYER_COSTS.LABEL,
  reportOptionsConfig.TOTAL_EMPLOYER_COSTS.LABEL,
  reportOptionsConfig.GLOBAL_PAYROLL_ELEMENT_COSTS.LABEL,
  reportOptionsConfig.GLOBAL_EMPLOYER_CONTRIBUTION_COSTS.LABEL,
  reportOptionsConfig.TOTAL_EMPLOYER_CONTRIBUTION_COSTS.LABEL,
  reportOptionsConfig.GLOBAL_PAY_ELEMENT_COSTS.LABEL,
  reportOptionsConfig.TOTAL_PAY_ELEMENT_COSTS.LABEL,
  reportOptionsConfig.GLOBAL_EMPLOYEE_DEDUCTION_COSTS.LABEL,
  reportOptionsConfig.TOTAL_EMPLOYEE_DEDUCTION_COSTS.LABEL,
  reportOptionsConfig.GLOBAL_EMPLOYEE_NET_DEDUCTION_COSTS.LABEL,
  reportOptionsConfig.TOTAL_EMPLOYEE_NET_DEDUCTION_COSTS.LABEL,
  reportOptionsConfig.PAYROLL_ELEMENT_COSTS.LABEL,
  reportOptionsConfig.EMPLOYER_COSTS_DETAIL.LABEL,
  reportOptionsConfig.PAY_ELEMENTS_COSTS_DETAIL.LABEL,
  reportOptionsConfig.EMPLOYEE_DEDUCTION_COSTS_DETAIL.LABEL,
  reportOptionsConfig.EMPLOYEE_NET_DEDUCTION_COSTS_DETAIL.LABEL,
  reportOptionsConfig.EMPLOYER_CONTRIBUTIONS_COSTS_DETAIL.LABEL,
  reportOptionsConfig.PAYROLL_ELEMENT_COSTS_DETAIL.LABEL,
  reportOptionsConfig.GLOBAL_WORKFLOW_AUDIT.LABEL,
  reportOptionsConfig.WORKFLOW_AUDIT.LABEL,
  reportOptionsConfig.PAYROLL_WORKFLOW_AUDIT.LABEL,
]

export default reportOptionsConfig
