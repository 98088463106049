import React from 'react'
import Fetcher from './Fetcher'
import BankAccountForm from '../components/BankAccountForm'
import { connect } from 'react-redux'
import { formValueSelector, reduxForm } from 'redux-form'
import { createBankAccount } from 'redux/actions/bankAccounts'
import { getCountriesByAuth } from 'redux/selectors/country'
import { getCompaniesByCountry, getCompanyRef } from 'redux/selectors/company'
import {
  getBankAccountTypes,
  getCurrenciesByBankAccountCountryAbbr,
  getFieldsByBankAccountCountryAbbrAndCurrency
} from 'redux/selectors/bankAccountTypes'
import { getUser } from 'redux/selectors/employees'
import _ from 'lodash'
import { createFilter } from '../utils/redux/filter'
import { sortByCountryName, sortByName } from 'utils/strings'

const mapDispatchToProps = (dispatch, props) => ({
  onSubmit: (data) => dispatch(createBankAccount(data))
    .then(() => {
      props.onSubmit()
    })
})

const mapStateToProps = (state, props) => {
  const companyId = props.match.params.companyId
  const employeeId = props.match.params.employeeId

  const employee = employeeId ? getUser(state, { userId: employeeId }) : null
  const currentCompany = companyId
    ? getCompanyRef(state, { companyId })
    : getCompanyRef(state, { companyId: employee.company })
  const isHQ = companyId ? currentCompany.isHQ : null

  const selector = formValueSelector('bankAccountCreate')
  const values = selector(state, 'country', 'company', 'bankCountry', 'currency')

  const customFields = values.country && values.company && values.bankCountry && values.currency
    ? getFieldsByBankAccountCountryAbbrAndCurrency(state, {
      bankCountry: values.bankCountry, currency: values.currency
    }) : null

  const initialVales = {}
  if (employee) initialVales.employee = employeeId

  return {
    countries: getCountriesByAuth(state).sort(sortByName),
    companies: values.country ? getCompaniesByCountry(state, { countryId: values.country }) : [],
    currencies: values.bankCountry
      ? getCurrenciesByBankAccountCountryAbbr(state, { bankCountry: values.bankCountry }) : [],
    bankAccountTypes: _.uniqBy(getBankAccountTypes(state), 'country_abbr').sort(sortByCountryName),
    customFields,
    country: values.country,
    company: values.company,
    bankCountry: values.bankCountry,
    disabled: !isHQ,
    initialValues: {
      ...initialVales,
      country: !isHQ ? currentCompany.country : null,
      company: !isHQ ? currentCompany.id : null,
      employee: employeeId || null,
      bankCountry: null,
      currency: null,
      payrollCountry: !isHQ ? currentCompany.country : null,
    }
  }
}

const Container = connect(mapStateToProps, mapDispatchToProps)(reduxForm({
  form: 'bankAccountCreate'
})(BankAccountForm))

const CompanyFetcher = Fetcher(Container, [
  'countries', 'currencies', 'bankAccountTypes', 'accessAreaPivot', 'companies'
])

const EmployeeFetcher = Fetcher(Container, [
  'countries', 'currencies', 'bankAccountTypes', 'accessAreaPivot', 'companies',
  {
    name: 'employeeSystemUsers',
    params: [{
      _computed: { filter: (state, props) => createFilter({ id: props.match.params.employeeId }) }
    }]
  },
])

export default props => {
  // eslint-disable-next-line react/prop-types
  if (props.match.params.employeeId) {
    return <EmployeeFetcher {...props} />
  }

  return <CompanyFetcher {...props} />
}
