import React from 'react'
import { connect } from 'react-redux'
import FilterContainer from '../containers/FilterContainer'
import { getFormFieldValue } from 'redux/selectors/form'
import InnerWrapper from 'layouts/InnerWrapper'
import SectionHeading from 'components/SectionHeading'
import DownloadTemplateContainer from './DownloadTemplateContainer'
import ImportContainer from './ImportContainer'

const ImportFacade = props => {
  const { payrollId, heading, templateSubHeading, hasFilters = true, TemplateClarifications, children } = props

  return <InnerWrapper>
    {TemplateClarifications && <TemplateClarifications />}
    { hasFilters && <FilterContainer {...props} /> }
    { ((hasFilters && payrollId) || !hasFilters) && <div>
      <SectionHeading text='Download template' />
      { templateSubHeading && <SectionHeading text={templateSubHeading} priority={2} bolded={false} huge={false} /> }
      <DownloadTemplateContainer hasFilters={hasFilters} {...props} />
      <SectionHeading text={heading} />
      <ImportContainer hasFilters={hasFilters} {...props} />
    </div> }
    { children }
  </InnerWrapper>
}

const mapStateToProps = state => {
  const payrollId = getFormFieldValue(state, 'companyFilter', 'payroll')
  const companyId = getFormFieldValue(state, 'companyFilter', 'company')

  return {
    payrollId,
    companyId,
    shouldUseCompanyAsFilter: payrollId === 'all'
  }
}

export default connect(mapStateToProps)(ImportFacade)
