import createSelector from 'utils/createSelector'
import moment from 'moment'
import _ from 'lodash'
import { isCot } from 'redux/selectors/auth'
import { makeFilteredIdsByFilter } from 'redux/selectors/filters'

const getFilteredIds = makeFilteredIdsByFilter('notifications')

// get notification Id
const getNotificationId = (state, props) => parseInt(props.notificationId)
const isUserCot = (state) => isCot(state)

export const getNotifications = createSelector(isUserCot, ({ Notification }, isCot) => {
  const notifications = Notification.all().toRefArray().map(notification => ({
    ...notification,
    createdAt: isCot
      ? `${moment(notification.created_at.date).format('DD/MM/YYYY HH:mm ')} ${notification.created_at.timezone}`
      : moment(notification.created_at.date).format('DD/MM/YYYY'),
    ...notification.extraData
  }))
  return _.orderBy(notifications, ['created_at["date"]'], ['desc'])
})

export const getNotificationById = createSelector(getNotificationId,
  ({ Notification }, notificationId) => {
    const notification = Notification.withId(notificationId)
    return {
      ...notification.ref
    }
  }
)

export const getFilteredNotifications = createSelector(getFilteredIds,
  ({ Notification }, ids) => ids.map(id => Notification.withId(id).ref))

export const getFilteredNotificationsDecorated = createSelector(getFilteredIds, isUserCot,
  ({ Notification }, ids, isCot) => {
    return ids.map(id => {
      const notification = Notification.withId(id).ref

      return {
        ...notification,
        // eslint-disable-next-line max-len
        createdAt: `${moment(notification.created_at.date).format('DD/MM/YYYY HH:mm ')} ${notification.created_at.timezone ? notification.created_at.timezone : null}`,
        ...notification.extraData
      }
    })
  })
