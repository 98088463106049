import React from 'react'
import { Field } from 'redux-form'
import { isArray } from 'lodash'
import PropTypes from 'prop-types'
import SubmitButtons from './SubmitButtons'
import InputField from 'components/form/InputField'
import FieldDatePicker from 'components/form/FieldDatePicker'
import FlagOption from 'components/form/select/FlagOption'
import ColorOption from 'components/form/select/ColorOption'
import CheckboxOption from 'components/form/select/CheckboxOption'
import CustomSelectField from 'components/form/select/CustomSelectField'
import PayrollSelectContainer from '../containers/PayrollSelectContainer'
import PayrollInstancesSelectContainer from '../containers/PayrollInstancesSelectContainer'
import EmploymentStatusHelper from 'utils/helperClasses/EmploymentStatuses'
import MostRecentRunReportItem from 'routes/Reporting/components/MostRecentRunReportItem'
import { sortByLabel } from 'utils/strings'
import { isEmpty } from 'utils/fnkit/typeChecks'

class FiltersEmployeeDataChangesReport extends React.Component {
  render () {
    const {
      setCurrency,
      selected,
      selectedType,
      countries,
      companies,
      currencies,
      fxRates,
      isFieldDisabled,
      disabledReason,
      mostRecentRunReport = {},
      handleModalOpen,
      businessUnits,
      costCenters,
      departments,
      resetFormFields,
    } = this.props

    const isVarianceReport = selectedType === 'VarianceReport'
    const isOrgUnitVarianceReport = selectedType === 'OrgUnitVarianceReport'
    const isWorkforceVariance = selectedType === 'WorkforceVarianceReport'
    const payrollInstancesStatuses = isVarianceReport
      ? [
        'active',
        'reopened',
        'sent-and-locked',
        'gross-to-net-calculations-and-review-done',
        'cot-final-payroll-reports-review-done',
        'completed',
        'completed-with-extra-tasks',
      ]
      : [
        'active',
        'reopened',
        'completed',
        'sent-and-locked',
        'gross-to-net-calculations-and-review-done',
        'cot-final-payroll-reports-review-done',
        'completed-with-extra-tasks',
        'reverted',
      ]

    const shouldAllowMultiCompany = !isOrgUnitVarianceReport && !isWorkforceVariance
    const shouldAllowMultiPayroll = !isVarianceReport && !isOrgUnitVarianceReport && !isWorkforceVariance

    const shouldShowMostRecentRunReportItem = !this.props.reportItem

    const pristineCheckConfig = {
      defaultPristineCheck: !isEmpty(selected.payrollInstance) && !isEmpty(selected.payrollCurrency),
      OrgUnitVarianceReport: !isEmpty(selected.payrollInstance) && !isEmpty(selected.payrollCurrency) && !isEmpty(selected.combinedSubcategory),
    }

    /*
     * Every next filter is shown if the last required field has value
     */
    return (
      <div className='o-block--inline'>
        {selected.subcategory && (
          <div className='o-layout__item u-padding-left-small u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
            <div className='c-modern-input'>
              <Field
                className='c-input c-input--transparent c-input__value-placeholder'
                label='From Date'
                labelClassName='c-label u-text--small u-text--curious'
                name='fromDate'
                component={FieldDatePicker}
                disabled={isFieldDisabled}
                disabledReason={disabledReason}
                formGroupClassName='u-margin-none'
                isClearable={!isFieldDisabled}
                enableRemovedOptionFix
                data-testid='fromDate'
              />
            </div>
          </div>
        )}
        {selected.subcategory && (
          <div className='o-layout__item u-padding-left-small u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
            <div className='c-modern-input'>
              <Field
                className='c-input c-input--transparent c-input__value-placeholder'
                label='To Date'
                labelClassName='c-label u-text--small u-text--curious'
                name='toDate'
                component={FieldDatePicker}
                disabled={isFieldDisabled}
                disabledReason={disabledReason}
                formGroupClassName='u-margin-none'
                isClearable={!isFieldDisabled}
                data-testid='toDate'
              />
            </div>
          </div>
        )}
        {selected.subcategory && (
          <div className='o-layout__item u-padding-left-small u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
            <div className='c-modern-input'>
              <Field
                name='country'
                label='Country *'
                component={CustomSelectField}
                className='c-custom-select--transparent c-custom-select--left-padding'
                labelClassName='c-label u-text--small u-text--curious'
                formGroupClassName='u-margin-none'
                optionComponent={FlagOption}
                placeholder='Select'
                disabled={isFieldDisabled}
                disabledReason={disabledReason}
                options={countries.map((country) => ({
                  value: country.id,
                  label: country.name,
                  abbreviature: country.abbreviature,
                }))}
                enableRemovedOptionFix
                data-testid='country-wrapper'
              />
            </div>
          </div>
        )}
        {selected.country && (
          <div className='o-layout__item u-padding-left-small u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
            <div className='c-modern-input'>
              <Field
                name='company'
                label='Company *'
                component={CustomSelectField}
                className='c-custom-select--transparent c-custom-select--left-padding'
                labelClassName='c-label u-text--small u-text--curious'
                formGroupClassName='u-margin-none'
                placeholder='Select'
                multi={shouldAllowMultiCompany}
                disabled={isFieldDisabled}
                disabledReason={disabledReason}
                options={companies
                  .map((company) => ({
                    value: company.id,
                    label: company.name,
                  }))
                  .sort(sortByLabel)}
                enableRemovedOptionFix
                data-testid='company-wrapper'
              />
            </div>
          </div>
        )}
        {selected.company && (
          <div className='o-layout__item u-padding-left-small u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
            <div className='c-modern-input'>
              <PayrollSelectContainer
                optionComponent={shouldAllowMultiPayroll ? CheckboxOption : ColorOption}
                {...(!isFieldDisabled
                  ? {
                    onChanged: () => {
                      setCurrency()
                      resetFormFields(null, ['reportingRate', 'rate', 'toCurrency'])
                    },
                  }
                  : {})}
                multi={shouldAllowMultiPayroll}
                {...this.props}
              />
            </div>
          </div>
        )}
        {!isEmpty(selected.payroll) && (
          <div className='o-layout__item u-padding-left-small u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
            <div className='c-modern-input'>
              <PayrollInstancesSelectContainer
                status={payrollInstancesStatuses}
                {...(!isFieldDisabled
                  ? {
                    onChanged: () => {
                      setCurrency()
                      resetFormFields(null, ['reportingRate', 'rate', 'toCurrency'])
                    },
                  }
                  : {})}
                {...this.props}
              />
            </div>
          </div>
        )}
        {!isEmpty(selected.payrollInstance) && !isVarianceReport && (
          <div className='o-layout__item u-padding-left-small u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
            <div className='c-modern-input'>
              <Field
                name='employmentStatus'
                label='Type of Employment/Engagement'
                component={CustomSelectField}
                className='c-custom-select--transparent'
                labelClassName='c-label u-text--small u-text--curious'
                formGroupClassName='u-margin-none'
                optionComponent={CheckboxOption}
                placeholder='Any'
                disabled={isFieldDisabled}
                disabledReason={disabledReason}
                options={EmploymentStatusHelper.allStatusOptionsForSelectBox}
                data-testid='type-of-employment'
                multi
                hasNoneOption
              />
            </div>
          </div>
        )}
        {selected.payrollInstance && !isVarianceReport && (
          <div className='o-layout__item u-padding-left-small u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
            <div className='c-modern-input'>
              <Field
                name='businessUnit'
                label='Business Unit'
                component={CustomSelectField}
                optionComponent={CheckboxOption}
                className='c-custom-select--transparent'
                labelClassName='c-label u-text--small u-text--curious'
                formGroupClassName='u-margin-none'
                placeholder='Any'
                disabled={isFieldDisabled}
                disabledReason={disabledReason}
                multi
                options={businessUnits
                  .map((unit) => ({
                    value: unit.id,
                    label: unit.name,
                  }))
                  .sort(sortByLabel)}
                data-testid='businessUnit-wrapper'
                hasNoneOption
                enableRemovedOptionFix
              />
            </div>
          </div>
        )}
        {selected.payrollInstance && !isVarianceReport && (
          <div className='o-layout__item u-padding-left-small u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
            <div className='c-modern-input'>
              <Field
                name='costCenter'
                label='Cost Center'
                component={CustomSelectField}
                optionComponent={CheckboxOption}
                className='c-custom-select--transparent'
                labelClassName='c-label u-text--small u-text--curious'
                formGroupClassName='u-margin-none'
                placeholder='Any'
                disabled={isFieldDisabled}
                disabledReason={disabledReason}
                multi
                options={costCenters
                  .map((cc) => ({
                    value: cc.id,
                    label: cc.name,
                  }))
                  .sort(sortByLabel)}
                hasNoneOption
                enableRemovedOptionFix
                data-testid='costCenter-wrapper'
              />
            </div>
          </div>
        )}
        {selected.payrollInstance && !isVarianceReport && (
          <div className='o-layout__item u-padding-left-small u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
            <div className='c-modern-input'>
              <Field
                name='department'
                label='Department'
                component={CustomSelectField}
                optionComponent={CheckboxOption}
                className='c-custom-select--transparent'
                labelClassName='c-label u-text--small u-text--curious'
                formGroupClassName='u-margin-none'
                placeholder='Any'
                disabled={isFieldDisabled}
                disabledReason={disabledReason}
                multi
                options={departments
                  .map((dep) => ({
                    value: dep.id,
                    label: dep.name,
                  }))
                  .sort(sortByLabel)}
                hasNoneOption
                enableRemovedOptionFix
                data-testid='department-wrapper'
              />
            </div>
          </div>
        )}
        {!isEmpty(selected.payrollInstance) && (
          <div className='o-layout__item u-padding-left-small u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
            <div className='c-modern-input'>
              <Field
                name='payrollCurrency'
                label='Payroll Currency *'
                component={CustomSelectField}
                className='c-custom-select--transparent'
                labelClassName='c-label u-text--small u-text--curious'
                formGroupClassName='u-margin-none'
                placeholder='Select'
                disabled
                multi
                disabledReason={isFieldDisabled ? disabledReason : 'Payroll currency'}
                options={currencies.map((currency) => ({
                  value: currency.id,
                  label: currency.abbreviature,
                }))}
                enableRemovedOptionFix
                data-testid='payrollCurrency-wrapper'
              />
            </div>
          </div>
        )}
        {!isEmpty(selected.payrollInstance) && (
          <div className='o-layout__item u-padding-left-small u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
            <div className='c-modern-input'>
              <Field
                name='reportingCurrency'
                label='Reporting Currency *'
                component={CustomSelectField}
                className='c-custom-select--transparent'
                labelClassName='c-label u-text--small u-text--curious'
                formGroupClassName='u-margin-none'
                placeholder='Select'
                disabled
                multi
                disabledReason={
                  isFieldDisabled
                    ? disabledReason
                    : `By default reporting currency is the same as the payroll currency.
                    If you want to change it please click the button "Change reporting currency" and choose an option`
                }
                options={currencies.map((currency) => ({
                  value: currency.id,
                  label: currency.abbreviature,
                }))}
                enableRemovedOptionFix
                data-testid='reportingCurrency-wrapper'
              />
            </div>
          </div>
        )}
        {selected.payrollCurrency && selected.reportingCurrency && JSON.stringify(selected.payrollCurrency) !== JSON.stringify(selected.reportingCurrency) ? (
          <div className='o-layout__item u-padding-left-small u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
            <div className='c-modern-input'>
              {isArray(selected.reportingRate) ? (
                <Field
                  name='reportingRate'
                  label='Rate'
                  component={CustomSelectField}
                  className='c-custom-select--transparent'
                  labelClassName='c-label u-text--small u-text--curious'
                  formGroupClassName='u-margin-none'
                  placeholder='Select'
                  multi
                  disabled
                  disabledReason={
                    isFieldDisabled
                      ? disabledReason
                      : `If you want to change the rate please click the button
                      "Change reporting currency" and choose an option`
                  }
                  options={fxRates.map((rate) => ({
                    value: rate.id,
                    label: rate.filterName,
                  }))}
                  data-testid='reportingRate'
                />
              ) : (
                <Field
                  name='reportingRate'
                  label='Rate'
                  labelClassName='c-label u-text--small u-text--curious'
                  className='c-input c-input--transparent'
                  component={InputField}
                  type='text'
                  formGroupClassName='u-margin-none'
                  disabled
                  data-testid='reportingRate'
                />
              )}
            </div>
          </div>
        ) : null}
        {!isEmpty(selected.payrollInstance) && !isFieldDisabled && (
          <div className='o-layout__item u-padding-left-small u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
            <div className='c-panel u-padding-top u-padding-bottom o-grid o-grid--medium'>
              <div onClick={() => handleModalOpen()} className='c-btn c-btn--small rounded shadow-md c-btn--curious u-padding-left u-padding-right'>
                Change Reporting Currency
              </div>
            </div>
          </div>
        )}
        {!this.props.hideNavigationButtons && (
          <SubmitButtons
            pristineCheck={pristineCheckConfig[selectedType] ?? pristineCheckConfig.defaultPristineCheck}
            onBackClick={this.props.navigateToParentOnClick}
            {...this.props}
          />
        )}

        {shouldShowMostRecentRunReportItem && (
          <div
            className='o-layout__item u-padding-left-small u-text--center
           u-1/1 u-margin-top-small u-padding-top-small'
          >
            <MostRecentRunReportItem
              mostRecentRunReport={mostRecentRunReport}
              onOpenReport={this.props.handleReportModalOpen}
              onDelete={this.props.deleteReport}
              {...this.props}
            />
          </div>
        )}
      </div>
    )
  }
}

FiltersEmployeeDataChangesReport.propTypes = {
  resetFormFields: PropTypes.func,
  setCurrency: PropTypes.func,
  categories: PropTypes.array,
  subcategories: PropTypes.array,
  // Props in order to check if the previous filter has a value
  // Show next filter only if the previous is filled
  selected: PropTypes.object,
  // Dropdown options data
  countries: PropTypes.array,
  companies: PropTypes.array,
  payrolls: PropTypes.array,
  payrollInstances: PropTypes.array,
  currencies: PropTypes.array,
  fxRates: PropTypes.array,
  // Disabled fields and submit btn check
  isFieldDisabled: PropTypes.bool,
  disabledReason: PropTypes.string,
  showRunReportBtn: PropTypes.bool,
  navigateToParentOnClick: PropTypes.func,
  mostRecentRunReport: PropTypes.object,
  deleteReport: PropTypes.func,
  handleReportModalOpen: PropTypes.func,
  selectedType: PropTypes.string,
  handleModalOpen: PropTypes.func,
  businessUnits: PropTypes.array,
  costCenters: PropTypes.array,
  departments: PropTypes.array,
  hideNavigationButtons: PropTypes.bool,
}

export default FiltersEmployeeDataChangesReport
