import React from 'react'

import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'

import { ReportDesigner } from './ReportDesigner'

import { getUserSpecialRights } from 'redux/selectors/employees'
import { isCot } from 'redux/selectors/auth'

export default (props) => {
  const history = useHistory()
  const specialRights = useSelector(getUserSpecialRights)
  const isCotUser = useSelector(isCot)
  const hasAccessToReportDesigner = specialRights?.reportDesigner || isCotUser

  if (!hasAccessToReportDesigner) {
    history.push('/reporting/customize-report')
  }
  return <ReportDesigner {...props} />
}
