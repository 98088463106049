import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { reduxForm, formValueSelector } from 'redux-form'
import { isFetching } from 'utils/redux/fetching'
import { getAccessibleCompanies } from 'redux/selectors/company'
import { getPayrollsByCompany } from 'redux/selectors/payroll'
import Filter from '../components/Filter'
import Fetcher from '../../../containers/Fetcher'

const getPayrollOptions = (payrolls, shouldIncludeAllOption) => {
  let options = payrolls.map(payroll => ({
    value: payroll.id,
    label: payroll.name
  }))

  if (shouldIncludeAllOption) options = [{ value: 'all', label: 'Select all' }, ...options]

  return options
}

const mapStateToProps = (state, { shouldIncludeAllPayrollsOption }) => {
  const { companies, payrolls } = state

  const entities = [ companies, payrolls ]

  if (isFetching(entities)) return { isFetching: true }

  const selector = formValueSelector('companyFilter')
  const companyId = selector(state, 'company')
  const payrollId = selector(state, 'payroll')

  return {
    companyId,
    payrollId,
    companies: getAccessibleCompanies(state),
    payrolls: companyId ? getPayrollOptions(
      getPayrollsByCompany(state, { companyId }), shouldIncludeAllPayrollsOption
    ) : [],
  }
}

const Container = connect(mapStateToProps)(reduxForm({
  form: 'companyFilter',
})(Filter))

Container.propTypes = {
  dispatch: PropTypes.func,
  isFetching: PropTypes.bool,
  shouldIncludeAllPayrollsOption: PropTypes.bool
}

export default Fetcher(Container, ['countries', 'companies', 'payrolls'])
