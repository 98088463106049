export const notificationTypeLabels = {
  FinalReversionTaskCompletedNotification: 'Run completed',
  GTNCalculationsFinishedNotification: 'GTN Calculations completed',
  GTNValidationFailedNotification: 'GTN Validation failed',
  GTNValidationErrorNotification: 'GTN Validation Error',
  TaskFinishedNotification: 'Action completed',
  AODeadlinePassedNotification: 'Action owner deadline passed',
  NODeadlinePassedNotification: 'Next action owner deadline passed',
  PODeadlinePassedNotification: 'Process owner deadline passed',
  FiveDaysToDeadlineNotification: 'Five days to start of updates',
  DeadlineTodayNotification: 'Deadline today',
  TaskNotFinishedNotification: 'Action incomplete',
  PODeadlineTodayNotification: 'Process owner deadline today',
  DeadlineTomorrowNotification: 'Deadline is tomorrow',
  ApproveAllUpdatesDone: 'Action Approve all updates completed',
  CNReversionCompletedNotification: 'Reversion completed',
  FileUploadedNotification: 'File Uploaded',
  NoteCreatedNotification: 'Note Created',
  SendForPaymentFinishedNotification: 'Action Send for Payment completed',
  StepCreatedNotification: 'Step created',
  TaskCreatedNotification: 'Action created',
  TaskRevertedNotification: 'Action reverted',
  DocumentDeleteNotification: 'Document deleted',
  CompanyCountryTermBulkCreationNotification: 'Element created',
  CompanyCountryTermBulkUpdateNotification: 'Element updated',
  PayrollInstanceLockUnlockNotification: 'Payrun locked / unlocked',
}

export const messageTextLabels = {
  successfully: 'Successfully deleted.',
  reportSaved: 'Your report has been successfully saved. \n You can view your report in the Saved Reports tab.',
}
