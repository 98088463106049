import React from 'react'
import PropTypes from 'prop-types'

import { SupportingDocsContent } from '../components/SupportingDocsContent'
import Fetcher from 'containers/Fetcher'
import { LinkDocument } from 'components/documents/LinkDocument'
import { createFilter } from 'utils/redux/filter'
import { createDocumentFilterNameById, prepareFilters } from 'redux/filters/createDocumentFilter'

const typeClass = 'payrollInstance'

export const Container = (props) => {
  return (
    <div className='rounded border u-padding-small'>
      <div className='d-flex jc--flex-end'>
        <LinkDocument payrollInstanceId={props.payrollInstanceId} />
      </div>
      <SupportingDocsContent {...props} />
    </div>
  )
}

Container.propTypes = {
  payrollInstanceId: PropTypes.string.isRequired,
}

export const SummarySupportingDocsContainer = (props) => {
  const DocumentsFetcher = Fetcher(Container, [
    'parentCompanies',
    'documentTypes',
    {
      name: 'document',
      params: [
        {
          _computed: {
            filter: () => {
              const computedFilters = prepareFilters({
                filterNames: ['typeClass', 'typeId'],
                props: {
                  typeId: props.payrollInstanceId,
                  typeClass: 'payrollInstance',
                },
              })
              return createFilter(computedFilters, createDocumentFilterNameById(props.payrollInstanceId))
            },
          },
          disableObsoleteFlow: true,
        },
      ],
    },
  ])
  return <DocumentsFetcher {...props} typeClass={typeClass} typeId={props.payrollInstanceId} payrollSummary={false} />
}
