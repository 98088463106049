import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { DownloadButton, PrimaryButton } from 'components/buttons'
import { PadlockIcon, PADLOCK_ICONS_TYPES } from 'components/PadlockIcons'
import { Authorization } from 'containers/Authorization'
import reportOptionsConfig from 'configs/reports'
import usePathName from 'hooks/usePathName'
import { payrunStatesEnum } from 'utils/enums/payrunStates'
import { ALL_ROUTES } from 'configs/routes'
import moment from 'moment'

const NavigationButtons = ({ basePath, button: { path, text, size = '' } }) => {
  return (
    <Link to={`${basePath}/${path}`}>
      <PrimaryButton classes={`u-margin-left${size} u-margin-bottom-tiny`}>{text}</PrimaryButton>
    </Link>
  )
}

NavigationButtons.propTypes = {
  basePath: PropTypes.string.isRequired,
  button: PropTypes.shape({
    path: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    size: PropTypes.string,
  }),
}

const PayrunFileModalButtons = ({
  payrollInstance,
  togglePayrollInstanceLockUnlock,
  isPayrollInstanceLocked,
  hasAccess,
  downloadArchive,
  createReport,
  triggerGlReportCreation,
  canCreateGlReport,
  disablePayrunTimeAttendanceDownload,
  userHasAccessToChangeLogReport,
}) => {
  let pathname = usePathName()
  const reopenedPath = `/${ALL_ROUTES.PAYRUNS.BASE}/${ALL_ROUTES.PAYRUNS.REOPENED}`
  const alternativePath = usePathName(reopenedPath, payrollInstance.status === payrunStatesEnum.REOPENED)

  const navButtons = [
    { base: alternativePath, path: 'instance', text: 'Payrun File', size: '-small' },
    { base: alternativePath, path: 'pay-and-taxes-changes', text: 'P&T Changes' },
    { base: pathname, path: 'time-attendance-updates', text: 'Time & Attendance Overtime' },
  ]

  return (
    <>
      <div className='o-layout__item o-grid o-grid--middle o-grid--center u-text--center u-margin u-1/1'>
        <button
          type='button'
          className='
            c-btn c-btn--transparent
            c-btn--small border-none
            u-padding-left-tiny
            u-padding-right-tiny
            as-start'
          data-testid={`padlock-${payrollInstance.id}`}
          onClick={() => togglePayrollInstanceLockUnlock()}
        >
          <PadlockIcon type={isPayrollInstanceLocked ? PADLOCK_ICONS_TYPES.padlockLocked : PADLOCK_ICONS_TYPES.padlockUnlocked} />
        </button>
        {hasAccess && (
          <>
            {navButtons.map((button) => (
              <NavigationButtons key={button.path} basePath={`${button.base}/${payrollInstance.id}`} button={button} />
            ))}
            <DownloadButton
              classes={`u-margin-left u-margin-bottom-tiny ${disablePayrunTimeAttendanceDownload ? 'disabled' : ''}`}
              isDownloading={disablePayrunTimeAttendanceDownload}
              onClick={() => downloadArchive(payrollInstance.id)}
            >
              Download Payrun and T&A Files
            </DownloadButton>
            <Authorization permissions={['REPORT_CREATE']}>
              <PrimaryButton
                classes='u-margin-left u-margin-bottom-tiny'
                onClick={() => {
                  const data = {
                    type: 'GlobalPayrunManagementReport',
                    category: 'GlobalPayrunManagement',
                    subcategory: 'GlobalPayrunManagement',
                    showOnlyTotals: true,
                    country: [payrollInstance.countryId],
                    company: [payrollInstance.companyId],
                    payroll: payrollInstance.payrollId,
                    payrollInstance: [payrollInstance.id],
                    selectedReportType: reportOptionsConfig.PAYRUN_MANAGEMENT_REPORT_BY_SUBCATEGORY.VALUE,
                  }
                  createReport(data)
                }}
              >
                GTN Totals
              </PrimaryButton>
            </Authorization>
          </>
        )}
        <Authorization permissions={['REPORT_CREATE']}>
          <PrimaryButton
            classes='u-margin-left u-margin-bottom-tiny'
            onClick={() => {
              const data = {
                type: 'EmployeeDataChangesReport',
                category: 'EmployeePayrollChanges',
                subcategory: 'EmployeePayrollChanges',
                country: payrollInstance.countryId,
                company: payrollInstance.companyId,
                payroll: payrollInstance.payrollId,
                payrollInstance: [payrollInstance.id],
                selectedReportType: reportOptionsConfig.PAYROLL_CHANGES_REPORT.VALUE,
              }
              createReport(data)
            }}
            data-testid='payrun-changes-button'
          >
            Payrun Changes
          </PrimaryButton>
        </Authorization>
        {userHasAccessToChangeLogReport && (
          <Authorization permissions={['REPORT_CREATE']}>
            <PrimaryButton
              classes='u-margin-left u-margin-bottom-tiny'
              onClick={() => {
                const data = {
                  type: 'ChangeLogReport',
                  category: 'ChangeLogReport',
                  subcategory: 'All',
                  country: payrollInstance.countryId,
                  company: payrollInstance.companyId,
                  payroll: payrollInstance.payrollId,
                  fromDate: moment(payrollInstance.fromDate.date),
                  toDate: moment(payrollInstance.toDate.date),
                  payrollInstance: [payrollInstance.id],
                  selectedReportType: reportOptionsConfig.CHANGE_LOG.VALUE,
                }
                createReport(data)
              }}
              data-testid='change-log-button'
            >
              Change Log
            </PrimaryButton>
          </Authorization>
        )}
        {canCreateGlReport && (
          <PrimaryButton classes='u-margin-left u-margin-bottom-tiny' onClick={() => triggerGlReportCreation(payrollInstance.id)}>
            GL Report
          </PrimaryButton>
        )}
      </div>
    </>
  )
}

PayrunFileModalButtons.propTypes = {
  payrollInstance: PropTypes.object,
  togglePayrollInstanceLockUnlock: PropTypes.func,
  isPayrollInstanceLocked: PropTypes.bool,
  downloadArchive: PropTypes.func,
  hasAccess: PropTypes.bool,
  createReport: PropTypes.func,
  triggerGlReportCreation: PropTypes.func,
  canCreateGlReport: PropTypes.bool,
  disablePayrunTimeAttendanceDownload: PropTypes.bool,
}

export default PayrunFileModalButtons
