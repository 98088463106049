import { connect } from 'react-redux'
import Fetcher from 'containers/Fetcher'
import PropTypes from 'prop-types'
import { change, getFormInitialValues, getFormValues, reduxForm, SubmissionError } from 'redux-form'
import { diff } from 'utils/object'
import { invalidatePayrollInstanceEmployeePivot } from 'redux/actions/payrollInstanceEmployeePivot'
import { invalidatePayrollInstanceCountryTermsPivot } from 'redux/actions/payrollInstanceCountryTermsPivot'
import { invalidateAnnualSalaries } from 'redux/actions/annualSalaries'
import { invalidatePayrollInstanceTask, mergePayrollInstanceTasks } from 'redux/actions/payrollInstanceTask'
import { invalidatePayrollInstances } from 'redux/actions/payrollInstances'
import { isCot, isPayrollAdmin, isPayrollAnalyst } from 'redux/selectors/auth'
import { areTasksCompleted, removeDisabledTasks } from 'redux/models/payrollInstanceTask'
import {
  getDisabledPayrollInstanceTasksByStep,
  getPayrollInstanceTasksByStep,
  getPayrollInstanceTasksByStepInitial,
  isPaymentTaskCompleted as isPaymentTaskCompletedSelector,
} from '../selectors/payrollInstanceTasks'
import { showMessage } from 'redux/actions/modal'
import { removeTasksURLParameters } from 'utils/query'
import { createFilter } from 'utils/redux/filter'
import { getPayrollInstanceRef } from 'redux/selectors/payrollInstance'
import PayrollInstanceTaskView from '../components/PayrollInstanceTask/PayrollInstanceTaskView'
import Raven from 'redux/middlewares/raven'
import { payrunMessages } from 'utils/locales/payruns.en'

const findReversibleTaskId = (tasksFormValues = {}) => {
  return Object.keys(tasksFormValues).find((key) => {
    const task = tasksFormValues[key]

    return task.isEditable && task.isReversible && task.status === 'reverted'
  })
}

const mapStateToProps = (state, { payrollInstanceId }) => {
  const payrollInstance = getPayrollInstanceRef(state, { payrollInstanceId })
  const payrollInstanceStepId = payrollInstance.currentStepId

  const tasks = getPayrollInstanceTasksByStep(state, { payrollInstanceStepId })
  const tasksInitials = getPayrollInstanceTasksByStepInitial(state, { payrollInstanceStepId })
  const disabledTasks = getDisabledPayrollInstanceTasksByStep(state, { payrollInstanceStepId })
  // If there is a task which is editable, user can edit
  // Otherwise we won't display submit button and each task will be disabled for editing
  const canEdit = tasks.some((task) => task.isEditable)

  // TODO - here we have the accumulated status, resulted for PITask status and MAO status
  // Because of this - `areCurrentTasksCompleted` may be wrong, because in the case an owner completes its own tasks,
  // doesn't explicitly mean all the tasks are completed too.
  // However - we discussed it with the PM, and for now we won't change the logic.
  const currentFormValues = getFormValues('payrollInstanceTaskEdit')(state)
  const areCurrentTasksCompleted = areTasksCompleted(currentFormValues)

  const reversibleTaskId = findReversibleTaskId(currentFormValues)

  return {
    formValues: {
      initial: getFormInitialValues('payrollInstanceTaskEdit')(state),
      current: currentFormValues,
    },
    areTasksCompleted: areCurrentTasksCompleted,
    areStepsCompleted: payrollInstance.isLastStep && areCurrentTasksCompleted,
    hasExtraSteps: payrollInstance.hasExtraSteps,
    isLastStepBeforeExtraStepsCompleted: payrollInstance.isLastBeforeExtraStep && areCurrentTasksCompleted,
    isPaymentTaskCompleted: isPaymentTaskCompletedSelector(state, { formValues: currentFormValues }),
    tasks,
    disabledTasks,
    isPayrollAdmin: isPayrollAdmin(state),
    isPayrollAnalyst: isPayrollAnalyst(state),
    stepId: payrollInstanceStepId,
    reversibleTaskId,
    companyId: payrollInstance.company,
    reversionType: reversibleTaskId && currentFormValues[reversibleTaskId].type,
    reversionReason: reversibleTaskId && currentFormValues[reversibleTaskId].reason,
    reversionDraftableTasks: reversibleTaskId && currentFormValues[reversibleTaskId].draftableTasks,
    reversionDraftableTasksHeading: reversibleTaskId && currentFormValues[reversibleTaskId].draftableTasksHeading,
    isCOT: isCot(state),
    isPayrollInstanceFrozen: payrollInstance?.newLockUnlock.isPayrollInstanceFrozen,
    initialValues: {
      ...tasksInitials,
    },
    showSubmitBtnOnActionsForm: canEdit && !reversibleTaskId,
  }
}

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const {
    formValues,
    areStepsCompleted,
    areTasksCompleted,
    isPaymentTaskCompleted,
    hasExtraSteps,
    isLastStepBeforeExtraStepsCompleted,
    disabledTasks,
    reversibleTaskId,
    companyId,
  } = stateProps
  const { dispatch } = dispatchProps
  const { onSubmit } = ownProps

  return {
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    onSubmit: (tasks) => {
      let normalized = removeDisabledTasks(tasks, disabledTasks)
      normalized = diff(formValues.initial, normalized)

      const reversibleTaskIdParsed = reversibleTaskId ? parseInt(JSON.parse(reversibleTaskId).id) : null

      return (
        dispatch(mergePayrollInstanceTasks(normalized, { shouldRefetch: false }))
          .then(() => {
            onSubmit(areStepsCompleted, isPaymentTaskCompleted, areTasksCompleted, isLastStepBeforeExtraStepsCompleted, reversibleTaskIdParsed, companyId)

            dispatch(invalidatePayrollInstances())
            dispatch(invalidatePayrollInstanceTask())

            // There are 4 scenarios where we inform the user about the payrun completion with different messaging
            // 1. Payrun Pre Payroll steps are completed, but there are still extra steps (after this payrun will go into Post Payroll tab)
            const isPrePayrollWithExtraStepsCompleted = isLastStepBeforeExtraStepsCompleted && hasExtraSteps
            // 2. Payrun is on Post Payroll tab and all steps are completed
            const isPostPayrollCompleted = areStepsCompleted && hasExtraSteps
            // 3. Payrun on Pre Payroll that doesn't have Extra Steps marked as Not Needed is completed
            const isPrePayrollWithExtraStepsNotNeededCompleted = isLastStepBeforeExtraStepsCompleted && !hasExtraSteps
            // 4. Payrun on Pre Payroll that has no Extra Steps is completed
            const isPrePayrollCompleted = areStepsCompleted && !hasExtraSteps

            const showMessageForCompletion = (message) => {
              dispatch(
                showMessage({
                  body: message,
                })
              )
            }

            if (isPrePayrollWithExtraStepsCompleted) showMessageForCompletion(payrunMessages.completedWithExtraSteps)
            if (isPostPayrollCompleted) showMessageForCompletion(payrunMessages.completedAfterExtraSteps)
            if (isPrePayrollWithExtraStepsNotNeededCompleted || isPrePayrollCompleted) showMessageForCompletion(payrunMessages.completed)

            if (areStepsCompleted || isLastStepBeforeExtraStepsCompleted) {
              removeTasksURLParameters()

              // When all the PayrollInstance's steps are completed, the BE creates a new PayrollInstance,
              // therefore there will be new data for the below models
              dispatch(invalidatePayrollInstanceEmployeePivot())
              dispatch(invalidatePayrollInstanceCountryTermsPivot())
              dispatch(invalidateAnnualSalaries())
            }
          })
          // On error we reset all changed fields to their initial values here
          .catch((error) => {
            let hasTimeoutErrorOnCompletedStep = false
            // Get all changed fields
            const dirtyFields = Object.keys(formValues.initial).filter((key) => {
              const initial = formValues.initial[key]
              const current = formValues.current[key]

              // Don't reset Reversion form fields in case of error and if the user is currently filling it.
              // If the below condition is `true` it means that the Reversion form is visible and the user is filling it.
              // Therefore, we don't want to reset the form.
              // The rest statuses fields will be reset, but we discussed this use-case and it's not a big problem.
              // In all other cases - in case of error, we reset all changed status fields to their initial values.
              // This specific use-case happens, because of two reasons:
              // 1. We show Reversion form in place of Task statuses form.
              // 2. Reversion form is an extension of the Task statuses form and on submit,
              // only 1 request is triggered. Therefore if we currently editing Reversion,
              // then we can't show the rest errors. Because of this we reset the rest errors and keep showing Reversions.
              if (current.isEditable && current.isReversible && current.status === 'reverted') {
                return false
              }

              hasTimeoutErrorOnCompletedStep = error && current.status === 'completed' && ownProps.payrunState === 'active'
              const initialValue = initial.status
              const currentValue = current.status

              return currentValue !== initialValue
            })

            // Reset changed fields to the initial values
            dirtyFields.forEach((name) => dispatch(change('payrollInstanceTaskEdit', name, formValues.initial[name])))

            if (hasTimeoutErrorOnCompletedStep && areTasksCompleted) {
              const message = payrunMessages.timeout
              Raven.captureException(message)
              removeTasksURLParameters()
              window.location.reload()
              return
            }
            throw new SubmissionError(error.errors)
          })
      )
    },
  }
}

const Component = connect(
  mapStateToProps,
  null,
  mergeProps
)(
  reduxForm({
    form: 'payrollInstanceTaskEdit',
  })(PayrollInstanceTaskView)
)

Component.propTypes = {
  payrollInstanceId: PropTypes.number.isRequired,
  onMultipleTaskClick: PropTypes.func.isRequired,
}

export default Fetcher(Component, [
  {
    name: 'payrollInstances',
    params: [
      {
        _computed: { filter: (state, { payrollInstanceId }) => createFilter({ id: payrollInstanceId }) },
      },
    ],
    isForceFetching: true,
  },
  {
    name: 'payrollInstanceTask',
    params: [
      {
        _computed: {
          filter: (state, { payrollInstanceId }) => {
            let filters = {
              payrollInstance: payrollInstanceId,
            }

            let payrollInstanceRef
            try {
              payrollInstanceRef = getPayrollInstanceRef(state, { payrollInstanceId })
            } catch (error) {
              // Waiting on payrollInstance Fetch to finish
            }

            if (payrollInstanceRef) {
              filters = {
                payrollInstance: payrollInstanceId,
                payrollInstanceStep: payrollInstanceRef.currentStepId,
              }
            }

            return createFilter({
              ...filters,
            })
          },
        },
      },
    ],
    isForceFetching: true,
  },
])
