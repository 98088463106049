import { ActionUtility } from 'utils/redux'
import errorsHandling from 'utils/redux/actions/errorsHandling'
import { dateTransformer } from 'redux/transformers/dateTransformer'
import { sanitizeObject } from 'utils/sanitizer'
import _ from 'lodash'
import { formatNameForChangeLogReport } from 'utils/dataFormatting'
import ReportItemBuilder from 'utils/helperClasses/ReportsItemBuilder'
// ------------------------------------
// Constants
// ------------------------------------
export const POLLING_JOB_FETCH = 'POLLING_JOB_FETCH'
export const POLLING_JOB_CREATE = 'POLLING_JOB_CREATE'
export const POLLING_JOB_RECEIVE = 'POLLING_JOB_RECEIVE'
export const POLLING_JOB_INVALIDATE = 'POLLING_JOB_INVALIDATE'
export const SET_SHOULD_POLL = 'SET_SHOULD_POLL'
export const SET_START_POLLING = 'SET_START_POLLING'
export const SET_POLLING_DELAY = 'SET_POLLING_DELAY'
export const POLLING_JOB_UPDATE = 'POLLING_JOB_UPDATE'
export const POLLING_JOB_SET_LAST_CREATED_ID = 'POLLING_JOB_SET_LAST_CREATED_ID'

// ------------------------------------
// Actions
// ------------------------------------
export const actionTypes = {
  fetch: POLLING_JOB_FETCH,
  createWithPolling: POLLING_JOB_CREATE,
  receive: POLLING_JOB_RECEIVE,
  updateWithChildUri: POLLING_JOB_UPDATE,
  setShouldPoll: SET_SHOULD_POLL,
  setStartPolling: SET_START_POLLING,
  setPollingDelay: SET_POLLING_DELAY,
  setLastCreatedId: POLLING_JOB_SET_LAST_CREATED_ID,
}
const actionUtility = new ActionUtility(actionTypes, 'pollingJob', 'jobs', 'PollingJob')

// ------------------------------------
// Thunk
// ------------------------------------
export const setShouldPoll = actionUtility.setShouldPoll
export const setStartPolling = actionUtility.setStartPolling
export const setPollingDelay = actionUtility.setPollingDelay
export const fetchPollingJobs = actionUtility.fetchEntities
export const flagJobAsRead = actionUtility.updateEntityWithChildUri
export const setLastCreatedJobId = actionUtility.setLastCreatedId

export const createNewPayfileChangesExportJob = (entity, params = {}) =>
  actionUtility.createWithPolling(entity, { childUri: 'payfilechangesexport', ...params })
export const createNewVarianceExportJob = (entity, params = {}, options = {}) =>
  actionUtility.createWithPolling(entity, { childUri: 'varianceexport', ...params, options })
export const createNewElementVarianceExportJob = (entity, params = {}, options = {}) =>
  actionUtility.createWithPolling(entity, { childUri: 'elementvarianceexport', ...params, options })
export const createNewEmployeeVarianceExportJob = (entity, params = {}, options = {}) =>
  actionUtility.createWithPolling(entity, { childUri: 'employeevarianceexport', ...params, options })
export const createNewPayfileExportJob = (entity, params = {}) => actionUtility.createWithPolling(entity, { childUri: 'payfileexport', ...params })
export const createNewEmptyPayfileExportJob = (entity, params = {}) => actionUtility.createWithPolling(entity, { childUri: 'emptypayfileexport', ...params })

export const createNewPayfileImportJob = (entityId, file, params = {}) =>
  actionUtility.createWithPolling({ entityId }, { childUri: 'payfileimport', ...params }, file)

export const createNewExportArchiveDownloadJob = (entityId, params = {}) =>
  actionUtility.createWithPolling({ entityId }, { childUri: 'payfileexportarchive', ...params })

export const createNewReportJob = (entity, saved, source) => {
  let transformedData = dateTransformer(entity, ['fromDate', 'toDate'])
  if (transformedData.type === 'ChangeLogReport' && transformedData.modifiedBy) {
    transformedData = formatNameForChangeLogReport(transformedData)
  }

  const builder = new ReportItemBuilder()
  const feReportName = builder.getReportNameCategory(entity.selectedReportType)

  // Pick the allowed report filters
  const filters = _.pick(transformedData, [
    'termCategory',
    'termSubcategory',
    'term',
    'showOnlyTotals',
    'fromDate',
    'toDate',
    'employeeStatus',
    'country',
    'company',
    'payroll',
    'payrollInstance',
    'businessUnit',
    'costCenter',
    'department',
    'processOwner',
    'process',
    'globalOwner',
    'employmentStatus',
    'modifiedBy',
    'orgUnits',
  ])

  // By default, `redux-orm` should clean the Store, when a field is unregistered,
  // but unfortunately it is kept in the Store with `null`
  // More info: https://github.com/erikras/redux-form/issues/2325
  const sanitizedFilters = sanitizeObject(filters)

  // Build specific data for all report types
  const data = {
    type: transformedData.type,
    feCategoryId: entity.selectedReportType,
    category: transformedData.category,
    subcategory: transformedData.subcategory,
    filters: sanitizedFilters,
    saved,
  }

  // Build custom rates params when there are multi payroll currencies
  const multiCustomRates = []
  for (let key in entity) {
    // Custom rates having values.
    // In our form, on changing `toCurrency` value, we are resetting all custom rates values (setting `null` as value).
    // That's needed, because new customRate fields will be rendered.
    // But the old customRate fields will be still kept in the form, having `null` as value.
    // That's the reasons we make sure to skip them here.
    if (_.includes(key, 'customRate') && entity[key] !== null) {
      let splited = _.split(key, '-')
      multiCustomRates.push({
        fromCurrency: parseInt(splited[1]),
        toCurrency: transformedData.toCurrency,
        rate: parseFloat(entity[key]),
      })
    }
  }

  // Send custom fxRate when there are multi payroll currencies
  if (multiCustomRates.length) {
    data['params'] = {
      fxRateCustom: multiCustomRates,
    }
  }

  // Send custom fxRate, when there is only one payroll currency
  if (transformedData.toCurrency && transformedData.rate) {
    data['params'] = {
      fxRateCustom: [
        {
          fromCurrency: transformedData.payrollCurrency[0],
          toCurrency: transformedData.toCurrency,
          rate: parseFloat(transformedData.rate),
        },
      ],
    }
  }

  // Send predefined fxRate in expected format
  if (transformedData.fxRate) {
    data['params'] = {
      fxRate: transformedData.fxRate,
    }
  }

  const buildChildUri = (feReportName, source = null) => {
    const baseUri = `reportcreate?feReportName=${feReportName}`
    // When running a report from a blueButton, we should make note of in the uri
    return source ? `${baseUri}&blueButton=true` : baseUri
  }

  return (dispatch, getState, { api }) => {
    return dispatch(actionUtility.createWithPolling(data, { childUri: buildChildUri(feReportName, source) }))
      .then(errorsHandling.handleFormErrors)
      .then((response) => {
        // Save last created job id to redux store
        dispatch(setLastCreatedJobId(response.id))
        return response
      })
  }
}

export const createSanitizedReport = (entity, saved) => {
  const { type, feCategoryId, category, subcategory } = entity
  const sanitizedFilters = sanitizeObject(entity.filters)
  const builder = new ReportItemBuilder()
  const feReportName = builder.getReportNameCategory(feCategoryId)
  const data = {
    type,
    feCategoryId,
    category,
    subcategory,
    filters: sanitizedFilters,
    saved,
  }
  return (dispatch, getState, { api }) => {
    return dispatch(actionUtility.createWithPolling(data, { childUri: `reportcreate?feReportName=${feReportName}` }))
      .then(errorsHandling.handleFormErrors)
      .then((response) => {
        dispatch(setLastCreatedJobId(response.id))
        return response
      })
  }
}
export const flagAsRead = (id, job, baseApiUsage) => {
  return (dispatch) => {
    dispatch(flagJobAsRead(job, id, 'markasread', false, false, { ...baseApiUsage })).then(() => {
      dispatch(setStartPolling({ shouldStartPolling: true }))
    })
  }
}
export const downloadDocument = (id, job, baseApiUsage, justFlagAsRead) => {
  return (dispatch) => {
    // Stop polling while we are downloading
    dispatch(setStartPolling({ shouldStartPolling: false }))
    if (justFlagAsRead) {
      dispatch(flagJobAsRead(job, id, 'markasread', false, false, { ...baseApiUsage })).then(() => {
        dispatch(setStartPolling({ shouldStartPolling: true }))
      })
    } else {
      dispatch(actionUtility.downloadEntity({ id, childUri: 'download', params: { ...baseApiUsage } })).then((res) => {
        dispatch(flagJobAsRead(job, id, 'markasread', false, false, { ...baseApiUsage })).then(() => {
          dispatch(setStartPolling({ shouldStartPolling: true }))
        })
      })
    }
  }
}
