import { connect } from 'react-redux'
import { getFormInitialValues, getFormValues, reduxForm } from 'redux-form'
import Fetcher from 'containers/Fetcher'
import { diff } from 'utils/object'
import { updateCotUser } from 'redux/actions/cotUsers'
import { fetchAccessAreaPivot } from 'redux/actions/accessAreaPivot'
import { getCotUserAccessAreasInitials, getCotUserWithCountry } from 'redux/selectors/cotUsers'
import { normalizeStringToBoolean, normalizeBooleanToString } from 'redux/transformers/booleanTransformer'
import { getCountries } from 'redux/selectors/country'
import { getTimezones } from 'redux/selectors/timezones'
import { resetPasswordSendEmail } from 'redux/actions/auth'
import { showMessage } from 'redux/actions/modal'
import CotUserForm from '../components/CotUserForm'
import CotUserFormHOC, { buildAccessArea } from './CotUserFormHOC'
import { isEmpty, omit } from 'lodash'
import { isCotAdmin } from 'redux/selectors/auth'
import { mapPropertyValueToBoolean } from 'utils/sanitizer'

const form = 'cotUserEditForm'
const isFormPristine = (state) => {
  const formValues = getFormValues(form)(state) || {}
  const initialFormValues = getFormInitialValues(form)(state)
  const difference = diff(initialFormValues, formValues)

  // Form is pristine (untouched), if there aren't any values changed
  return isEmpty(difference)
}

const mapStateToProps = (state, props) => {
  let cotUser = getCotUserWithCountry(state, {
    userId: props.cotUserId,
  })
  /**
   * Get Access Areas for the user
   *
   * We limit this selector only for Cot Admin users since they can edit access areas.
   * Access areas for ROLE_COT are returned only for the logged in user.
   */
  const accessAreas = isCotAdmin(state) && getCotUserAccessAreasInitials(state, { userId: props.cotUserId })
  return {
    isPristine: isFormPristine(state),
    countries: getCountries(state),
    timezones: getTimezones(state),
    hasCredentials: cotUser.hasCredentials,
    workEmail: cotUser.workEmail,
    isMobilePhoneFieldDisabled: state.auth.userId === cotUser.id,
    isCotAdmin: isCotAdmin(state),
    initialValues: {
      ...normalizeBooleanToString(
        {
          // TODO - check if we can use an enum to avoid hardcoding
          ...omit(cotUser, ['levelOfPosition', 'userRights', 'notes', 'cotUserCredentials', 'userCredentials', 'fullname', 'mostRecentLogin']),
          ...(cotUser.country && { country: cotUser.country.id }),
          ...(accessAreas && { accessAreaParentCompany: accessAreas.companies }),
        },
        ['receivesNotifications']
      ),
      beneficiaryFile: cotUser.cotSpecialRight?.beneficiaryFile,
      bankFile: cotUser.cotSpecialRight?.bankFile,
      dataMigration: cotUser.cotSpecialRight?.dataMigration,
    },
    isSSOEnabled: cotUser.isSSOEnabled,
  }
}

const mapDispatchToProps = (dispatch, props) => {
  return {
    dispatch,
  }
}

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { dispatch } = dispatchProps
  const { isMobilePhoneFieldDisabled, isCotAdmin } = stateProps

  return {
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    onSubmit: (data) => {
      const { accessAreaParentCompany, mobilePhone } = data
      // Normalize data before submit.
      // AccessAreaParentCompany is only a FE field, which needs to be build into access areas.
      let normalized = {
        ...omit(data, [
          'id',
          'accessAreaParentCompany',
          'mobilePhone',
          'mostRecentLogin',
          'authFailedAttempts',
          'specialRight',
          'isSSOEnabled',
          'beneficiaryFile',
          'bankFile',
          'cotSpecialRight',
          'dataMigration',
        ]),
        ...(!isMobilePhoneFieldDisabled && { mobilePhone: mobilePhone || null }),
        ...(accessAreaParentCompany &&
          accessAreaParentCompany.length && {
          accessAreas: buildAccessArea(accessAreaParentCompany, 'ParentCompany'),
        }),
      }

      // Only COT Admins have access to COT special rights
      // We include them in the payload only if the logged-in user is a COT admin.
      if (isCotAdmin) {
        const beneficiaryFile = mapPropertyValueToBoolean(data, 'beneficiaryFile')
        const bankFile = mapPropertyValueToBoolean(data, 'bankFile')
        const dataMigration = mapPropertyValueToBoolean(data, 'dataMigration')
        normalized['cotSpecialRight'] = {
          ...beneficiaryFile,
          ...bankFile,
          ...dataMigration,
        }
      }

      normalized = normalizeStringToBoolean(normalized, ['receivesNotifications'])

      return dispatch(updateCotUser(normalized, data.id)).then(() => {
        ownProps.onEdit()
        dispatch(fetchAccessAreaPivot())
      })
    },
    resetUserPassword: (data) =>
      dispatch(resetPasswordSendEmail(data)).then(() => {
        dispatch(
          showMessage({
            // TODO - move string to utils
            body: 'A password reset link has been sent to the user’s email',
          })
        )
      }),
  }
}

const Container = CotUserFormHOC(connect(mapStateToProps, mapDispatchToProps, mergeProps)(reduxForm({ form })(CotUserForm)), form)

export default Fetcher(Container, ['cotUsers', 'countries', 'timezones', 'accessAreaPivot', 'parentCompanies'])
