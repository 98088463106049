import { fetchEmployeeSystemUsers } from 'redux/actions/employeeSystemUsers'
import { fetchPayrollInstanceEmployeePivot } from 'redux/actions/payrollInstanceEmployeePivot'
import { prepareAttachDettachEmployeeFilters } from 'redux/filters/attachDettachEmployeesFilter'
import { prepareEEPivotFilters } from 'redux/filters/payrollInstanceEmployeePivotFilter'

export const triggerAttachDettachDispatch = ({ dispatch, data, state, limit, filterName, additionalFilters }) => {
  return dispatch(
    fetchEmployeeSystemUsers({
      filter: prepareAttachDettachEmployeeFilters({
        limit,
        filters: {
          ...data,
          ...additionalFilters,
        },
        state,
        filterName,
      }),
    })
  )
}

export const triggerPayrollInstancePivotDispatch = ({ dispatch, data, state, limit, payrollInstanceId, filterName }) => {
  return dispatch(
    fetchPayrollInstanceEmployeePivot({
      filter: prepareEEPivotFilters({
        limit,
        filters: {
          ...data,
          payrollInstance: payrollInstanceId,
        },
        state,
        filterName,
      }),
    })
  )
}
