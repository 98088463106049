import React, { useEffect, useState } from 'react'
import { useInterval } from 'hooks/useInterval'
import { useDispatch, useSelector } from 'react-redux'
import { downloadDocument, fetchPollingJobs, flagAsRead, setShouldPoll, setStartPolling } from 'redux/actions/pollingJob'
import { closeAllToastMessages, endToastFileGeneration, startToastFileGeneration } from 'redux/actions/toasts'
import { createFilter } from 'utils/redux/filter'
import { jobStatusFilterEnum, jobStatusEnum } from 'utils/enums/jobStatusEnum'
import { isCot, isVendorInvolved } from 'redux/selectors/auth'
import { toast } from 'react-toastify'
import ToastLoadingViews from './ToastLoadingViews'
import { getTenantSubdomain } from 'utils/tenant'
import ToastCompletedViews from './ToastCompletedViews'
import { getApiUrl } from 'redux/actions/api'
import { getParentCompanies } from 'redux/selectors/parentCompanies'
import { subdomainEnums } from 'utils/enums/subdomainEnums'
import ToastFailedViews from './ToastFailedViews'
import {
  DEFAULT_FAILED_OPTIONS,
  DEFAULT_LOADING_OPTIONS,
  DEFAULT_SUCCESS_OPTIONS,
  DEFAULT_SUCCESS_TIMED_OPTIONS,
  TOAST_MESSAGES_FOR_AUTOCLOSE
} from 'configs/toastConfigs'
import { isPathnamePure, isPathnameVendor } from 'utils/query'

const INITIAL_POLLING_COUNTER = 3

const JOB_STATUS_FILTERS = jobStatusFilterEnum.NOT_READ

const PollingTracker = () => {
  const dispatch = useDispatch()
  // Currently the BE doesn't always return the jobs fast enough
  // So a job could be in progress, but not in the /job all
  // We'll retry 3 extra times, before stopping the polling
  const [stopPollingCounter, setStopPollingCounter] = useState(INITIAL_POLLING_COUNTER)
  const [displayedErrorJobs, setDisplayedErrorJobs] = useState([])
  const [completedJobsForAutoClose, setCompletedJobsForAutoClose] = useState([])

  const pollingJobState = useSelector((state) => state.pollingJob)
  const isCotUser = useSelector((state) => isCot(state))
  const shouldUseDefaultApi = useSelector((state) => isCot(state))
  const isVendorUser = useSelector((state) => isVendorInvolved(state))
  const isLoggedIn = useSelector((state) => state.auth.accessToken)
  const tenants = useSelector((state) => state.tenants)
  const vendorTenant = useSelector((state) => state.vendorTenant)
  const parentCompanies = useSelector((state) => getParentCompanies(state))
  const userId = useSelector((state) => state.auth.userId)
  const delay = pollingJobState.startPolling && pollingJobState.shouldPoll ? pollingJobState.delay : null

  const stopPolling = async () => {
    await dispatch(setShouldPoll({ enablePolling: false }))
    await dispatch(setStartPolling({ shouldStartPolling: false }))
  }

  const startPolling = async () => {
    await dispatch(setShouldPoll({ enablePolling: true }))
    await dispatch(setStartPolling({ shouldStartPolling: true }))
  }

  const handleStoppingPollingFlow = () => {
    dispatch(closeAllToastMessages())
    stopPolling()
  }

  const checkIfShouldStopPolling = (vendorTenant) => {
    if (isVendorUser && !vendorTenant.url) { return true }
    if (isPathnameVendor(window.location.pathname) && !vendorTenant.url) { return true }
    if (isPathnamePure(window.location.pathname)) { return true }
    return false
  }

  const handleEndingToastMessage = (job) => {
    dispatch(endToastFileGeneration(job.id))
  }

  const getBaseApiUsage = () => {
    let baseApiUsage = { forceDefaultAPI: shouldUseDefaultApi }
    if (isVendorUser) { baseApiUsage = { forceDefaultAPI: false } }
    return baseApiUsage
  }

  const findIfThereMessageAlreadyDisplay = (jobs, job) => {
    return jobs.find(trackedJobs => trackedJobs.id === job.id)
  }

  const handleUpdatingFailedTrackingJobs = (job) => {
    let errors = [...displayedErrorJobs]
    errors.push(job)
    setDisplayedErrorJobs(errors)
    handleEndingToastMessage(job)
  }

  const handleUpdatingSuccessTrackingJobs = (job) => {
    let jobs = [...completedJobsForAutoClose]
    jobs.push(job)
    setCompletedJobsForAutoClose(jobs)
    handleEndingToastMessage(job)
  }

  const getVendorClientId = () => {
    const windowUrl = window.location.pathname.split('/')
    const indexOfCompanyRoute = windowUrl.indexOf('clients')
    const tenantUrlId = parseInt(windowUrl[indexOfCompanyRoute + 1], 10)
    return tenantUrlId
  }

  const displayTimedCompletedJobs = (job) => {
    if (findIfThereMessageAlreadyDisplay(completedJobsForAutoClose, job)) return

    handleUpdatingSuccessTrackingJobs(job)
    toast.update(job.id, {
      render: <ToastCompletedViews job={job} cb={downloadDocument} baseApiUsage={getBaseApiUsage()} />,
      type: toast.TYPE.SUCCESS,
      ...DEFAULT_SUCCESS_TIMED_OPTIONS,
      onClose: () => dispatch(flagAsRead(job.id, job, getBaseApiUsage()))
    })
  }

  const displayNonTimedCompletedJobs = (job) => {
    toast.update(job.id, {
      render: <ToastCompletedViews job={job} cb={downloadDocument} baseApiUsage={getBaseApiUsage()} />,
      type: toast.TYPE.SUCCESS,
      ...DEFAULT_SUCCESS_OPTIONS
    })
  }

  const handleCompletedJobsFlow = (job) => {
    handleEndingToastMessage(job)
    if (TOAST_MESSAGES_FOR_AUTOCLOSE.includes(job.type)) {
      displayTimedCompletedJobs(job)
      return
    }

    displayNonTimedCompletedJobs(job)
  }

  const displayFailedJobs = (job) => {
    if (findIfThereMessageAlreadyDisplay(displayedErrorJobs, job)) return
    handleUpdatingFailedTrackingJobs(job)
    toast.update(job.id, {
      render: <ToastFailedViews job={job} cb={null} baseApiUsage={getBaseApiUsage()} />,
      type: toast.TYPE.ERROR,
      ...DEFAULT_FAILED_OPTIONS,
      onClose: () => dispatch(flagAsRead(job.id, job, getBaseApiUsage()))
    })
  }

  const updateToastMessages = (job) => {
    if (job.status === jobStatusEnum.COMPLETED) {
      handleCompletedJobsFlow(job)
    }

    if (job.status === jobStatusEnum.FAILED) {
      displayFailedJobs(job)
    }
  }

  useEffect(() => {
    // When first logged in, need to make an API call to determine if there is any jobs
    // If there are any jobs in progress will need to kick off a dispatch for the shouldPoll: true
    // Then if a person is in a section that polling should be started (i.e after selecting a tenant, or a ICP selecting a tenant)
    // Another dispatch to startPolling should be initiated to actually start the polling
    // When the polling response comes back, for each item that is complete
    // need to trigger a dispatch to toast@endToastFileGeneration
    async function getJobs () {
      // We only want to look at even starting to poll for jobs if one is in the tenant sections
      // This includes the ICP's in which it will only start looking once they go to a tenant
      // We don't want to poll if in the COT section for now
      if ((!tenants.url && getTenantSubdomain() === subdomainEnums.COS && !vendorTenant.url)) {
        stopPolling()
        dispatch(closeAllToastMessages())
        return
      }
      if (!isLoggedIn) return
      if (!parentCompanies.length) return
      if (isCotUser && !tenants.id) return
      // If the person isn't logged in as COS, we need to wait for the settings/tenant call to be completed
      if (getTenantSubdomain() !== subdomainEnums.COS) {
        await dispatch(getApiUrl(getTenantSubdomain()))
      }

      const shouldStopPollingFlow = checkIfShouldStopPolling(vendorTenant)
      if (shouldStopPollingFlow) {
        handleStoppingPollingFlow()
        return
      }

      let currentTenant = parentCompanies.find(company => isCotUser ? company.id === tenants.id : true)
      if (vendorTenant.url && isPathnameVendor(window.location.pathname)) {
        const vendorTenantId = getVendorClientId()
        currentTenant = parentCompanies.find(company => company.id === vendorTenantId)
      }
      const filter = createFilter({ read: JOB_STATUS_FILTERS, entityTenant: currentTenant.schema, userId: userId })
      const resp = await dispatch(fetchPollingJobs({ ...getBaseApiUsage(), filter: filter }))
      if (!resp) return
      const { payload: { response } } = resp
      // If there are items, that we don't classify as non completed items,
      // We should start the polling
      if (response.totalCount > 0) { startPolling() }
      if (response.totalCount === 0) { stopPolling() }
    }
    getJobs()
    // Ping once to see if there are jobs
  }, [tenants, isLoggedIn, vendorTenant, parentCompanies])

  useInterval(async () => {
    // Be honest, this turned into a bit of a monster as scope increased. Can do a lot of re-work handling these checks
    if (isPathnameVendor(window.location.pathname) && !vendorTenant.url) return
    if (isPathnamePure(window.location.pathname)) return
    if (pollingJobState.shouldPoll && tenants) {
      if (isPathnamePure(window.location.pathname)) return
      let currentTenant = parentCompanies.find(company => isCotUser ? company.id === tenants.id : true)
      if (vendorTenant.url && isPathnameVendor(window.location.pathname)) {
        const vendorTenantId = getVendorClientId()
        currentTenant = parentCompanies.find(company => company.id === vendorTenantId)
      }
      const filter = createFilter({ read: JOB_STATUS_FILTERS, entityTenant: currentTenant.schema, userId: userId })
      const { payload } = await dispatch(fetchPollingJobs({ ...getBaseApiUsage(), filter: filter }))
      if (payload) {
        const { response } = payload

        if (response.totalCount === 0 && stopPollingCounter !== 0) {
          setStopPollingCounter(stopPollingCounter - 1)
        }
        if (stopPollingCounter === 0 && response.totalCount === 0) {
          stopPolling()
          setStopPollingCounter(INITIAL_POLLING_COUNTER)
          return
        }

        response.data.forEach(job => {
          dispatch(startToastFileGeneration(job.id))
          toast.loading(<ToastLoadingViews job={job} />, { toastId: job.id, ...DEFAULT_LOADING_OPTIONS })
          updateToastMessages(job)
        })
      }
    }
  }, delay, pollingJobState.startPolling)
  return null
}

export default PollingTracker
